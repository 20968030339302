import styled from 'styled-components'
import { resetList } from '../../../../styled/mixins'

import ButtonLibrary from '../../../common/Button'

export const List = styled.ul`
  ${resetList};
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex;
  }
`

export const Button = styled(ButtonLibrary)`
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    width: 100%;
    justify-content: center;
    padding-top: 11px;
    padding-bottom: 11px;
  }

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const Item = styled.li`
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    margin-bottom: 15px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    &:first-child ${Button} {
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-right: 15px;
  
    &:last-child {
      margin-right: 0;
    } 
  }
`
