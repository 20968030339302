type CountryData = {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

type TPhoneValidation = (value: { number: string, country: CountryData }) => string | undefined

export const requiredValidation = {
  required: 'Данное поле обязательно',
}

export const emailValidation = {
  ...requiredValidation,
  pattern: {
    message: 'Проверьте правильность email',
    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
}

export const agreeValidation = {
  ...requiredValidation,
}

export const phoneValidation: TPhoneValidation = (value) => {
  if (!value.number) {
    return 'Данное поле обязательно'
  }

  // if (value.country && value.number.length !== (value.country.format.split('.').length - 1)) {
  //   return 'Проверьте правильность телефона'
  // }

  return undefined
}

export const nameValidation = (value: string) => {
  if (!value) {
    return 'Данное поле обязательно'
  }

  if (value.length > 30) {
    return 'Максимальная длина - 30 символов'
  }

  if (value.length < 2) {
    return 'Минимальная длина - 2 символа'
  }

  if (!/^[A-Za-zА-Яа-яЁё -]+$/.test(value)) {
    return 'Недопустимые символы (разрешены: пробел и тире)'
  }

  if (!/^[^\s]+(\s.*)?$/.test(value)) {
    return 'Одни пробелы запрещены'
  }
}
