import { useMediaQuery } from 'react-responsive'
import { device, TSizes } from './variables'

type TUseMediaProps = {
  size: keyof TSizes
}

const useMedia = ({ size }: TUseMediaProps) => {
  return useMediaQuery({ query: device[size] })
}

export const useDesktop = () => useMedia({ size: 'desktop' })

export default useMedia
