import styled, { css } from 'styled-components';

import { container, visuallyHidden } from '../../../styled/mixins';

import ContentCardLibrary from '../../ContentCard';
import * as ContentCardLibraryStyles from '../../ContentCard/ContentCard.styled';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.main.darken};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`;

export const ContentCard = styled(ContentCardLibrary)<{
  isActive?: boolean;
  isDuplicate?: boolean;
  isNext?: boolean;
  isPrev?: boolean;
  isPrevSide?: boolean;
  differenceIndexOfCenter: number;
  backgroundColor: string;
}>`
  width: 215px;
  @media ${(props) => props.theme.device['desktop']} {
    width: 550px;
    height: 315px;
  }
  @media ${(props) => props.theme.device['desktop-medium']} {
    width: 600px;
    height: 315px;
  }

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
    `}

  ${(props) =>
    props.isPrev &&
    css`
      @media ${(props) => props.theme.device['desktop']},
        @media ${(props) => props.theme.device['desktop-medium']} {
        display: flex;
        justify-content: flex-end;
        opacity: 1;
      }
    `}
  
  ${(props) =>
    props.isPrev &&
    !props.isDuplicate &&
    props.differenceIndexOfCenter > 1 &&
    css`
      @media ${(props) => props.theme.device['desktop-medium']} {
        // opacity: 0;
        position: relative;
        right: -${(props.differenceIndexOfCenter - 1) * 400}px;

        ${ContentCardLibraryStyles.CardWrapper} {
          ${window.CSS.supports(
            '(backdrop-filter: blur(21px)) or (-webkit-backdrop-filter: blur(21px))'
          ) &&
          css`
            opacity: ${0.4 / props.differenceIndexOfCenter};
          `};
        }

        ${ContentCardLibraryStyles.Card} {
          backdrop-filter: blur(20px);
        }
      }
    `}
  
  ${(props) =>
    !props.isPrev &&
    !props.isDuplicate &&
    props.differenceIndexOfCenter > 1 &&
    css`
      @media ${(props) => props.theme.device['desktop']},
        @media ${(props) => props.theme.device['desktop-medium']} {
        position: relative;
        right: ${(props.differenceIndexOfCenter - 1) * 400}px;

        ${ContentCardLibraryStyles.CardWrapper} {
          ${window.CSS.supports(
            '(backdrop-filter: blur(21px)) or (-webkit-backdrop-filter: blur(21px))'
          ) &&
          css`
            opacity: ${0.4 / props.differenceIndexOfCenter};
          `};
        }

        ${ContentCardLibraryStyles.Card} {
          backdrop-filter: blur(20px);
        }
      }
    `}

  ${(props) =>
    props.differenceIndexOfCenter > 2 &&
    !props.isDuplicate &&
    css`
      @media ${(props) => props.theme.device['desktop']},
        @media ${(props) => props.theme.device['desktop-medium']} {
        visibility: hidden;
      }
    `}
  
  ${(props) =>
    !props.isActive &&
    css`
      @media ${(props) => props.theme.device['desktop']},
        @media ${(props) => props.theme.device['desktop-medium']} {
        border: none;
        background: transparent;
        backdrop-filter: none;
      }

      ${ContentCardLibraryStyles.CardWrapper} {
        ${window.CSS.supports(
          '(backdrop-filter: blur(21px)) or (-webkit-backdrop-filter: blur(21px))'
        ) &&
        css`
          opacity: 0.4;
        `}
      }
    `}
  
  ${ContentCardLibraryStyles.Card} {
    background: ${(props) => props.backgroundColor};
    backdrop-filter: blur(20px);
  }

  ${ContentCardLibraryStyles.Content} {
    ${(props) =>
      props.isActive &&
      css`
        opacity: 1;
        visibility: visible;
        transition: opacity 0.2s ease-in-out 0.2s,
          visibility 0.2s ease-in-out 0.2s, width 0.2s ease-in-out;
      `}

    ${(props) =>
      !props.isActive &&
      css`
        opacity: 0;
        visibility: hidden;
        @media ${(props) => props.theme.device['desktop']},
          @media ${(props) => props.theme.device['desktop-medium']} {
          ${visuallyHidden};
        }
      `}
  }
`;

export const Header = styled.div`
  margin-bottom: 80px;
`;

export const Slogan = styled.div`
  margin-bottom: 10px;
  ${container};
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  ${container};
`;

export const Slider = styled.div``;
