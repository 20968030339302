import { css } from 'styled-components'

export const modalStylesCSS = css`
  .react-responsive-modal {
    &-modal {
      margin: 0;
      padding: 0;
      overflow-y: visible;
      background: transparent;
      box-shadow: none;
      border-radius: 15px;
      outline: none;
      max-width: none;

       @media ${props => props.theme.maxDevice['desktop']},
       @media ${props => props.theme.maxDevice['desktop-medium']} {
        width: 100%;
      }
    }

    &-closeButton {
      width: 25px;
      height: 25px;

       @media ${props => props.theme.maxDevice['desktop']},
       @media ${props => props.theme.maxDevice['desktop-medium']} {
        top: auto;
        left: 50%;
        bottom: -30px;
        transform: translateX(-50%);
      }

      @media ${props => props.theme.device['desktop']},
      @media ${props => props.theme.device['desktop-medium']} {
        top: 0px;
        right: -35px;
      }

      & > svg {
        width: 25px;
        height: 25px;
        fill: ${props => props.theme.colors.text.contrast};;
      }
   }

    &-overlay {
      background: rgba(23, 17, 51, 0.01);
      backdrop-filter: blur(21px) opacity(1);
      transition: backdrop-filter 0.250s ease-in-out;

      @supports not ((backdrop-filter: blur(60px)) or (-webkit-backdrop-filter: blur(60px))) {
        & {
          background: rgba(23, 17, 51, 0.95);
        }
      }
    }

    &-container {
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  }
`
