import React, { useCallback, useState } from 'react';

import './switch.scss';

const Switch = ({
  ownerTitle = "",
  advTitle = "",
  initialState = true,
  onChange = (newType: number) => {},
}) => {
  const [isChecked, setIsChecked] = useState(initialState);

  const toggle = useCallback(
    (type, position = true) => {
      if (type === 1) {
        setIsChecked(!isChecked);
        onChange(isChecked ? 1 : 2);
      } else {
        if (position !== isChecked) {
          setIsChecked(position);
          onChange(position ? 2 : 1);
        }
      }
    },
    [isChecked, onChange]
  );

  return (
    <div className="toggle">
      <div
        className={`toggle-slogan ${!isChecked ? "toggle-slogan__active" : ""}`}
        onClick={() => toggle(2, false)}
      >
        {ownerTitle}
      </div>
      <div className="toggle-pin">
        <input type="checkbox" id="toggle-switch" checked={isChecked} />
        <label htmlFor="toggle-switch" onClick={() => toggle(1)} />
      </div>
      <div
        className={`toggle-slogan ${isChecked ? "toggle-slogan__active" : ""}`}
        onClick={() => toggle(2, true)}
      >
        {advTitle}
      </div>
    </div>
  );
};

export default Switch;