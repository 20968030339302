import styled from 'styled-components'

export const Root = styled.div``

export const Main = styled.main``

export const Item = styled.div`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    &:first-child section {
      padding-top: 140px;
    }
  }
`
