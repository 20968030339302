import { DefaultTheme } from 'styled-components'
import * as variables from './variables'

export enum ThemeModes {
  Default = 'default',
}

type TGetThemeApp = (mode: ThemeModes) => DefaultTheme;

export const getThemeApp: TGetThemeApp = (mode) => {
  return {
    fonts: variables.fonts,
    device: variables.device,
    maxDevice: variables.maxDevice,
    colors: { ...variables.colors[mode] },
    mode,
  }
}
