import React from 'react'

import { ReactComponent as VkSVG } from './vk.svg'
import { ReactComponent as TgSVG } from './tg.svg'
import { ReactComponent as QuestionSVG } from './question.svg'

export type TSocialList = {
  tg: any
  vk: any
  question: any
}

const socialList: TSocialList = {
  vk: VkSVG,
  tg: TgSVG,
  question: QuestionSVG,
}

export const getSocialIcon = (name: keyof TSocialList): React.FunctionComponent => socialList[name]
