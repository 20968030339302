import styled from 'styled-components'

import { resetList } from '../../../styled/mixins'

export const List = styled.ul`
  ${resetList};
  
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    display: flex;
  }
`

export const Item = styled.li`
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    margin-right: 20px;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
  
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 0;
  text-decoration: none;
  
  svg {
    max-width: 20px;
    max-height: 15px;
    fill: ${props => props.theme.colors.text.contrast};
    transition: fill 0.2s ease-in-out;
  }
  
  &:hover svg {
    fill: ${props => props.theme.colors.thirty.default};
  }
`

