import React from 'react'
const User = () => {
  return <g>
    <g>
      <path
        fill="url(#paint7_welcome_vp_linear)"
        d="M580.12 64.963c16.508 0 29.89-13.376 29.89-29.877 0-16.5-13.382-29.877-29.89-29.877-16.507 0-29.89 13.376-29.89 29.877 0 16.5 13.383 29.877 29.89 29.877z"
      ></path>
      <path
        fill="url(#pattern1_welcome_vp)"
        d="M556.875 12.4H602.237V66.179H556.875z"
      ></path>
      <path
        fill="#fff"
        d="M591.435 23.92c0 6.243-5.068 11.31-11.314 11.31s-11.314-5.067-11.314-11.31 5.068-11.31 11.314-11.31c6.246-.024 11.314 5.043 11.314 11.31zM580.12 65.004c8.6 0 16.311-3.65 21.764-9.484-3.17-8.98-11.698-15.416-21.764-15.416-10.065 0-18.593 6.435-21.764 15.416 5.453 5.835 13.165 9.484 21.764 9.484z"
      ></path>
    </g>
  </g>
}

export default User
