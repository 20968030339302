import React from 'react'

import { observer } from 'mobx-react-lite'

import RegisterConfirmation, { IRegisterConfirmationSubmitData } from '../../../common/Forms/RegisterConfirmation'

import storeApp from '../../../../store/AppStore'
import { EAuthModalSteps } from '../../../Modals/Modals.type'

const RegisterConfirmationForm = () => {
  const onSubmit = (data: IRegisterConfirmationSubmitData) => {
    storeApp.authRegistrationConfirm(data)
  }

  const onClickSwitchingRegistration = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.setAuthModalData(EAuthModalSteps.Register)
  }

  const backendErrors = React.useMemo(() => {
    return storeApp.errors;
  }, [storeApp.errorsUpdated]);

  return <RegisterConfirmation
    cbSubmitAction={ onSubmit }
    cbCancelAction={ onClickSwitchingRegistration }
    backendErrors={ backendErrors }
  />
}

export default observer(RegisterConfirmationForm)
