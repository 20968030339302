import React from 'react'

import * as Styles from './Header.styled'

import { useDesktop } from '../../../styled/useMedia'
import { useSticky } from './hooks'

import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

import { IExternalNavigations, ILogo, INavigations, ISocials } from '../types'

export interface IHeaderProps {
  navigations: INavigations
  externalLinks?: IExternalNavigations
  socials: ISocials
  logo: ILogo
  isOpenMobileMenu: boolean
  onClickMenuButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickEnterButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickRegisterButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickLinkMenu: () => void
}

const Header: React.FC<IHeaderProps> = ({ navigations, externalLinks, socials, logo, isOpenMobileMenu, onClickMenuButton, onClickEnterButton, onClickRegisterButton, onClickLinkMenu }) => {
  const isDesktop = useDesktop()
  const { isSticky, refSticky } = useSticky()

  return <Styles.Root ref={ refSticky } isSticky={ isSticky }>
    <Styles.Container>
      {
        isDesktop ?
          <HeaderDesktop
            navigations={ navigations }
            externalLinks={ externalLinks }
            onClickEnterButton={ onClickEnterButton }
            onClickRegisterButton={ onClickRegisterButton }
            onClickLinkMenu={ onClickLinkMenu }
          />
          :
          <HeaderMobile
            navigations={ navigations }
            externalLinks={ externalLinks }
            socials={ socials }
            logo={ logo }
            isOpenMenu={ isOpenMobileMenu }
            onClickMenuButton={ onClickMenuButton }
            onClickUserButton={ onClickEnterButton }
            onClickRegisterButton={ onClickRegisterButton }
            onClickLinkMenu={ onClickLinkMenu }
          />
      }
    </Styles.Container>
  </Styles.Root>
}

export default Header
