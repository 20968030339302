import React from 'react'

const SocialVk = () => {
  return <g>
    <g filter="url(#filter5_welcome_rk_d)">
      <ellipse
        cx={685.434}
        cy={359.662}
        rx={23.7149}
        ry={23.725}
        fill="url(#paint2_welcome_rk_linear)"
      />
    </g>
    <g filter="url(#filter6_welcome_rk_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M693.537 361.547C694.265 362.258 695.032 362.926 695.684 363.708C695.972 364.055 696.245 364.414 696.454 364.817C696.749 365.39 696.482 366.021 695.968 366.055L692.774 366.054C691.951 366.122 691.294 365.79 690.741 365.227C690.299 364.776 689.89 364.297 689.465 363.831C689.29 363.641 689.108 363.462 688.89 363.32C688.454 363.037 688.076 363.124 687.826 363.578C687.573 364.041 687.515 364.553 687.49 365.069C687.456 365.821 687.229 366.019 686.473 366.054C684.859 366.13 683.327 365.885 681.904 365.071C680.649 364.352 679.676 363.338 678.829 362.191C677.18 359.955 675.917 357.499 674.783 354.974C674.527 354.405 674.714 354.1 675.341 354.089C676.383 354.069 677.425 354.07 678.468 354.088C678.891 354.094 679.171 354.337 679.335 354.737C679.899 356.124 680.588 357.443 681.454 358.666C681.685 358.991 681.92 359.317 682.254 359.546C682.625 359.8 682.907 359.715 683.081 359.303C683.191 359.041 683.24 358.759 683.265 358.479C683.347 357.515 683.358 356.552 683.213 355.591C683.124 354.991 682.787 354.603 682.189 354.49C681.883 354.432 681.929 354.318 682.077 354.144C682.333 353.843 682.575 353.656 683.056 353.656H686.665C687.233 353.768 687.359 354.024 687.437 354.596L687.44 358.606C687.434 358.828 687.551 359.485 687.95 359.631C688.269 359.736 688.479 359.48 688.67 359.278C689.534 358.36 690.151 357.276 690.702 356.153C690.947 355.659 691.157 355.147 691.361 354.634C691.512 354.254 691.748 354.067 692.176 354.076L695.65 354.079C695.753 354.079 695.857 354.08 695.957 354.097C696.542 354.197 696.703 354.449 696.522 355.021C696.237 355.918 695.683 356.666 695.141 357.417C694.562 358.219 693.942 358.993 693.368 359.8C692.84 360.536 692.882 360.908 693.537 361.547Z"
        fill="white"
      />
    </g>
  </g>
}

export default SocialVk
