import { ISections } from '../../../types/sections'
import { SectionNamesRK } from '../../rk/sections/names'
import {
  benefitContent,
  competenceContent,
  contactsContent,
  inventoryContent,
  opportunityContent,
  welcomeContent
} from './data'

import Welcome from '../../../components/Sections/Welcome'
import Opportunity from '../../../components/Sections/Opportunity'
import Competence from '../../../components/Sections/Competence'
import Inventory from '../../../components/Sections/Inventory'
import Slider from '../../../components/Sections/Slider'
import ContactForm from '../../../components/Sections/ContactForm'

export const sections: ISections = [
  {
    id: 1,
    name: SectionNamesRK.Welcome,
    component: Welcome,
    content: welcomeContent,
  },
  {
    id: 2,
    name: SectionNamesRK.Capabilities,
    component: Opportunity,
    content: opportunityContent,
  },
  {
    id: 3,
    name: SectionNamesRK.Competencies,
    component: Competence,
    content: competenceContent,
  },
  {
    id: 4,
    name: SectionNamesRK.Inventory,
    component: Inventory,
    content: inventoryContent,
  },
  {
    id: 5,
    name: SectionNamesRK.Benefits,
    component: Slider,
    content: benefitContent,
  },
  {
    id: 6,
    name: SectionNamesRK.Contacts,
    component: ContactForm,
    content: contactsContent,
  },
]
