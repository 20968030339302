import React from 'react'

import * as Styles from './Advantages.styled'

import { useDesktop } from '../../../styled/useMedia'

import Section from '../../common/Section'
import Slogan from '../SloganSection'
import AdvantagesList from '../../Advantages'
import SectionParallax from '../SectionParallax'

import { advantagesList } from '../../../data/vp'

const Advantages = () => {
  const isDesktop = useDesktop()

  const bgImageDesktopStyleObject = {
    left: '35%',
    width: '75%',
    height: '100%',
  }

  return <Styles.Wrapper>
    <SectionParallax
      bgImageStyle={ isDesktop ? bgImageDesktopStyleObject : {} }
    >
      <Section>
        <Styles.Header>
          <Slogan>Наши преимущества</Slogan>
        </Styles.Header>


        <Styles.Content>
          <AdvantagesList
            advantages={ advantagesList }
            isHTML
          />
        </Styles.Content>
      </Section>
    </SectionParallax>
  </Styles.Wrapper>
}

export default Advantages
