import React from 'react'

import * as Styles from './Competence.styled'

import Section from '../../common/Section'
import Slogan from '../SloganSection'
import SectionParallax, { ParallaxSide } from '../SectionParallax'

import { ICompetenceSection } from '../../../types/sections'

const Competence: React.FC<ICompetenceSection> = ({ slogan, competenceList }) => {
  return <Styles.Wrapper>
    <SectionParallax side={ ParallaxSide.Right }>
      <Section>
        <Styles.Header>
          <Slogan>{ slogan }</Slogan>
        </Styles.Header>

        <Styles.Content>
          <Styles.List>
            {
              competenceList.map(competence => {
                const items = competence.competences.map(item => {
                  const Icon = item.content

                  return {
                    ...item,
                    content: <Styles.Icon key={item.id}> <Icon /> </Styles.Icon>
                  }
                })

                return <Styles.Item key={competence.id}>
                  <Styles.SloganItem>{ competence.slogan }</Styles.SloganItem>
                  <Styles.SkewCard backgroundColor={ getBackgroundColor(competence.id) }>
                    <Styles.ListWithEllipse items={ items } />
                  </Styles.SkewCard>
                </Styles.Item>
              })
            }
          </Styles.List>
        </Styles.Content>
      </Section>
    </SectionParallax>
  </Styles.Wrapper>
}

const getBackgroundColor = (id: number): string => {
  switch(id) {
    case 1: return 'linear-gradient(148.62deg, #C165DD 6.02%, #5C27FE 94.37%)'
    case 2: return 'linear-gradient(148.62deg, #1DE5E2 6.25%, #B588F7 94.27%)'
    case 3: return 'linear-gradient(148.62deg, #FACD68 5.73%, #FC76B3 93.75%)'
    default: return ''
  }
}

export default Competence
