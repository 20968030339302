import React from 'react'

const Picture = () => {
  return <g>
    <g filter="url(#filter1_welcome_vp_d)">
      <path
        fill="#fff"
        d="M532.922 440.173H334.835a8.101 8.101 0 01-8.109-8.106V307.219a8.102 8.102 0 018.109-8.106h198.087a8.102 8.102 0 018.11 8.106v124.87c0 4.46-3.626 8.084-8.11 8.084z"
      ></path>
      <path
        fill="#66B4FF"
        d="M532.916 432.072V307.203H334.829v124.869h198.087z"
      ></path>
      <path
        fill="#40A1FF"
        d="M486.527 366.293l-23.186 32.887-10.572 14.961-22.022-31.24-34.681-49.165-61.23 86.841v11.49h198.087l-46.396-65.774z"
      ></path>
      <path
        fill="#fff"
        d="M467.934 343.226c0 8.37-6.791 15.136-15.143 15.136-8.373 0-15.143-6.788-15.143-15.136 0-8.37 6.792-15.136 15.143-15.136 8.352 0 15.143 6.766 15.143 15.136z"
      ></path>
    </g>
  </g>
}

export default Picture
