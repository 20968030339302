import React, { useEffect } from 'react'

import * as Styles from './AsideMenu.styled'

import Logo from '../Logo'
import StepMenu from '../StepMenu'
import Social from '../Social'

import { ILogo, INavigations, ISocials } from '../types'

interface IAsideMenuProps {
  navigations: INavigations
  logo: ILogo
  socials: ISocials
}

const AsideMenu: React.FC<IAsideMenuProps> = ({ navigations, logo, socials }) => {
  return <Styles.Root>
    <Styles.Logo>
      <Logo { ...logo } />
    </Styles.Logo>
    <Styles.Steps>
      <StepMenu navigations={ navigations } />
    </Styles.Steps>
    <Styles.Social>
      <Social socials={ socials } />
    </Styles.Social>
  </Styles.Root>
}

export default AsideMenu
