import styled, {css} from 'styled-components'
import CardLibrary from '../Card'

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(148.62deg, rgba(193, 101, 221, 0.15) 6.02%, rgba(92, 39, 254, 0.15) 94.37%), rgba(14, 11, 29, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  backdrop-filter: blur(30px);
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    flex-direction: row;
    border-radius: 30px;
  }
`

export const Card = styled(CardLibrary)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 215px;
  flex-shrink: 0;
  min-height: 175px;
  
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    padding-left: 10px;
    padding-right: 10px;
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 200px;
    min-height: 315px;
  }
`

export const CardWrapper = styled.div``

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 20px;
  }
  
  svg {
    width: 55px;
    height: 55px;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 130px;
      height: 130px;
    }
  }
`

export const CardSlogan = styled.h3<{ isOnlySlogan: boolean }>`
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: ${props => props.theme.colors.text.contrast};
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.08);
  overflow-wrap: break-word;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 28px;
  }
  
  ${props => props.isOnlySlogan && css`
    margin-bottom: 0;
    
    @media ${props.theme.device['desktop']},
    @media ${props.theme.device['desktop-medium']} {
      margin-bottom: 0;
      font-size: 20px;
      line-height: 25px;
    }    
  `}
`

export const CardTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: ${props => props.theme.colors.text.contrast};
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.08);
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 12px;
    line-height: 15px;
  }
`

export const Content = styled.div`
  padding: 15px;

  @media ${props => props.theme.device['phone']},
  @media ${props => props.theme.device['tablet']}{
    min-height:150px;
  }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding: 30px;
  }
`

export const Slogan = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: ${props => props.theme.colors.text.contrast};
  
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    display: none;
  }
`

export const Title = styled.p<{ isOnlySlogan: boolean }>`
  margin: 0;
  
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    font-size: 10px;
    line-height: 13px;
    
    ${props => props.isOnlySlogan && css`
      font-size: 12px;
      line-height: 16px;
    `}
  }
`
