import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
`

export const Tooltip = styled.div`
  .__react_component_tooltip.type-dark {
    box-sizing: border-box;
    width: 210px;
    padding: 15px;
    font-size: 11px;
    line-height: 14px;
    color: ${props => props.theme.colors.text.contrast};
    text-align: center;
    background: rgba(23, 17, 51, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(50px);
    border-radius: 30px;
    opacity: 1;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 370px;
      padding: 20px;
      font-size: 14px;
      line-height: 18px;
    }
    
    &::before,
    &::after {
      display: none;
    }
  }
`

export const Content = styled.div`
  height: 100%;
`
