import React, { useCallback, useEffect } from "react";

import Form from "../../Form";

import {
  phoneValidation,
  requiredValidation,
} from "../../Form/utils/validation";

import { useForm, Controller, NestedValue } from "react-hook-form";

import * as Styles from "./Enter.styled";

import storeApp, { TErrors } from "../../../../store/AppStore";
import Switch from "../../Switch";

export interface IEnterSubmitData {
  password: string;
  phone: {
    country: {
      name: string;
      dialCode: number;
      countryCode: string;
      format: string;
    };
    number: number;
  };
}

interface IEnterFormProps {
  cbSubmitAction: (data: IEnterSubmitData) => void;
  cbCancelAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  cbAgreeAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onlyCountries?: string[];
  phoneMasks?: {[key:string]:string}
  backendErrors?: TErrors;
}

interface IEnterFormInitialInputs {
  phone: NestedValue<{
    number: string;
    country: string;
  }>;
  password: string;
}

enum EnterFormInputNames {
  password = "password",
  phone = "phone",
}

const EnterForm: React.FC<IEnterFormProps> = ({
  cbCancelAction,
  cbAgreeAction,
  cbSubmitAction,
  onlyCountries,
  phoneMasks,
  backendErrors,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IEnterFormInitialInputs>({
    mode: "onChange",
    defaultValues: {
      [EnterFormInputNames.password]: "",
      [EnterFormInputNames.phone]: {
        number: "",
        country: "",
      },
    },
  });

  useEffect(() => {
    if (backendErrors) {
      const errors = Object.entries(backendErrors) as [
        [keyof typeof EnterFormInputNames, string]
      ];
      errors.forEach(([name, value]) => {
        if (value) {
          setError(name, {
            type: "backend",
            message: value,
          });
        }
      });
    }
  }, [backendErrors]);

  const changeType = useCallback((newType: number) => {
    storeApp.changeTypePage(newType, 'login');
  }, []);

  return (
    <Form onSubmit={handleSubmit(cbSubmitAction)}>
      <Switch
        ownerTitle={"Администраторам"}
        advTitle={"Рекламодателям"}
        initialState={storeApp.typePage === 1 ? false : true}
        onChange={changeType}
      ></Switch>
      <Form.Field isError={Boolean(errors[EnterFormInputNames.phone]?.message)}>
        <Controller
          name={EnterFormInputNames.phone}
          control={control}
          rules={{
            validate: {
              country: phoneValidation,
            },
          }}
          render={({ field }) => (
            <Form.PhoneInput
              {...field}
              onlyCountries={onlyCountries}
              masks={phoneMasks}
              placeholder="Ваш телефон"
            />
          )}
        />
        <Form.Label>Ваш телефон</Form.Label>
        {errors && errors[EnterFormInputNames.phone] && (
          <Form.Error>{errors[EnterFormInputNames.phone]?.message}</Form.Error>
        )}
      </Form.Field>

      <Form.Field
        isError={Boolean(errors[EnterFormInputNames.password]?.message)}
      >
        <Form.Input
          name={EnterFormInputNames.password}
          register={register}
          placeholder="Ваш пароль"
          type="password"
          rules={requiredValidation}
        />
        <Form.Label>Пароль</Form.Label>
        {errors && errors[EnterFormInputNames.password] && (
          <Form.Error>
            {errors[EnterFormInputNames.password]?.message}
          </Form.Error>
        )}
      </Form.Field>

      <Form.Field>
        <Form.AgreeButton onClick={cbAgreeAction}>
          Забыли пароль?
        </Form.AgreeButton>
      </Form.Field>

      <Form.Field>
        <Styles.Buttons>
          <Form.SubmitButton>Войти</Form.SubmitButton>
          <Form.CancelButton onClick={cbCancelAction}>
            Регистрация
          </Form.CancelButton>
        </Styles.Buttons>
      </Form.Field>
    </Form>
  );
};

export default EnterForm;
