import React from 'react'

import ReactModal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

import * as Styles from './Modal.styled'

export enum ModalView {
  Default = 'Default',
  Fixed = 'Fixed'
}

type ModalProps = {
  slogan?: string
  title?: string
  isOpen: boolean
  setIsClose: () => void
  view?: ModalView
}

const Index: React.FC<ModalProps> = ({ slogan = '', title = '', isOpen, setIsClose, view = ModalView.Default, children }) => {
  const onRequestClose = () => {
    setTimeout(setIsClose, 250)
  }

  return <ReactModal
    open={ isOpen }
    onClose={ onRequestClose }
    center
    focusTrapped={ false }
  >
    <Styles.Root view={ view }>
      { (slogan || title) &&
      <Styles.Header>
        { slogan && <Styles.Slogan>{ slogan }</Styles.Slogan> }
        { title && <Styles.Title>{ title }</Styles.Title> }
      </Styles.Header>
      }
      <Styles.Content>
        { children }
      </Styles.Content>
    </Styles.Root>
  </ReactModal>
}

export default Index

