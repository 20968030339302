import React from 'react'

interface IPointProps {
  cx: number
  cy: number
  r?: number
  fillOpacity?: number
}

const Point: React.FC<IPointProps> = ({ cx, cy, r = 6, fillOpacity = 0.3, }) => {
  return <circle cx={ cx } cy={ cy } r={ r } fill="white" fillOpacity={ fillOpacity } />
}

export default Point
