import styled, { css } from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'

import { resetList } from '../../../styled/mixins'

export const Root = styled.nav``

export const List = styled.ul`
  ${resetList};
    @media ${props => props.theme.device['desktop']}{
      display:flex;
      align-items:center;
  }
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex;
  }
`

export const Item = styled.li<{ isMore: boolean }>`
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
    @media ${props => props.theme.device['desktop']}{
    margin-right: 25px;

      &:nth-child(-n+4){
      flex-basis: 90px;
    }

      &:nth-child(n+5){
      flex-shrink: 0;
    }

      ${props => !props.isMore && css`
        margin-right: 70px;
      `}

    &:last-child {
      margin-right: 0;
    }
  }
    @media ${props => props.theme.device['desktop-medium']} {
    margin-right: 25px;

    &:nth-child(-n + 4) {
      flex-basis: auto;
    }

    ${props => !props.isMore && css`
        margin-right: 70px;
      `}

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Name = styled.span`
  position: relative;
  display: inline-flex;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 1px;
    background-color: ${props => props.theme.colors.thirty.default};
    border-radius: 10px;
    transition: width 0.2s ease-in-out;
  }
`

const LinkStyledCSS = css`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.theme.colors.text.contrast};
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    display: block;
    text-align: center;
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;
  }

  &:hover {
    color: ${props => props.theme.colors.thirty.default};
    cursor: pointer;
  }
`

export const Link = styled(ScrollLink)`
  ${LinkStyledCSS};

  &.selected {
    color: ${props => props.theme.colors.thirty.default};

    ${Name}::after {
      width: 100%;
    }
  }
`

export const ExternalLink = styled.a`
  ${LinkStyledCSS};
`
