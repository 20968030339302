import React from 'react'

import Modal from '../../common/Modal'
import Button from '../../common/Button'

import storeApp from '../../../store/AppStore'
import { observer } from 'mobx-react-lite'
import { EModalNames } from '../Modals.type'

import * as Styles from './Info.styled'

const InfoModal: React.FC = () => {
  const onClickCloseModal = () => {
    storeApp.modalClose(EModalNames.Info)
    storeApp.resetInfoModalData()
  }

  const onClickClose = storeApp.infoModalData.cbOnClickClose || onClickCloseModal

  return <Modal
    slogan={ storeApp.infoModalData.slogan }
    title={ storeApp.infoModalData.title }
    view={ storeApp.infoModalData.view }
    isOpen={ storeApp.modalOpenNames.includes(EModalNames.Info) }
    setIsClose={ onClickCloseModal }
  >
    <Styles.Container isContent={ Boolean(storeApp.infoModalData.content) }>
      {
        storeApp.infoModalData.content &&
        <Styles.Content dangerouslySetInnerHTML={ {__html: storeApp.infoModalData.content } } />
      }
      {
        storeApp.infoModalData.buttonTitle &&
          <Styles.Action>
            <Button onClick={ onClickClose }>{ storeApp.infoModalData.buttonTitle }</Button>
          </Styles.Action>
      }
    </Styles.Container>
  </Modal>
}

export default observer(InfoModal)
