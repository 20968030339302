import React from 'react'

const ToggleList = () => {
  return <g filter="url(#filter10_welcome_rk_d)">
      <g>
        <path
          d="M402.956 278.831C398.948 278.831 395.686 275.568 395.686 271.561V207.637C395.686 203.629 398.948 200.366 402.956 200.366H754.73C758.737 200.366 762 203.629 762 207.637V271.561C762 275.568 758.737 278.831 754.73 278.831H402.956Z"
          fill="white"
          fillOpacity={0.3}
        />
        <path
          d="M402.956 278.331C399.225 278.331 396.186 275.292 396.186 271.561V207.637C396.186 203.905 399.225 200.866 402.956 200.866H754.73C758.461 200.866 761.5 203.905 761.5 207.637V271.561C761.5 275.292 758.461 278.331 754.73 278.331H402.956Z"
          stroke="white"
          strokeOpacity={0.4}
        />
      </g>
      <g filter="url(#filter12_welcome_rk_d)">
        <path
          d="M439.109 264.619C425.313 264.619 414.102 253.407 414.102 239.61C414.102 225.814 425.313 214.601 439.109 214.601C452.905 214.601 464.117 225.814 464.117 239.61C464.117 253.407 452.883 264.619 439.109 264.619Z"
          fill="white"
        />
      </g>
      <path
        d="M429.86 231.54C429.86 230.987 430.308 230.54 430.86 230.54H436.164V236.847H429.86V231.54Z"
        stroke="#BFD2E3"
        strokeWidth={2}
      />
      <path
        d="M441.485 230.54H446.788C447.34 230.54 447.788 230.987 447.788 231.54V236.847H441.485V230.54Z"
        stroke="#BFD2E3"
        strokeWidth={2}
      />
      <path
        d="M429.86 242.169H436.164V248.476H430.86C430.308 248.476 429.86 248.029 429.86 247.476V242.169Z"
        stroke="#BFD2E3"
        strokeWidth={2}
      />
      <path
        d="M441.485 242.169H447.788V247.476C447.788 248.029 447.34 248.476 446.788 248.476H441.485V242.169Z"
        stroke="#BFD2E3"
        strokeWidth={2}
      />
      <path
        opacity={0.2}
        d="M626.384 234.135H489.629C487.396 234.135 485.6 232.317 485.6 230.106C485.6 227.872 487.418 226.076 489.629 226.076H626.384C628.618 226.076 630.414 227.894 630.414 230.106C630.414 232.317 628.618 234.135 626.384 234.135Z"
        fill="#204A74"
      />
      <path
        opacity={0.2}
        d="M739.073 234.135H644.713C642.479 234.135 640.684 232.317 640.684 230.106C640.684 227.872 642.501 226.076 644.713 226.076H739.073C741.306 226.076 743.102 227.894 743.102 230.106C743.124 232.317 741.306 234.135 739.073 234.135Z"
        fill="#204A74"
      />
      <path
        opacity={0.2}
        d="M650.823 254.28H556.463C554.229 254.28 552.434 252.463 552.434 250.251C552.434 248.017 554.251 246.221 556.463 246.221H650.823C653.056 246.221 654.852 248.039 654.852 250.251C654.874 252.463 653.056 254.28 650.823 254.28Z"
        fill="#204A74"
      />
      <path
        opacity={0.2}
        d="M538.441 254.502H489.629C487.396 254.502 485.6 252.684 485.6 250.472C485.6 248.238 487.418 246.443 489.629 246.443H538.441C540.674 246.443 542.47 248.26 542.47 250.472C542.47 252.706 540.674 254.502 538.441 254.502Z"
        fill="#204A74"
      />
    </g>
}

export default ToggleList
