import React from 'react'

import Tooltip from '../common/Tooltip'

import * as Styles from './Advantages.styled'

import { TAdvantages } from '../types'

interface IAdvantagesProps {
  advantages: TAdvantages
  isHTML?: boolean
}

const Advantages: React.FC<IAdvantagesProps> = ({ advantages, isHTML = false }) => {
  return <Styles.List>
    {
      advantages.map((advantage) => {
        const Icon = advantage.Icon

        return <Styles.Item key={ advantage.id }>
          <Tooltip
            idPrefix={`${advantage.id}advantage`}
            component={<>{ advantage.tooltip }</>}
            hoverComponent={
                <Styles.Card backgroundColor={ getBackgroundColor(advantage.id) }>
                  <Styles.IconContainer>
                    <Icon />
                  </Styles.IconContainer>
                  {
                    isHTML ? <Styles.Title dangerouslySetInnerHTML={{ __html: String(advantage.title) }} />
                      : <Styles.Title>{ advantage.title }</Styles.Title>
                  }
                </Styles.Card>
            }
          />
        </Styles.Item>
      })
    }
  </Styles.List>
}

const getBackgroundColor = (id: number): string => {
  switch(id) {
    case 1: return 'linear-gradient(148.62deg, #FACD68 5.73%, #FC76B3 93.75%)'
    case 2: return 'linear-gradient(148.62deg, #C165DD 6.02%, #5C27FE 94.37%)'
    case 3: return 'linear-gradient(148.62deg, #1DE5E2 6.25%, #B588F7 94.27%)'
    case 4: return 'linear-gradient(148.62deg, #00ADE4 6.17%, #04F5ED 94.27%)'
    case 5: return 'linear-gradient(148.62deg, #2AFEB7 5.21%, #08C7BC 93.75%)'
    case 6: return 'linear-gradient(148.62deg, #55AFF1 5.21%, #1153FC 95.31%)'
    default: return ''
  }
}

export default Advantages
