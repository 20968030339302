import React from 'react'

const ToggleList = () => {
  return <g>
    <g filter="url(#filter2_welcome_vp_d)">
      <g>
        <path
          fill="#fff"
          fillOpacity="0.3"
          d="M314.001 133.448c-4.022 0-7.297-3.273-7.297-7.294V62.028c0-4.02 3.275-7.294 7.297-7.294h353.052c4.022 0 7.297 3.274 7.297 7.294v64.126c0 4.021-3.275 7.294-7.297 7.294H314.001z"
        ></path>
        <path
          stroke="#fff"
          strokeOpacity="0.4"
          d="M314.001 132.948c-3.746 0-6.797-3.05-6.797-6.794V62.028c0-3.744 3.051-6.794 6.797-6.794h353.052c3.746 0 6.797 3.05 6.797 6.794v64.126c0 3.744-3.051 6.794-6.797 6.794H314.001z"
        ></path>
      </g>
      <g>
        <path
          fill="#fff"
          d="M350.286 119.19c-13.846 0-25.098-11.247-25.098-25.088 0-13.84 11.252-25.088 25.098-25.088 13.846 0 25.099 11.248 25.099 25.088 0 13.841-11.275 25.088-25.099 25.088z"
        ></path>
      </g>
      <path
        stroke="#BFD2E3"
        strokeWidth="2"
        d="M341 86a1 1 0 011-1h5.333v6.333H341V86zM352.667 85H358a1 1 0 011 1v5.333h-6.333V85zM341 96.667h6.333V103H342a1 1 0 01-1-1v-5.333zM352.667 96.667H359V102a1 1 0 01-1 1h-5.333v-6.333z"
      ></path>
      <path
        fill="#fff"
        d="M538.241 88.61H400.989a4.046 4.046 0 01-4.044-4.042 4.046 4.046 0 014.044-4.042h137.252a4.046 4.046 0 014.044 4.042 4.046 4.046 0 01-4.044 4.042zM651.339 88.61h-94.702a4.046 4.046 0 01-4.044-4.042 4.046 4.046 0 014.044-4.042h94.702a4.046 4.046 0 014.044 4.042c.022 2.219-1.802 4.042-4.044 4.042zM449.978 109.041h-48.989a4.046 4.046 0 01-4.044-4.042 4.046 4.046 0 014.044-4.042h48.989a4.046 4.046 0 014.044 4.042 4.032 4.032 0 01-4.044 4.042z"
      ></path>
    </g>
  </g>
}

export default ToggleList
