import React from 'react'

import * as Styles from './ListWithEllipse.styled'

interface ItemWithEllipseProps {
  contentEllipse: any
  isHTML?: boolean
}

interface ItemWithEllipse {
  id: number
  content: any
  title: string | Array<any>
}

interface ListWithEllipseProps {
  className?: string
  items: ItemWithEllipse[]
  isFlowColumn?: boolean
  isHTML?: boolean
}

export const ItemWithEllipse: React.FC<ItemWithEllipseProps> = ({ children, contentEllipse, isHTML = false }) => {
  return <Styles.Root>
    <Styles.Ellipse>
      { contentEllipse }
    </Styles.Ellipse>
    {
      isHTML ? <Styles.Title dangerouslySetInnerHTML={{ __html: String(children) }} />
        : <Styles.Title>
          { children }
        </Styles.Title>
    }
  </Styles.Root>
}

export const ListWithEllipse: React.FC<ListWithEllipseProps> = ({ items, className, isFlowColumn = false, isHTML = false }) => {
  return <Styles.List className={ className } isFlowColumn={ isFlowColumn }>
    {
      items.map(item => (
        <Styles.Item key={ item.id }>
          <ItemWithEllipse contentEllipse={ item.content } isHTML={ isHTML }>
            { item.title }
          </ItemWithEllipse>
        </Styles.Item>
      ))
    }
  </Styles.List>
}

export default ListWithEllipse
