import React from 'react'

import * as Styles from './Down.styled'
import { ReactComponent as ArrowSVG } from '../../../svg/common/arrow.svg'

interface IDownProps {
  to: string
}

const Down: React.FC<IDownProps> = ({ to }) => {
  return <Styles.Root>
    <Styles.Link
      to={ to }
      activeClass="selected"
      spy
      smooth
    >
      <ArrowSVG />
    </Styles.Link>
  </Styles.Root>
}

export default Down
