import React from 'react'

import * as Styles from './HeaderMobile.styled'

import Navigation from '../../Navigation'
import HeaderActions from '../HeaderActions'
import Social from '../../Social'
import Logo from '../../Logo'
import ButtonWithIcon from '../../ButtonWithIcon'

import { ReactComponent as UserSVG } from '../../../../svg/common/user.svg'

import { IExternalNavigations, ILogo, INavigations, ISocials } from '../../types'

interface IHeaderMobileProps {
  navigations: INavigations
  externalLinks?: IExternalNavigations
  socials: ISocials
  logo: ILogo
  onClickMenuButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickUserButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickRegisterButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickLinkMenu: () => void
  isOpenMenu: boolean
}

const HeaderMobile: React.FC<IHeaderMobileProps> = ({ navigations, externalLinks, socials, logo, onClickMenuButton, isOpenMenu, onClickUserButton, onClickRegisterButton, onClickLinkMenu }) => {
  return <Styles.Root>
    <Styles.Line>
      <Styles.ButtonMenu>
        <Styles.ButtonMenuStyle onClick={ onClickMenuButton } isOpen={ isOpenMenu }>
          <Styles.ButtonLine isOpen={ isOpenMenu } />
        </Styles.ButtonMenuStyle>
      </Styles.ButtonMenu>
      <Styles.Logo>
        <Logo { ...logo }  />
      </Styles.Logo>
      <Styles.ButtonUser>
        <ButtonWithIcon onClick={ onClickUserButton }>
          <UserSVG />
        </ButtonWithIcon>
      </Styles.ButtonUser>
    </Styles.Line>
    <Styles.Menu isOpen={ isOpenMenu }>
      <Styles.MenuLogo>
        <Logo { ...logo }  />
      </Styles.MenuLogo>
      <Styles.MenuContent>
        <Styles.Nav>
          <Navigation
            navigations={ navigations }
            externalLinks={ externalLinks }
            onClickLink={ onClickLinkMenu }
          />
        </Styles.Nav>
        <Styles.Actions>
          <HeaderActions
            onClickEnterButton={ onClickUserButton }
            onClickRegisterButton={ onClickRegisterButton }
          />
        </Styles.Actions>
        <Styles.Social>
          <Social socials={ socials } />
        </Styles.Social>
      </Styles.MenuContent>
    </Styles.Menu>
  </Styles.Root>
}

export default HeaderMobile
