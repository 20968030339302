import styled from 'styled-components'

export const Root = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  display: flex;
  flex-direction: column;
  width: 110px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(10px);
`

export const Logo = styled.div`
  width: 70px;
  height: 19px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`

export const Steps = styled.div`
  margin-top: auto;
  padding-left: 45px;
  margin-bottom: auto;
`

export const Social = styled.div`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`
