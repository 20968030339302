import React from 'react'

import { ReactComponent as CpaSVG } from './cpa.svg'
import { ReactComponent as MpoSVG } from './mpo.svg'
import { ReactComponent as YoutubeSVG } from './youtube.svg'
import { ReactComponent as MpwSVG } from './mpw.svg'
import { ReactComponent as NbSVG } from './nb.svg'
import { ReactComponent as PbSVG } from './pb.svg'
import { ReactComponent as CalendarSVG } from './calendar.svg'
import { ReactComponent as CpmSVG } from './cpm.svg'
import { ReactComponent as FlexSVG } from './flex.svg'
import { ReactComponent as KpiSVG } from './kpi.svg'
import { ReactComponent as OptimizationSVG } from './optimization.svg'
import { ReactComponent as RocketSVG } from './rocket.svg'
import { ReactComponent as SegmentSVG } from './segment.svg'
import { ReactComponent as StarSVG } from './star.svg'
import { ReactComponent as StaticsSVG } from './statics.svg'
import { ReactComponent as TargetSVG } from './target.svg'
import { ReactComponent as WalletSVG } from './wallet.svg'

export type TSliderIcons = {
  mpo: any
  youtube: any
  nb: any
  pb: any
  cpa: any
  mpw: any
  calendar: any
  cpm: any
  flex: any
  kpi: any
  optimization: any
  rocket: any
  segment: any
  star: any
  statics: any
  target: any
  wallet: any
}

const monetizationMethodsList: TSliderIcons = {
  mpo: MpoSVG,
  youtube: YoutubeSVG,
  nb: NbSVG,
  pb: PbSVG,
  cpa: CpaSVG,
  mpw: MpwSVG,
  calendar: CalendarSVG,
  cpm: CpmSVG,
  flex: FlexSVG,
  kpi: KpiSVG,
  optimization: OptimizationSVG,
  rocket: RocketSVG,
  segment: SegmentSVG,
  star: StarSVG,
  statics: StaticsSVG,
  target: TargetSVG,
  wallet: WalletSVG,
}

export const getSliderIcon = (name: keyof TSliderIcons): React.FunctionComponent => monetizationMethodsList[name]
