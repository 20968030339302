import React from 'react'

import * as Styles from './HeaderActions.styled'

import { ButtonView, ButtonSize } from '../../Button'

interface IHeadersActionsProps {
  onClickEnterButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickRegisterButton: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const HeaderActions: React.FC<IHeadersActionsProps> = ({ onClickEnterButton, onClickRegisterButton }) => {
  return (
    <Styles.List>
      <Styles.Item>
        <Styles.Button view={ButtonView.Outline} size={ButtonSize.Small} onClick={ onClickRegisterButton } >
          Регистрация
        </Styles.Button>
      </Styles.Item>
      <Styles.Item>
        <Styles.Button size={ButtonSize.Small} onClick={ onClickEnterButton }>
          Войти
        </Styles.Button>
      </Styles.Item>
    </Styles.List>
  );
}

export default HeaderActions

