import styled from 'styled-components'

import { resetList } from '../../../styled/mixins'

import { Link as ScrollLink } from 'react-scroll'

export const List = styled.ul`
  ${resetList};
`

export const Item = styled.li`
  margin-bottom: 25px;
  
  &:last-child {
    margin-bottom: 0;
  }
`

export const Link = styled(ScrollLink)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.2s ease-in-out;
  
  &:hover {
    color: ${props => props.theme.colors.thirty.default};
    cursor: pointer;
  }
  
  &::after {
    content: "";
    flex-grow: 0;
    height: 1px;
    margin-left: 10px;
    background-color: ${props => props.theme.colors.thirty.default};
    transform: translateY(-50%);
    transition: flex-grow 0.2s ease-in-out;
  }
  
  &.selected {
    color: ${props => props.theme.colors.thirty.default};
    
    &::after {
      flex-grow: 1;
    }
  }
`
