import styled, { css } from 'styled-components'

import { container } from '../../../styled/mixins'
import {isMobileSafari} from "react-device-detect";

export const Root = styled.header<{ isSticky?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: backdrop-filter 0.2s ease-in-out, background-color 0.2s ease-in-out;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding-left: 110px;
  }
  
  ${props => props.isSticky && css`
    background-color: rgba(23, 17, 51, 0.3);
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      backdrop-filter: blur(5px);
    }
    
    @supports not ((backdrop-filter: blur(60px)) or (-webkit-backdrop-filter: blur(60px))) {
      & {
        background-color: rgba(23, 17, 51, 0.95);
      }
    }
  `}
`

export const Container = styled.div`
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    padding-left: 0;
    padding-right: 0;
  }

  ${container};
`
