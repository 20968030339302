import styled, { css } from 'styled-components'
import { LabelAgreeColor } from './index'
import { placeholderColor, scrollbar } from '../../../styled/mixins'

import SearchSVG from '../../../svg/common/search.svg'


export const Form = styled.form``

const inputPlaceholderCSS = css`
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.4);
`

const inputCSS = css`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 12px 15px;
  font-size: 14px;
  line-height: 18px;
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.text.contrast};
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: border-color 0.15s ease-in-out;
  
  ${placeholderColor(inputPlaceholderCSS)};
  
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 0 white inset; 
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${props => props.theme.colors.text.contrast};
    color: ${props => props.theme.colors.text.contrast};
  }
  
  &:hover {
    border-color: rgba(255, 255, 255, 0.4);
  }
  
  &:focus {
    border-color: rgba(255, 255, 255, 0.6);
    outline: none;
  }
`

const inputWithLabelCSS = css`
  &:focus {
    padding-top: 17px;
    padding-bottom: 7px;
  }
  
  &:focus:placeholder-shown {
    -webkit-text-fill-color: transparent;
  }
  
  &:not(:placeholder-shown) {
    padding-top: 17px;
    padding-bottom: 7px;
  }
`

export const Input = styled.input`
  ${inputCSS};
  ${inputWithLabelCSS};
`

export const Textarea = styled.textarea`
  ${inputCSS};
  ${inputWithLabelCSS};
  ${scrollbar('rgba(255, 255, 255, 0.10)')};
  resize: vertical;
  min-height: 70px;
  max-height: 150px;
`

export const TextError = styled.p`
  position: absolute;
  bottom: -15px;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${props => props.theme.colors.indicator.error.default};
`

export const PhoneInput = styled.div<{ isError?: boolean }>`
  & .react-tel-input .form-control {
    ${inputCSS};
    height: auto;
    padding-left: 55px;
    
    ${props => props.isError && css`
      border-color: ${props.theme.colors.indicator.error.default};
    `}
  }
  
  & .react-tel-input .flag-dropdown {
    width: 50px;
    border: none;
    background-color: transparent;
    
    &.open {
      background: transparent;
      border-radius: 0;
    }
  }
  
  & .react-tel-input .selected-flag {
    padding-left: 15px;
    
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
  
  & .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
    border-radius: 0;
  }
  
  & .react-tel-input .selected-flag .arrow {
    left: 25px;
    border-top: 4px solid ${props => props.theme.colors.text.default};
    
    &.up {
      border-top: none;
      border-bottom: 4px solid ${props => props.theme.colors.text.default};
    }
  }
  
  & .react-tel-input .flag {
    width: 15px;
    height: 12px;
    border: none;
    border-radius: 2px;
  }
  
  & .react-tel-input .country-list {
    width: 280px;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: ${props => props.theme.colors.main.darken};
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    
    ${scrollbar('rgba(255, 255, 255, 0.10)')};

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 370px;
    }
  }
  
  & .react-tel-input .country-list .search {
    top: -5px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: ${props => props.theme.colors.main.darken};
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  & .react-tel-input .country-list .search-emoji {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    font-size: 0;
    background-image: url(${SearchSVG});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }
  
  & .react-tel-input .country-list .search-box {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.colors.text.contrast};
    background-color: ${props => props.theme.colors.main.darken};
    border: none;
    
    ${placeholderColor(inputPlaceholderCSS)};
  }
  
  & .react-tel-input .country-list .country {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.colors.text.contrast};
    transition: background-color 0.1s linear, color 0.1s linear;
    
    &:hover {
      color: #79BDFF;
      background-color: transparent;
    }
    
    &.highlight {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 5px;
    }
  }
  
  & .react-tel-input .country-list .country .flag {
    margin-right: 10px;
  }
  
  & .react-tel-input .country-list .country .dial-code {
    margin-left: auto;
    color: ${props => props.theme.colors.text.contrast};
  }
`

export const Field = styled.div<{ isError?: boolean }>`
  position: relative;
  margin-bottom: 15px;
  
  ${props => props.isError && css`
    ${Input} {
      border-color: ${props.theme.colors.indicator.error.default};
    }
    
    ${PhoneInput} {
      & input.form-control[type="tel"] {
        border-color: ${props.theme.colors.indicator.error.default};
      }
    }
  `}
  
  &:last-child {
    margin-bottom: 0;
  }
  
  @media ${props => props.theme.device.desktop} {
    margin-bottom: 30px;
  }
`

export const Label = styled.label`
  position: absolute;
  top: 5px;
  left: 17px;
  font-size: 10px;
  line-height: 14px;
  color: ${props => props.theme.colors.gray.dark};
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out, visibility 0.15s ease-out;
  
  ${Input}:focus + &,
  ${Input}:not(:placeholder-shown) + & {
    opacity: 1;
    visibility: visible;
  }
`

export const LabelAgree = styled.label`
  display: flex;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.color === LabelAgreeColor.Contrast ? props.theme.colors.gray.lighten : props.theme.colors.text.default};
  cursor: pointer;
`

export const Checkbox = styled.input.attrs({type: 'checkbox'})`
  display: none;
`

export const CheckboxIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-right: 15px;
  background-color: ${props => props.theme.colors.gray.lighten};
  border: 2px solid ${props => props.theme.colors.gray.default};
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  svg {
    width: 14px;
    height: 14px;
    fill: none;
    stroke: transparent;
    stroke-width: 2px;
  }

  ${Checkbox}:checked + & svg {
    stroke: ${props => props.theme.colors.gray.lighten};
  }
  
  ${LabelAgree}:hover &,
  &:hover {
    border-color: ${props => props.theme.colors.thirty.default};
  }
  
  ${Checkbox}:checked + & {
    border-color: ${props => props.theme.colors.thirty.default};
    background-color: ${props => props.theme.colors.thirty.default};
  }
  
  ${LabelAgree}:hover > ${Checkbox}:checked + &,
  ${Checkbox}:checked + &:hover {
    border-color: ${props => props.theme.colors.thirty.light};
    background-color: ${props => props.theme.colors.thirty.light};
  }
`

export const AgreeContent = styled.div``

export const AgreeButton = styled.button`
  display: inline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: inherit;
  font-family: inherit;
  color: ${props => props.theme.colors.thirty.default};
  text-decoration: none;
  cursor: pointer;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 16px;
    line-height: 20px;
  }
  
  &:hover {
    text-decoration: none;
    text-decoration: underline;
  }
  
  &:focus {
    outline: none;
  }
`
