import React from 'react'

import { ReactComponent as VkSVG } from './vk.svg'
import { ReactComponent as TgSVG } from './tg.svg'
import { ReactComponent as OdSVG } from './od.svg'
import { ReactComponent as InstSVG } from './insts-disabled.svg'
import { ReactComponent as FbSVG } from './fb-disabled.svg'

export type TSocialStairsList = {
  tg: any
  vk: any
  od: any
  inst: any
  fb: any
}

const socialList: TSocialStairsList = {
  vk: VkSVG,
  tg: TgSVG,
  od: OdSVG,
  inst: InstSVG,
  fb: FbSVG,
}

export const getSocialStairsIcon = (name: keyof TSocialStairsList): React.FunctionComponent => socialList[name]
