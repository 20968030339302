import styled from 'styled-components'
import { resetList } from '../../styled/mixins'

// List Styling
export const List = styled.ul`
  ${resetList};
`

export const Item = styled.li`
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
  }
`

// Item Styling
export const Root = styled.div`
  display: flex;
  align-items: center;
`

export const Oval = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }
  
  svg {
    width: 30px;
    height: 30px;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 40px;
      height: 40px;
    }
  }
`

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.theme.colors.text.contrast};
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;
  }
`
