// @ts-ignore

export const overridePosition = ({ left, top }, currentEvent, currentTarget, node) => {
  const documentEl = document.documentElement;
  const paddingLeftEl = 15;

  let topPosition = Math.min(documentEl.clientHeight - node.clientHeight, top);
  let leftPosition = Math.min(documentEl.clientWidth - node.clientWidth, left);

  topPosition = Math.max(0, topPosition);
  leftPosition = Math.max(paddingLeftEl, leftPosition);

  return {
    top: topPosition,
    left: leftPosition,
  }
}
