import styled, { css } from 'styled-components'

const cardGeneralStyle = css`
  border-radius: 40px;
  border-top-left-radius: 0;
`

export const Content = styled.div`
  position: relative;
  z-index: 1;
  padding: 15px;
  
  ${cardGeneralStyle};
  background: rgba(23, 17, 51, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(60px);
  transition: transform 0.25s ease-in-out;
  
  @supports not ((backdrop-filter: blur(60px)) or (-webkit-backdrop-filter: blur(60px))) {
    & {
      background: rgba(23, 17, 51, 0.95);
    }
  }
    @media ${props => props.theme.device['desktop']}{
      padding: 20px;
    }
    @media ${props => props.theme.device['desktop-medium']} {
      padding: 30px;
  }
`

export const Substrate = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 5%;
  bottom: 0;
  ${cardGeneralStyle};
  background: linear-gradient(148.62deg, #C165DD 6.02%, #5C27FE 94.37%);
  transform: skewY(2deg);
  transform-origin: left top;
  transition: transform 0.25s ease-in-out, right 0.25s ease-in-out;
`

export const Root = styled.div<{ isSkew: boolean }>`
  position: relative;
  
  &:hover ${Substrate} {
    right: 8%;
    transform: skewY(4deg);
  }
  
  ${props => props.isSkew && css`
    ${Substrate} {
      transform: skewY(4deg);
    } 
  `}
`
