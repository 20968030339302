import React, { useEffect, useState } from 'react'

import { ReactComponent as WelcomeImageSVG } from './Original.svg'

import { useSpring, useSprings, animated } from 'react-spring'
import { WelcomeImageProps } from '../../types'


import MessageHeart from './MessageHeart'
import Message from './Message'
import Gear from './Gear'
import Picture from './Picture'
import SocialOd from './SocialOd'
import SocialVk from './SocialVk'
import Search from './Search'
import User from './User'
import ToggleList from './ToggleList'
import Point from './Point'

import { itemsList } from './List'

const WelcomeImage: React.FC<WelcomeImageProps> = ({ isExternalAnimation = true }) => {
  const [isAnimation, setIsAnimation] = useState(isExternalAnimation)

  useEffect(() => {
    setIsAnimation(_ => isExternalAnimation)
  }, [isExternalAnimation])

  const styleMessageHeart = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(-150px, 0px, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 1300,
    config: {
      duration: 500,
    }
  })

  const styleMessage = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(0, 50px, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 1300,
    config: {
      duration: 500,
    }
  })

  const styleGear = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(-20px, 0, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 800,
    config: {
      duration: 500,
    }
  })

  const stylePicture = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(150px, 0, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 800,
    config: {
      duration: 500,
    }
  })

  const styleSocialOd = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(0px, 5px, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 1300,
    config: {
      duration: 500,
    }
  })

  const styleSocialVk = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(25px, 0px, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 1300,
    config: {
      duration: 500,
    }
  })

  const styleSearch = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(180px, 0px, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 800,
    config: {
      duration: 500,
    }
  })

  const styleUser = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(70px, 0px, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 1300,
    config: {
      duration: 500,
    }
  })

  const styleToggleList = useSpring({
    transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(0px, 50px, 0px)`,
    opacity: isAnimation ? 1 : 0,
    delay: 800,
    config: {
      duration: 500,
    }
  })

  const stylePoint = useSpring({
    opacity: isAnimation ? 1 : 0,
    delay: 1800,
    config: {
      duration: 500,
    }
  })

  const stylesList = useSprings(
    itemsList.length,
    itemsList.map((_, index) => {
      return {
        transform: isAnimation ? `translate3d(0px, 0px, 0px)` : `translate3d(0px, -200px, 0px)`,
        opacity: isAnimation ? 1 : 0,
        delay: index * 150,
        config: {
          duration: 500,
        }
      }
    })
  )

  return <>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="692"
    height="474"
    fill="none"
    viewBox="0 0 692 474"
  >
    <g>
      <g>
        <rect
          width="593.994"
          height="300.087"
          x="50.403"
          y="94.2"
          fill="#fff"
          fillOpacity="0.3"
          rx="35"
        ></rect>
        <rect
          width="592.994"
          height="299.087"
          x="50.903"
          y="94.7"
          stroke="#fff"
          strokeOpacity="0.4"
          rx="34.5"
        ></rect>
      </g>
      <path
        fill="#fff"
        fillOpacity="0.5"
        d="M605.801 104.647H89.321c-15.67 0-28.374 12.698-28.374 28.362v97.496a9.543 9.543 0 006.396 9.007 5.047 5.047 0 013.384 4.768 5.046 5.046 0 01-3.384 4.767 9.544 9.544 0 00-6.396 9.007v97.519c0 15.663 12.703 28.361 28.374 28.361H605.8c15.67 0 28.373-12.698 28.373-28.361V133.009c0-15.664-12.703-28.362-28.373-28.362z"
      ></path>
      <path
        fill="url(#paint0_welcome_vp_linear)"
        d="M432.396 104.647H89.321c-15.67 0-28.374 12.698-28.374 28.362v97.518a9.543 9.543 0 006.396 9.007 5.047 5.047 0 013.384 4.768 5.046 5.046 0 01-3.384 4.767 9.544 9.544 0 00-6.396 9.007v97.519c0 15.663 12.703 28.361 28.374 28.361h343.075V104.647z"
      ></path>
      <mask
        id="mask0_welcome_vp"
        width="373"
        height="280"
        x="60"
        y="104"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="url(#paint1_welcome_vp_linear)"
          d="M432.396 104.647H89.321c-15.67 0-28.374 12.698-28.374 28.362v97.518a9.543 9.543 0 006.396 9.007 5.047 5.047 0 013.384 4.768 5.046 5.046 0 01-3.384 4.767 9.544 9.544 0 00-6.396 9.007v97.519c0 15.663 12.703 28.361 28.374 28.361h343.075V104.647z"
        ></path>
      </mask>
      <g mask="url(#mask0_welcome_vp)">
        <g
          stroke="#fff"
          strokeDasharray="5 7"
          strokeLinecap="round"
          opacity="0.5"
        >
          <path d="M71 145h351M71 185h351M71 225h351M71 265h351M71 305h351M71 345h351"></path>
        </g>
        <path
          fill="url(#paint2_welcome_vp_linear)"
          fillRule="evenodd"
          d="M61 384s28.984-153.885 40.625-153.885c11.64 0 37.858 114.632 66.875 114.632 29.016 0 38.182-39.837 75.583-55.767 37.402-15.931 39.634 75.073 58.144 75.073 18.51 0 46.19-95.365 59.659-95.365 12.749 0 17.498 95.365 56.718 95.365S482.291 169 500.067 169C517.842 169 537 384 537 384H61z"
          clipRule="evenodd"
        ></path>
        <path
          fill="url(#paint3_welcome_vp_linear)"
          fillRule="evenodd"
          d="M61 384s26.054-48.657 47.974-48.657c21.921 0 23.385 22.324 63.186 22.324s26.889-103.637 53.215-103.637c15.902 0 15.957 81.313 35.223 81.313 21.597 0 36.88-194.343 52.752-194.343 20.129 0 30.628 173.516 48.732 173.516 18.103 0 29.189-99.379 47.666-99.379 16.408 0 28.014 102.966 39.781 114.564 12.415 12.237 18.133-85.115 41.475-85.115C514.345 244.586 537 384 537 384H61z"
          clipRule="evenodd"
        ></path>
      </g>
      {
        stylesList.map((animatedStyle, index) => (
          <animated.g
            key={ index }
            style={animatedStyle}
          >
            { itemsList[index] }
          </animated.g>
        ))
      }
      <animated.g style={ stylePicture }>
        <Picture />
      </animated.g>
      <animated.g style={ stylePoint }>
        <Point />
      </animated.g>
      <animated.g style={ styleToggleList }>
        <ToggleList />
      </animated.g>
      <animated.g style={ styleMessageHeart }>
        <MessageHeart />
      </animated.g>
      <animated.g style={ styleMessage }>
        <Message />
      </animated.g>
      <animated.g style={ styleSocialOd }>
        <SocialOd />
      </animated.g>
      <animated.g style={ styleUser }>
        <User />
      </animated.g>
      <animated.g style={ styleSearch }>
        <Search />
      </animated.g>
      <animated.g style={ styleSocialVk }>
        <SocialVk />
      </animated.g>
      <animated.g style={ styleGear }>
        <Gear />
      </animated.g>
    </g>
    <defs>
      <filter
        id="filter0_welcome_vp_bd"
        width="641.994"
        height="348.087"
        x="16.403"
        y="80.2"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImage"
          stdDeviation="2"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur"
        ></feComposite>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-10" dy="10"></feOffset>
        <feGaussianBlur stdDeviation="12"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.0862745 0 0 0 0 0.211765 0 0 0 0.2 0"></feColorMatrix>
        <feBlend
          in2="effect1_backgroundBlur"
          result="effect2_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter1_welcome_vp_d"
        width="262.306"
        height="189.06"
        x="292.726"
        y="285.113"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-10" dy="10"></feOffset>
        <feGaussianBlur stdDeviation="12"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.0862745 0 0 0 0 0.211765 0 0 0 0.2 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter id="blur_welcome_vp">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blurred" />
      </filter>
      <filter
        id="filter2_welcome_vp_d"
        width="415.646"
        height="126.713"
        x="272.704"
        y="40.734"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-10" dy="10"></feOffset>
        <feGaussianBlur stdDeviation="12"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.0862745 0 0 0 0 0.211765 0 0 0 0.2 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter3_welcome_vp_b"
        width="387.646"
        height="98.713"
        x="296.704"
        y="44.734"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImage"
          stdDeviation="5"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter4_welcome_vp_d"
        width="98.198"
        height="98.176"
        x="291.188"
        y="55.014"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-10" dy="10"></feOffset>
        <feGaussianBlur stdDeviation="12"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.0862745 0 0 0 0 0.211765 0 0 0 0.2 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <pattern
        id="pattern0_welcome_vp"
        width="1"
        height="1"
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#image0"></use>
      </pattern>
      <filter
        id="filter5_welcome_vp_d"
        width="56.747"
        height="49.877"
        x="159.463"
        y="19.948"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="7"></feOffset>
        <feGaussianBlur stdDeviation="5"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.316724 0 0 0 0 0.1325 0 0 0 0 0.6625 0 0 0 0.5 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter6_welcome_vp_d"
        width="102.381"
        height="37.441"
        x="19.541"
        y="348.284"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="7"></feOffset>
        <feGaussianBlur stdDeviation="5"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.804167 0 0 0 0 0.281458 0 0 0 0 0.330771 0 0 0 0.4 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter7_welcome_vp_d"
        width="32"
        height="32"
        x="485"
        y="432"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="3"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.804167 0 0 0 0 0.281458 0 0 0 0 0.330771 0 0 0 0.4 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter8_welcome_vp_d"
        width="107.78"
        height="108.97"
        x="516.23"
        y="-8.791"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-10" dy="10"></feOffset>
        <feGaussianBlur stdDeviation="12"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.0862745 0 0 0 0 0.211765 0 0 0 0.2 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <pattern
        id="pattern1_welcome_vp"
        width="1"
        height="1"
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#image1"></use>
      </pattern>
      <filter
        id="filter9_welcome_vp_b"
        width="290"
        height="50"
        x="250.84"
        y="-1"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImage"
          stdDeviation="5"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter10_welcome_vp_d"
        width="95.602"
        height="95.6"
        x="593"
        y="152"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-10" dy="10"></feOffset>
        <feGaussianBlur stdDeviation="12"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.0862745 0 0 0 0 0.211765 0 0 0 0.2 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter11_welcome_vp_d"
        width="32"
        height="32"
        x="635"
        y="177"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="3"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0707291 0 0 0 0 0.353294 0 0 0 0 0.808333 0 0 0 0.5 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter12_welcome_vp_d"
        width="139"
        height="139"
        x="-13"
        y="50"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-10" dy="10"></feOffset>
        <feGaussianBlur stdDeviation="12"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.0862745 0 0 0 0 0.211765 0 0 0 0.2 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter13_welcome_vp_b"
        width="111"
        height="111"
        x="11"
        y="54"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImage"
          stdDeviation="5"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur"
          result="shape"
        ></feBlend>
      </filter>
      <defs>
        <linearGradient
          id="paint0_welcome_vp_linear"
          x1="60.947"
          x2="231.327"
          y1="104.647"
          y2="476.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.063" stopColor="#1DE5E2" />
          <stop offset="0.943" stopColor="#B588F7" />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient
          id="paint1_welcome_vp_linear"
          x1="60.947"
          x2="231.327"
          y1="104.647"
          y2="476.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.063" stopColor="#1DE5E2"></stop>
          <stop offset="0.943" stopColor="#B588F7"></stop>
        </linearGradient>
      </defs>
      <defs>
        <linearGradient
          id="paint2_welcome_vp_linear"
          x1="61.154"
          x2="61.154"
          y1="437.698"
          y2="139.534"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181" stopColor="#fff" stopOpacity="0.22"></stop>
          <stop offset="0.896" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
      <defs>
        <linearGradient
          id="paint3_welcome_vp_linear"
          x1="61.154"
          x2="61.154"
          y1="497.935"
          y2="184.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#682EFB" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#A965DE"></stop>
        </linearGradient>
      </defs>
      <defs>
        <linearGradient
          id="paint4_welcome_vp_linear"
          x1="137.529"
          x2="194.106"
          y1="0.035"
          y2="108.778"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#C165DD"></stop>
          <stop offset="0.944" stopColor="#5C27FE"></stop>
        </linearGradient>
      </defs>
      <defs>
      <linearGradient
        id="paint5_welcome_vp_linear"
        x1="6.94"
        x2="55.887"
        y1="326.235"
        y2="444.928"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.057" stopColor="#FACD68"></stop>
        <stop offset="0.938" stopColor="#FC76B3"></stop>
      </linearGradient>
      </defs>
      <defs>
      <linearGradient
        id="paint6_welcome_vp_linear"
        x1="477.316"
        x2="511.178"
        y1="421.456"
        y2="476.82"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.063" stopColor="#F5E97C"></stop>
        <stop offset="0.938" stopColor="#EE7C3C"></stop>
      </linearGradient>
      </defs>
      <defs>
      <linearGradient
        id="paint7_welcome_vp_linear"
        x1="550.23"
        x2="592.986"
        y1="5.209"
        y2="75.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.052" stopColor="#2AFEB7"></stop>
        <stop offset="0.938" stopColor="#08C7BC"></stop>
      </linearGradient>
      </defs>
      <defs>
      <linearGradient
        id="paint8_welcome_vp_linear"
        x1="627"
        x2="661.064"
        y1="166"
        y2="221.856"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.052" stopColor="#55AFF1"></stop>
        <stop offset="0.953" stopColor="#1153FC"></stop>
      </linearGradient>
      </defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H692V474H0z"></path>
      </clipPath>
      <clipPath id="clip1">
        <path
          fill="#fff"
          d="M0 0H24V24H0z"
          transform="translate(489 433)"
        ></path>
      </clipPath>
      <clipPath id="clip2">
        <path
          fill="#fff"
          d="M0 0H10V10H0z"
          transform="translate(268.84 19)"
        ></path>
      </clipPath>
      <image
        id="image0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAmCAYAAACyAQkgAAAACXBIWXMAABcRAAAXEQHKJvM/AAAA"
      ></image>
      <image
        id="image1"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAA0CAYAAADxAdr3AAAACXBIWXMAABcRAAAXEQHKJvM/AAAA"
      ></image>
    </defs>
  </svg>
  </>
}
export const WelcomeImageStatic = () => {
  return <WelcomeImageSVG />
}

export default WelcomeImage
