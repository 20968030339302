import React from 'react'

const Message = () => {
  return <g>
    <path
      fill="url(#paint5_welcome_vp_linear)"
      d="M20.114 326.235H121.35c7.289 0 13.173 5.911 13.173 13.168v42.198c0 7.286-5.913 13.168-13.173 13.168h-10.686V412.5s-6.421-5.112-10.163-8.5-9-9.231-9-9.231H20.114c-7.29 0-13.174-5.911-13.174-13.168v-42.198a13.154 13.154 0 0113.174-13.168z"
    ></path>
    <g filter="url(#filter6_welcome_vp_d)">
      <path
        fill="#fff"
        d="M103.198 351.284c4.801 0 8.724 3.892 8.724 8.72 0 4.799-3.894 8.721-8.724 8.721-4.801 0-8.724-3.892-8.724-8.721.029-4.828 3.923-8.72 8.724-8.72zm-41.19 8.72c0 4.799 3.893 8.721 8.723 8.721 4.802 0 8.725-3.892 8.725-8.721 0-4.799-3.894-8.72-8.725-8.72-4.8 0-8.724 3.892-8.724 8.72zm-32.467 0c0 4.799 3.894 8.721 8.724 8.721 4.801 0 8.724-3.892 8.724-8.721 0-4.799-3.893-8.72-8.724-8.72a8.706 8.706 0 00-8.724 8.72z"
      ></path>
    </g>
  </g>
}

export default Message
