import styled, { keyframes } from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'

const fadeInAnimation = keyframes`
  0%    { opacity: 0; }
  50%   { opacity: 1; }
  100%  { opacity: 0; }
`

export const Root = styled.div`
  display: flex;
  justify-content: center;
`

export const Link = styled(ScrollLink)`
  display: inline-flex;
  width: 20px;
  height: 20px;
  text-decoration: none;
  animation: ${fadeInAnimation} 2s infinite;
  
  &:hover {
    cursor: pointer;
  }
  
  svg {
    width: 10px;
    height: 18px;
    transform: rotate(-90deg);
  }
`
