import styled from 'styled-components'
import SkewCardLibrary from '../SkewCard'
import * as SkewCardLibraryStyles from '../SkewCard/SkewCard.styled'

import { resetList } from '../../styled/mixins'

export const List = styled.ul`
  ${resetList};

  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    display: flex;
    flex-direction: column;
  }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Card = styled.div`
  display: flex;
  align-items: center;
`

export const SkewCard = styled(SkewCardLibrary)<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  ${SkewCardLibraryStyles.Content} {
    flex-grow: 1;
  }
  
  ${SkewCardLibraryStyles.Substrate} {
    background: ${props => props.backgroundColor};
  }
  
  @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    ${SkewCardLibraryStyles.Content} {
      padding: 25px 20px;
    }
  }
`

export const ContainerIcon = styled.div`
  margin-right: 18px;
  flex-shrink: 0;
  
  & > svg {
    width: 45px;
    height: 45px;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 60px;
      height: 60px;
    }
  }
`

export const Content = styled.div`
  flex-grow: 1;
`

export const Slogan = styled.h3`
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: ${props => props.theme.colors.text.contrast};
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 23px;
  }
`

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 13px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;
  }
`

export const Item = styled.li`
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    margin-bottom: 6vw;
    order: -1;
    
    &:nth-child(3n) {
      order: 1;
      margin-bottom: 0;
    }
  }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    &:nth-child(3n) {
      grid-row-start: span 2;
      grid-row-end: auto;
      
      ${SkewCardLibraryStyles.Content} {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      ${Card} {
        flex-direction: column;
        align-items: center;
      }
      
      ${Slogan} {
        text-align: center;
      }
      
      ${Title} {
        text-align: center;
      }
      
      ${ContainerIcon} {
        margin-right: 0;
        margin-bottom: 25px;
        
        & > svg {
          width: 110px;
          height: 110px;
        }
      }
    }
  }
`
