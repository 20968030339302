import styled from 'styled-components'

export const Card = styled.div`
  box-sizing: border-box;
  padding: 20px 5px;
  height: 100%;
  border-radius: 15px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding: 30px 20px;
    border-radius: 30px;
  }
`
