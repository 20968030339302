import styled, { css } from 'styled-components'
import { ButtonView, ButtonSize } from './index'

interface IButtonStylesProps {
  view: ButtonView
  size: ButtonSize
}

export const getViewButton = (view: ButtonView, size: ButtonSize) => {
  switch(view) {
    case ButtonView.Outline: return css`${viewOutlineCSS}${ButtonSize.Small === size ? viewOutlineSizeSmallCSS : ''}`;
    case ButtonView.Default: return css`${viewDefaultCSS}${ButtonSize.Small === size ? viewDefaulSizeSmallCSS : ''}`;
    case ButtonView.Secondary: return css`${viewSecondaryCSS}${ButtonSize.Small === size ? viewDefaulSizeSmallCSS : ''}`;

    default: return css`${viewDefaultCSS}`;
  }
}

export const getSizeButton = (size: ButtonSize) => {
  switch(size) {
    case ButtonSize.Default: return css`${sizeDefaultCSS}`;
    case ButtonSize.Small: return css`${sizeSmallCSS}`;

    default: return css`${sizeDefaultCSS}`;
  }
}

const viewDefaultCSS = css`
  border: none;
  background-color: ${props => props.theme.colors.secondary.default};
  box-shadow: 0 9px 17px rgba(64, 161, 255, 0.26);
  
  &:hover {
    background-color: ${props => props.theme.colors.secondary.light};
  }
  
  &:focus {
    outline: none;
  }
  
  &:active {
    color: ${props => props.theme.colors.secondary.light};
    background-color: ${props => props.theme.colors.secondary.dark};
  }
  
  &[disabled] {
    background-color: ${props => props.theme.colors.secondary.darkness};
  }
`

const viewOutlineCSS = css`
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colors.text.contrast};
  
  &:hover {
    color: ${props => props.theme.colors.secondary.default};
    border-color: ${props => props.theme.colors.secondary.default};
  }
  
  &:focus {
    outline: none;
  }
  
  &:active {
    color: ${props => props.theme.colors.secondary.dark};
    border-color: ${props => props.theme.colors.secondary.dark};
  }
  
  &[disabled] {
    color: ${props => props.theme.colors.secondary.darkness};
    background-color: ${props => props.theme.colors.secondary.darkness};
  }
`

const viewOutlineSizeSmallCSS = css`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding-top: 9px;
    padding-bottom: 9px;
  }
`

const viewDefaulSizeSmallCSS = css`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding-top: 11px;
    padding-bottom: 11px; 
  }
`

const viewSecondaryCSS = css`
  border: none;
  background-color: ${props => props.theme.colors.main.default};
  box-shadow: 0 9px 17px rgba(147, 56, 238, 0.26);
  
  &:hover {
    background-color: ${props => props.theme.colors.main.light};
  }
  
  &:focus {
    outline: none;
  }
  
  &:active {
    background-color: ${props => props.theme.colors.main.dark};
  }
`

const sizeDefaultCSS = css`
  font-size: 12px;
  line-height: 14px;

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 18px;
    line-height: 23px;
  }
`

const sizeSmallCSS = css`
  font-size: 14px;
  line-height: 18px;
`

export const Button = styled.button<IButtonStylesProps>`
  display: inline-flex;
  padding: 13px 20px;
  font-family: ${props => props.theme.fonts.main};
  font-weight: 500;
  color: ${props => props.theme.colors.text.contrast};
  text-align: center;
  border-radius: 32px;
  cursor: pointer;
  text-decoration: none;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding: 16px 30px;
  }

  ${props => getViewButton(props.view, props.size)};
  ${props => getSizeButton(props.size)};
`
