import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.main.darken};
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`

export const Header = styled.div`
  margin-bottom: 20px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 60px;  
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 5;
`
