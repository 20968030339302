import React from 'react'

import { getSocialIcon } from '../../../svg/social'
import * as Styles from './Social.styled'

import { ISocials } from '../types'

interface ISocialProps {
  socials: ISocials
}

const Social: React.FC<ISocialProps> = ({ socials }) => {
  return <Styles.List>
    {
      socials.map(social => {
        const Icon = getSocialIcon(social.systemName)

        return <Styles.Item key={ social.id }>
          <Styles.Link href={ social.href } target="_blank">
            <Icon />
          </Styles.Link>
        </Styles.Item>
      })
    }
  </Styles.List>
}

export default Social
