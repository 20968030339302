import React, {useEffect, useRef} from 'react'

import Form from '../../Form'

import { phoneValidation } from '../../Form/utils/validation'

import { useForm, Controller, NestedValue } from 'react-hook-form'

import * as Styles from './RecoveryPassword.styled'

import { TErrors, TRecaptcha } from '../../../../store/AppStore'

export interface IRecoveryPasswordSubmitData {
  phone: {
    country: {
      name: string
      dialCode: number
      countryCode: string
      format: string
    }
    number: number
  }
}

interface IRecoveryPasswordFormProps {
  cbSubmitAction: (data: IRecoveryPasswordSubmitData) => void
  cbCancelAction?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onlyCountries?: string[]
  phoneMasks?: {[key:string]:string}
  recaptcha?: TRecaptcha,
  backendErrors?: TErrors
  recaptcha2?: string | null
  isShowRecaptcha: boolean
  cbRecaptchaToken: (token: string) => void
}

interface IRecoveryPasswordFormInitialInputs {
  phone: NestedValue<{
    number: string
    country: string
  }>
}

enum RecoveryPasswordFormInputNames {
  phone = 'phone',
}

const RecoveryPasswordForm: React.FC<IRecoveryPasswordFormProps> = ({ cbCancelAction, cbSubmitAction, onlyCountries, phoneMasks, recaptcha, backendErrors, recaptcha2 = null, isShowRecaptcha = false, cbRecaptchaToken = ()=>{} }) => {
  const { control, handleSubmit, setError, formState: { errors } } = useForm<IRecoveryPasswordFormInitialInputs>({
    mode: 'onChange',
    defaultValues: {
      [RecoveryPasswordFormInputNames.phone]: {
        number: '',
        country: '',
      },
    }
  })

  useEffect(() => {
    if (backendErrors) {
      const errors = Object.entries(backendErrors) as [[keyof typeof RecoveryPasswordFormInputNames, string]]
      errors.forEach(([name, value]) => {
        if (value) {
          setError(name, {
            type: "backend",
            message: value,
          });
        }
      })
    }
  }, [backendErrors])

  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (isShowRecaptcha && recaptcha2) {
      window.grecaptcha.render(recaptchaRef.current, {
        sitekey: recaptcha2,
        callback: (response: any) => {
          cbRecaptchaToken(response);
        },
      });
    }
  }, [isShowRecaptcha, recaptcha2]);

  return <Form onSubmit={ handleSubmit(cbSubmitAction)  }>
    <Form.Field isError={ Boolean(errors[RecoveryPasswordFormInputNames.phone]?.message) }>
      <Controller
        name={ RecoveryPasswordFormInputNames.phone }
        control={ control }
        rules={{
          validate: {
            country: phoneValidation
          }
        }}
        render={({ field }) => <Form.PhoneInput { ...field } 
        onlyCountries={ onlyCountries } 
        masks={phoneMasks}
        placeholder="Ваш телефон" /> }
      />
      <Form.Label>Ваш телефон</Form.Label>
      { errors && errors[RecoveryPasswordFormInputNames.phone] && <Form.Error>{ errors[RecoveryPasswordFormInputNames.phone]?.message }</Form.Error> }
    </Form.Field>

    { recaptcha?.key3 && <Form.Recaptcha sitekey={ recaptcha.key3 } /> }
    <Form.Field>
      <div ref={recaptchaRef}></div>
    </Form.Field>

    <Form.Field>
      <Styles.Buttons>
        <Form.SubmitButton>Восстановить</Form.SubmitButton>
        <Form.CancelButton onClick={ cbCancelAction }>Отмена</Form.CancelButton>
      </Styles.Buttons>
    </Form.Field>
  </Form>
}

export default RecoveryPasswordForm
