import React from 'react'

import * as Styles from './ReferralProgram.styled'

import Section from '../../common/Section'
import Slogan from '../SloganSection'
import ListWithEllipse from '../../ListWithEllipse'

import { ReferralList } from '../../../data/vp'
import SectionParallax, { ParallaxSide } from '../SectionParallax'
import { useDesktop } from '../../../styled/useMedia'

import storeApp from '../../../store/AppStore'
import { EAuthModalSteps, EModalNames } from '../../Modals/Modals.type'

const ReferralProgram = () => {
  const isDesktop = useDesktop()

  const bgImageDesktopStyleObject = {
    left: '65%',
    width: '75%',
    height: '100%',
  }

  const onClickRegisterButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Enter)
    storeApp.setAuthModalData(EAuthModalSteps.Register)
  }

  const referralList = ReferralList.map(item => ({
      ...item,
      title: addedLink(item.title, /регистрируешься/gi, onClickRegisterButton),
      content: <Styles.Number>{ item.id }</Styles.Number>
    }
  ))

  return <Styles.Wrapper>
    <SectionParallax side={ ParallaxSide.Right } bgImageStyle={ isDesktop ? bgImageDesktopStyleObject : {} }>
      <Section>
        <Styles.Header>
            <Slogan>Реферальная программа</Slogan>
        </Styles.Header>

        <Styles.Content>
          <Styles.YouAction>
            <Styles.SloganContent>
              Даже если у вас нет групп
            </Styles.SloganContent>
            <Styles.Title>
              BidFox может предложить заработок даже тем,
              у кого нет своих личных сообществ, но есть друзья или друзья друзей с пабликами Если кто-нибудь зарегистрируется по твоей реферальной ссылке и подключит к системе паблики с подключенной монетизацией, то BidFox будет начислять тебе
              за это вознаграждение. Такое вознаграждение выплачивается за счет средств сервиса, а не отнимается от суммы вознаграждения другого администратора.
            </Styles.Title>
            <Styles.Action>
              <Styles.Button onClick={onClickRegisterButton}>Стать партнером</Styles.Button>
            </Styles.Action>
          </Styles.YouAction>
          <Styles.BidFoxAction>
            <Styles.SloganContent>
              Как это работает?
            </Styles.SloganContent>
            <Styles.SkewCard>
              <ListWithEllipse
                items={ referralList }
                isFlowColumn
              />
            </Styles.SkewCard>
          </Styles.BidFoxAction>
        </Styles.Content>
      </Section>
    </SectionParallax>
  </Styles.Wrapper>
}

const addedLink = (title: string, RegExp: RegExp, cbOnClick: (e: React.MouseEvent<HTMLButtonElement>) => void) => {
  // @ts-ignore
  const res = []

  // @ts-ignore
  title.split(' ').forEach(item => {
    res.push(item.match(RegExp) ? <Styles.Link onClick={ cbOnClick }>{ item }</Styles.Link> : ` ${item} `)
  })

  // @ts-ignore
  return res
}

export default ReferralProgram
