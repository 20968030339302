import {
  ICompetenceSection,
  IContactsFormSection,
  IInvertorySection,
  ISliderSection,
  ITOpportunitySection,
  IWelcomeSection,
} from '../../../types/sections';
import WelcomeImage from '../../../components/_RK/WelcomeImage';

import { ReactComponent as BrandSVG } from '../../../svg/rk/opportunity/brand.svg';
import { ReactComponent as ProductSVG } from '../../../svg/rk/opportunity/product.svg';
import { ReactComponent as ContentSVG } from '../../../svg/rk/opportunity/content.svg';
import { ReactComponent as UsersSVG } from '../../../svg/rk/opportunity/users.svg';
import { ReactComponent as GoalSVG } from '../../../svg/rk/opportunity/goal.svg';

import { ReactComponent as SettingsSVG } from '../../../svg/common/settings.svg';
import { ReactComponent as ProductionSVG } from '../../../svg/common/production.svg';
import { ReactComponent as AiSVG } from '../../../svg/common/rk-optimization.svg';
import { ReactComponent as CheckboxSVG } from '../../../svg/common/checkbox.svg';

import { isFirefox } from 'react-device-detect';

import { SectionNamesRK } from './names';

export const welcomeContent: IWelcomeSection = {
  slogan: 'Сервис массовых размещений в социальных сетях',
  title:
    'Помогаем агентствам и брендам выстраивать маркетинговую коммуникацию в социальных сетях.',
  buttonMain: {
    title: 'Администраторам',
    href: 'https://vp.bidfox.ru',
    type: 'publicPageOwner',
    tooltip:
      'Если у тебя есть свои сообщества и ты хочешь начать зарабатывать на них, тогда регистрируйся как «Владелец групп». Зарабатывать можно и без групп, приглашая в сервис других администраторов сообществ с помощью своей реферальной ссылки.',
  },
  buttonSecondary: {
    title: 'Рекламодателям',
    href: 'https://bidfox.ru',
    type: 'advertiser',
    tooltip:
      'Если у тебя цель запустить рекламную кампанию, познакомить аудиторию с новым продуктом или услугой, то тебе нужно регистрироваться как «Рекламодатель». В сервисе будет доступен функционал для автоматического размещения публикаций.',
  },
  Image: WelcomeImage,
  nameDownSection: SectionNamesRK.Capabilities,
  isReverseActions: true,
};

export const opportunityContent: ITOpportunitySection = {
  slogan: 'Решайте свои задачи автоматизированно',
  opportunityList: [
    {
      id: 1,
      Icon: BrandSVG,
      slogan: 'Повышайте узнаваемость',
      title:
        'Стройте эмоциональную и\u00A0рациональную связь с\u00A0брендом, следите за\u00A0эффективностью кампаний онлайн с\u00A0помощью дашбордов',
    },
    {
      id: 2,
      Icon: ProductSVG,
      slogan: 'Стройте знания о\u00A0продукте',
      title:
        'Расскажите аудитории о\u00A0продукте и\u00A0его\u00A0УТП, оцените ее\u00A0реакцию с\u00A0помощью автоматической выгрузки пользовательских комментариев',
    },
    {
      id: 3,
      Icon: ContentSVG,
      slogan: 'Делайте дистрибуцию контента',
      title:
        'Найдите вашу аудиторию в\u00A0социальных сетях, изучите ее\u00A0предпочтения и\u00A0обеспечьте эффективную дистрибуцию по\u00A0необходимым тематическим вертикалям',
    },
    {
      id: 4,
      Icon: UsersSVG,
      slogan: 'Привлекайте пользователей',
      title:
        'Привлекайте подписчиков, участников конкурса, аудиторию спецпроекта или\u00A0мобильного приложения',
    },
    {
      id: 5,
      Icon: GoalSVG,
      slogan: 'Повышайте продажи',
      title:
        'Увеличивайте количество заявок и\u00A0продаж за\u00A0счет быстрой и\u00A0доступной оптимизации рекламных кампаний',
    },
  ],
};

export const competenceContent: ICompetenceSection = {
  slogan: 'Ключевые компетенции команды и\u00A0сервиса',
  competenceList: [
    {
      id: 1,
      slogan: 'Полная автоматизация',
      competences: [
        {
          id: 1,
          content: SettingsSVG,
          title:
            'За\u00A0счет автоматизации одна кампания может содержать десятки тысяч рекламных размещений',
        },
        {
          id: 2,
          content: SettingsSVG,
          title:
            'Сервис собирает и\u00A0транслирует статистику онлайн в\u00A0режиме реального времени. Все\u00A0размещения фиксируются скриншотами',
        },
        {
          id: 3,
          content: SettingsSVG,
          title:
            'Система автоматически проводит инвентаризацию подключенных площадок, это важно для\u00A0Brand Safety',
        },
        {
          id: 4,
          content: SettingsSVG,
          title: 'Антифрод-решения контролируют качество инвентаря',
        },
        {
          id: 5,
          content: SettingsSVG,
          title:
            'Любой «ручной труд» сводится к\u00A0автоматизации и\u00A0оптимизации',
        },
      ],
    },
    {
      id: 2,
      slogan: 'Своя разработка',
      competences: [
        {
          id: 6,
          content: ProductionSVG,
          title:
            'При сервисе работает своя команда разработки, решающая любые технические задачи',
        },
        {
          id: 7,
          content: ProductionSVG,
          title:
            'Это позволяет нам реализовывать  индивидуальные дополнительные опции для\u00A0партнеров (дашборды по\u00A0White label, настройку расширенной статистики и\u00A0т.д.)',
        },
        {
          id: 8,
          content: ProductionSVG,
          title:
            'Разработка задействует искусственный интеллект, обучая систему различным алгоритмам и\u00A0предиктивным технологиям',
        },
        {
          id: 9,
          content: ProductionSVG,
          title:
            'Bidfox оперативно реагирует на\u00A0все\u00A0изменения в\u00A0социальных сетях, добавляя в\u00A0сервис функции, доступные для\u00A0тестирования новых возможностей площадок',
        },
      ],
    },
    {
      id: 3,
      slogan: 'Оптимизация кампаний',
      competences: [
        {
          id: 10,
          content: AiSVG,
          title:
            'У\u00A0сервиса есть\u00A0динамическое медиапланирование: поручайте системе распределение рекламных материалов, избавляясь от\u00A0ручного труда',
        },
        {
          id: 11,
          content: AiSVG,
          title:
            'Bidfox анализирует сообщества и\u00A0определяет их\u00A0рейтинг, чтобы\u00A0вы\u00A0смогли составить медиаплан из\u00A0наиболее эффективных сообществ',
        },
        {
          id: 12,
          content: AiSVG,
          title:
            'Bidfox парсит список целевой аудитории (ID), которая контактировала с\u00A0рекламой, для\u00A0дальнейшего взаимодействия с\u00A0ней в\u00A0других каналах коммуникации',
        },
        {
          id: 13,
          content: AiSVG,
          title:
            'Bidfox собирает расширенные данные по\u00A0рекламным размещениям, что\u00A0позволяет оптимизировать креативы в\u00A0процессе кампании',
        },
      ],
    },
  ],
};

export const inventoryContent: IInvertorySection = {
  slogan: 'Доступный инвентарь',
  communitySlogan: 'Сообщества и аудитория',
  segmentSlogan: 'Сегменты аудитории',
  socialSlogan: 'Мы представлены в соц. сетях',
  indicatorsList: [
    {
      id: 1,
      number: '>12',
      dimension: 'тыс.',
      title: 'подключенных сообществ',
    },
    {
      id: 2,
      number: '1.6+',
      dimension: 'млрд',
      title: 'подписчиков',
    },
    {
      id: 3,
      number: '6',
      dimension: 'млрд',
      title: 'показов в месяц',
    },
  ],
  segmentsList: [
    {
      id: 1,
      title: 'Домохозяйки',
      content: CheckboxSVG,
    },
    {
      id: 2,
      title: 'Бизнесмены',
      content: CheckboxSVG,
    },
    {
      id: 3,
      title: 'Автолюбители',
      content: CheckboxSVG,
    },
    {
      id: 4,
      title: 'Родители',
      content: CheckboxSVG,
    },
    {
      id: 5,
      title: 'Офисные сотрудники',
      content: CheckboxSVG,
    },
    {
      id: 6,
      title: 'Путешественники',
      content: CheckboxSVG,
    },
    {
      id: 7,
      title: 'Студенты',
      content: CheckboxSVG,
    },
    {
      id: 8,
      title: 'Пенсионеры',
      content: CheckboxSVG,
    },
    {
      id: 9,
      title: 'Охотники и рыболовы',
      content: CheckboxSVG,
    },
    {
      id: 10,
      title: 'Спортсмены',
      content: CheckboxSVG,
    },
    {
      id: 11,
      title: 'Волонтеры/ активисты',
      content: CheckboxSVG,
    },
    {
      id: 12,
      title: 'Рукодельники',
      content: CheckboxSVG,
    },
    {
      id: 13,
      title: 'Фрилансеры',
      content: CheckboxSVG,
    },
    {
      id: 14,
      title: 'Зоозащитники',
      content: CheckboxSVG,
    },
    {
      id: 15,
      title: 'Дачники и другие',
      content: CheckboxSVG,
    },
  ],
  socialList: [
    {
      id: 1,
      name: 'Вконтакте',
      systemName: 'vk',
      href: '#',
    },
    {
      id: 2,
      name: 'Одноклассники',
      systemName: 'od',
      href: '#',
    },
    {
      id: 3,
      name: 'Telegram',
      systemName: 'tg',
      href: '#',
    },
    {
      id: 4,
      name: 'Facebook',
      systemName: 'fb',
      href: '#',
      tooltip:
        'Деятельность Facebook в России признана экстремистской и запрещена.',
    },
    {
      id: 5,
      name: 'Instagram',
      systemName: 'inst',
      href: '#',
      tooltip:
        'Деятельность Instagram в России признана экстремистской и запрещена.',
    },
  ],
};

export const benefitContent: ISliderSection = {
  slogan: 'Преимущества сервиса',
  isHyphenated: false,
  sliderList: [
    {
      id: 1,
      slogan: 'Оптимизация кампаний',
      subTitle:
        'Bidfox имеет собственный сократитель, что\u00A0позволяет видеть статистику по\u00A0кликам независимо от\u00A0того, промечали ли вы ссылку UTM-метками. Статистика будет доступна вам в\u00A0отчете по\u00A0размещениям.',
      systemName: 'optimization',
    },
    {
      id: 2,
      slogan: 'Детальная отчетность',
      subTitle:
        'Мы предоставляем расширенную отчетность: ссылки на\u00A0публикации, пользовательские комментарии, автоматизированные скриншоты постов и\u00A0многое другое.',
      systemName: 'kpi',
    },
    {
      id: 3,
      slogan: 'Дашборды',
      subTitle:
        'Сервис умеет настраивать гибкие дашборды, которые собирают и\u00A0транслируют статистику по\u00A0основным показателям кампании в\u00A0режиме реального времени.',
      systemName: 'statics',
    },
    {
      id: 4,
      slogan: 'Оценка эффективности',
      subTitle:
        'Bidfox собирает расширенные данные, позволяющие оценивать эффективность рекламы с\u00A0помощью таких механик, как\u00A0Brand-Lift и\u00A0замер «прогрева» аудитории.',
      systemName: 'star',
    },
    {
      id: 5,
      slogan: 'Особые условия для агентств',
      subTitle:
        'Кастомные решения для\u00A0агентств, различные интеграции, предоставление функционала по\u00A0White Label и\u00A0более выгодные финансовые условия работы.',
      systemName: 'wallet',
    },
    {
      id: 6,
      slogan: 'Удобная группировка посевов',
      subTitle:
        'Группировка посевов в\u00A0рамках одной кампании позволяет одновременную работу с\u00A0разными аудиториями, социальными сетями и\u00A0задачами клиента. Следить за\u00A0статистикой можно как по\u00A0отдельным посевам, так\u00A0и\u00A0по\u00A0всей РК.',
      systemName: 'flex',
    },
    {
      id: 7,
      slogan: 'Быстрый старт',
      subTitle:
        'Интуитивно понятный интерфейс и\u00A0информативность каждого шага позволяют создать рекламную кампанию за\u00A015\u00A0минут.',
      systemName: 'rocket',
    },
    {
      id: 8,
      slogan: 'Все соцсети в\u00A0одной РК',
      subTitle:
        'Одна рекламная кампания может содержать посевы из\u00A0разных социальных сетей, это\u00A0позволяет выстроить «бесшовный» процесс работы с\u00A0несколькими площадками одновременно.',
      systemName: 'mpo',
    },
    {
      id: 9,
      slogan: 'Ручные или массовые посевы',
      subTitle:
        'Сервис дает вариативность: размещайте несколько точечных постов вручную или\u00A0тысячи публикаций с\u00A0помощью динамического медиапланирования, у\u00A0сервиса нет\u00A0ограничений.',
      systemName: 'calendar',
    },
    {
      id: 10,
      slogan: 'Тематическое таргетирование',
      subTitle:
        'Благодаря качественной категоризации сообществ вы\u00A0можете таргетироваться на\u00A0пользователей по\u00A0их\u00A0модели поведения (домохозяйка, студент, человек, интересующийся здоровым образом жизни и\u00A0т.д.). Привлекайте внимание нужной вам\u00A0аудитории.',
      systemName: 'target',
    },
    {
      id: 11,
      slogan: 'Нативный формат размещений',
      subTitle:
        'Наши проверенные партнеры могут получить персональный доступ к\u00A0размещению нативной рекламы, которая доносит целевое послание ненавязчиво, минуя феномен «баннерной слепоты».',
      systemName: 'nb',
    },
    {
      id: 12,
      slogan: 'Оплата по CPM',
      subTitle:
        'Рекламодатель платит за\u00A0фактически набранный охват\u00A0(CPM). Это\u00A0позволяет включать посевы в\u00A0привычные медиапланы. Также оплата по\u00A0CPM снимает риск с\u00A0рекламодателя: он\u00A0платит за\u00A0результат, а\u00A0не\u00A0за\u00A0размещение.',
      systemName: 'cpm',
    },
    {
      id: 13,
      slogan: 'Большой охват',
      subTitle:
        'К\u00A0сервису подключено более\u00A012\u00A0000 тематических сообществ, что\u00A0позволяет сгенерировать больше 4\u00A0млрд показов в\u00A0месяц. Из\u00A0такого объема инвентаря можно выбрать любую целевую аудиторию.',
      systemName: 'youtube',
    },
  ],
  getBackgroundCardColor: (id: number, isActive: boolean): string => {
    const cofficient = 0.97; // 0.5
    const isSupportBackdropFilter = window.CSS.supports(
      '(backdrop-filter: blur(21px)) or (-webkit-backdrop-filter: blur(21px))'
    );

    switch (id) {
      case 1:
        return `linear-gradient(148.62deg, rgba(85, 175, 241, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.5) : '1'
        }) 5.21%, rgba(17, 83, 252, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 95.31%)`;
      case 2:
        return `linear-gradient(148.62deg, rgba(29, 229, 226, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.6) : '1'
        }) 6.25%, rgba(181, 136, 247, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 94.27%)`; // linear-gradient(148.62deg, rgba(0, 173, 228, ${ isActive ? '1' : cofficient / 0.6 }) 6.17%, rgba(4, 245, 237, ${ isActive ? '1' : cofficient / id }) 94.27%)
      case 3:
        return `linear-gradient(148.62deg, rgba(0, 173, 228, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.65) : '1'
        }) 6.17%, rgba(4, 245, 237, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 94.27%)`;
      case 4:
        return `linear-gradient(148.62deg, rgba(42, 254, 183, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.7) : '1'
        }) 5.21%, rgba(8, 199, 188, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 93.75%)`;
      case 5:
        return `linear-gradient(148.62deg, rgba(250, 205, 104, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.8) : '1'
        }) 5.73%, rgba(252, 118, 179, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 93.75%)`; // linear-gradient(148.62deg, rgba(29, 229, 226, ${ isActive ? '1' : cofficient / 0.8 }) 6.25%, rgba(181, 136, 247, ${ isActive ? '1' : cofficient / id }) 94.27%)
      case 6:
        return `linear-gradient(148.62deg, rgba(85, 175, 241, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 5.21%, rgba(17, 83, 252, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 95.31%)`; // зеленый - linear-gradient(148.62deg, rgba(42, 254, 183, ${ isActive ? '1' : cofficient }) 5.21%, rgba(8, 199, 188, ${ isActive ? '1' : cofficient }) 93.75%)
      case 7:
        return `linear-gradient(148.62deg, rgba(250, 205, 104, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 5.73%, rgba(252, 118, 179, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 93.75%)`;
      case 8:
        return `linear-gradient(148.62deg, rgba(85, 175, 241, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 5.21%, rgba(17, 83, 252, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 95.31%)`;
      case 9:
        return `linear-gradient(148.62deg, rgba(29, 229, 226, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.8) : '1'
        }) 6.25%, rgba(181, 136, 247, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 94.27%)`;
      case 10:
        return `linear-gradient(148.62deg, rgba(0, 173, 228, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.75) : '1'
        }) 6.17%, rgba(4, 245, 237, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 94.27%)`;
      case 11:
        return `linear-gradient(148.62deg, rgba(42, 254, 183, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.7) : '1'
        }) 5.21%, rgba(8, 199, 188, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 93.75%)`;
      case 12:
        return `linear-gradient(148.62deg, rgba(42, 254, 183, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.65) : '1'
        }) 5.21%, rgba(8, 199, 188, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 93.75%)`; // рыжий замена - linear-gradient(148.62deg, rgba(250, 205, 104, ${ isActive ? '1' : cofficient / 0.65 }) 5.73%, rgba(252, 118, 179, ${ isActive ? '1' : cofficient / id }) 93.75%)
      case 13:
        return `linear-gradient(148.62deg, rgba(0, 173, 228, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.6) : '1'
        }) 6.17%, rgba(4, 245, 237, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 94.27%)`; // синий - linear-gradient(148.62deg, rgba(85, 175, 241, ${ isActive ? '1' : cofficient / 0.6 }) 5.21%, rgba(17, 83, 252, ${ isActive ? '1' : cofficient / id }) 95.31%)
      default:
        return `linear-gradient(148.62deg, rgba(42, 254, 183, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient / 0.55) : '1'
        }) 5.21%, rgba(8, 199, 188, ${
          isSupportBackdropFilter ? (isActive ? '1' : cofficient) : '1'
        }) 93.75%)`;
    }
  },
};

export const contactsContent: IContactsFormSection = {
  slogan: 'Контакты',
  subSlogan: 'Автоматизируйте рекламу вместе с\u00A0Bidfox!',
  contactsList: [
    {
      id: 1,
      title: '8 800 777 53 14',
      subTitle: 'Звонок бесплатный',
      systemName: 'phone',
      href: 'tel:88007775314',
    },
    {
      id: 2,
      title: 'Написать менеджеру',
      subTitle: 'hello@bidfox.ru',
      systemName: 'mail',
      href: 'mailto:hello@bidfox.ru',
    },
  ],
};
