import React from 'react'

const Message = () => {
  return <g>
    <path
      d="M102.924 335.937H203.791C211.054 335.937 216.917 341.83 216.917 349.064V391.128C216.917 398.392 211.025 404.255 203.791 404.255H193.144V421.93C193.144 421.93 186.746 416.835 183.018 413.457C179.29 410.08 174.051 404.255 174.051 404.255H102.924C95.6604 404.255 89.7974 398.363 89.7974 391.128V349.064C89.7974 341.801 95.6604 335.937 102.924 335.937Z"
      fill="url(#paint1_welcome_rk_linear)"
    />
    <g filter="url(#filter2_welcome_rk_d)">
      <path
        d="M185.706 360.907C190.49 360.907 194.399 364.787 194.399 369.6C194.399 374.384 190.519 378.293 185.706 378.293C180.923 378.293 177.014 374.413 177.014 369.6C177.043 364.787 180.923 360.907 185.706 360.907ZM144.665 369.6C144.665 374.384 148.545 378.293 153.358 378.293C158.141 378.293 162.05 374.413 162.05 369.6C162.05 364.816 158.171 360.907 153.358 360.907C148.574 360.907 144.665 364.787 144.665 369.6ZM112.316 369.6C112.316 374.384 116.196 378.293 121.009 378.293C125.793 378.293 129.701 374.413 129.701 369.6C129.701 364.816 125.822 360.907 121.009 360.907C116.196 360.907 112.316 364.787 112.316 369.6Z"
        fill="white"
      />
    </g>
  </g>
}

export default Message
