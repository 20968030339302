import styled, { keyframes, css } from 'styled-components'

import youtubeImageJPG from '../../../images/vp/youtube-image.jpg'

export const Root = styled.div`
  position: relative;
  height: 51.3vw;
  border: 1px solid rgba(255, 255, 255, 0.4);
  overflow: hidden;
  border-radius: 6.4vw;
  
  @media ${props => props.theme.device.tablet} {
    height: 56.3vw;
  }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    height: 375px;
    border-radius: 40px;
  }
  
  & iframe {
    width: 100%;
    height: 51.3vw;
    border: none;
    border-radius: 15px;
    
    @media ${props => props.theme.device.tablet} {
      height: 56.3vw;
    }
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      height: 375px;
      border-radius: 45px;
    }
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
    
  to {
    transform: rotate(360deg);
  }
`

type PlayButtonProps = {
  isLoading?: boolean
}

const PlayButtonLoadingCSS = css`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.main.default};
    border-bottom: none;
    animation: ${rotate} 2s infinite;
    background-image: none;

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 30px;
      height: 30px;
      margin-top: -15px;
      margin-left: -15px;
    }
  }
`

export const PlayButton = styled.button.attrs({ type: 'button' })<PlayButtonProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-image: url(${youtubeImageJPG});
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    border-radius: 40px;
  }
  
  ${props => props.isLoading && PlayButtonLoadingCSS }
`

