import React from 'react'

import * as Styles from './Slider.styled'

import SwiperCore, { Pagination, Navigation, EffectCoverflow } from 'swiper'
import SwiperClass from 'swiper/types/swiper-class'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper-bundle.css'
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'

SwiperCore.use([Pagination, Navigation, EffectCoverflow])

export type TSliderParams = {
  slidesPerView: number | 'auto'
  spaceBetween: number
  navigation?: boolean
  pagination?: {
    clickable: boolean
  }
  loop?: boolean
  effect?: 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip'
  centeredSlides?: boolean
  coverflowEffect?: {
    rotate: number
    stretch: number
    depth: number
    modifier: number
    slideShadows: boolean
  }
  observer?: boolean,
  observeParents?: boolean,
  observeSlideChildren?: boolean
  updateOnWindowResize?: boolean
  onSwiper?: (swiper: SwiperClass) => void
  onSlideChange?: (swiper: SwiperClass) => void
}

type TSliderProps = {
  sliderParams: TSliderParams
  slides: React.ReactElement<any>[]
  containerEndContent?: any
}

type TSlideProps = {
  isActive?: boolean
}

const Slider: React.FC<TSliderProps> = ({ sliderParams, slides, containerEndContent = ''}) => {
  return <Styles.Root isPagination={ Boolean(sliderParams.pagination) }>
    <Swiper
      { ...sliderParams }
    >
      { slides.map((slide, index) => (
        <SwiperSlide key={ index }>
          {
            ({ isActive }: TSlideProps) => React.cloneElement(slide, { isActive }, null)
          }
        </SwiperSlide>
      )) }

      {
        containerEndContent &&
        <Styles.ContainerEnd>{ containerEndContent }</Styles.ContainerEnd>
      }
    </Swiper>
  </Styles.Root>
}

export default Slider
