import styled from 'styled-components'
import { columnMixin, resetList } from '../../../styled/mixins'

import SkewCardLibrary from '../../SkewCard'
import ListWithEllipseLibrary from '../../ListWithEllipse'

import * as SkewCardLibraryStyles from '../../SkewCard/SkewCard.styled'

export const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.main.darken};
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 350px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
  
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 250px;
    background: linear-gradient(0deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 1;
  }
`

export const Header = styled.div`
  position: relative;
  z-index: 5;
  margin-bottom: 15px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;  
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 5;
`

export const List = styled.ul`
  ${resetList};
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex;
    flex-wrap: wrap;
  }
`

export const Item = styled.li`
   @media ${props => props.theme.maxDevice['desktop']},
   @media ${props => props.theme.maxDevice['desktop-medium']} {
    margin-bottom: 20px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex;
    flex-direction: column;
    ${columnMixin(3, 30)};
  }
`

export const SloganItem = styled.h3`
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  color: ${props => props.theme.colors.text.contrast};
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    flex-shrink: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 25px;
  }
`

export const SkewCard = styled(SkewCardLibrary)<{ backgroundColor: string }>`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  ${SkewCardLibraryStyles.Substrate} {
    background: ${props => props.backgroundColor};
  }
  
  ${SkewCardLibraryStyles.Content} {
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1; 
    }
  }
`

export const ListWithEllipse = styled(ListWithEllipseLibrary)`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 20px;
    height: 20px;
    fill: none;
    stroke: ${props => props.theme.colors.text.contrast};
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 26px;
      height: 26px;
    }
  }
`
