import styled from 'styled-components'

import ContactImageJPEG from '../../../images/vp/contact-image-mobile.svg'
import ContactImageMobileX3JPEG from '../../../images/vp/mobile-map-x3.svg'
import ContactImageMobileX4JPEG from '../../../images/vp/mobile-map-x4.svg'

export const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.main.darken};
  background-image: url(${ContactImageMobileX3JPEG});
  background-repeat: no-repeat;
  background-position: 0 -25vw;
  background-size: cover;
  
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    position: relative;
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      background: linear-gradient(0deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
      z-index: 1;
    }
  }
  
  @media ${props => props.theme.device.tablet} {
    background-image: url(${ContactImageMobileX4JPEG});
  }
  
  @media ${props => props.theme.device['desktop']} {
    min-height: 100vh;
    background-image: url(${ContactImageJPEG});
    background-position: top left;
    background-size: cover;
  }

  @media ${props => props.theme.device['tablet-landscape']} {
    min-height: 100vh;
    background-image: url(${ContactImageJPEG});
    background-position: top center;
    background-size: cover;
  }
`

export const Header = styled.div`
  position: relative;
  z-index: 5;
  margin-bottom: 90px;
`

export const Content = styled.div`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex; 
  }
`

export const Contact = styled.div` 
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 370px;
    flex-shrink: 0;
  }
`
