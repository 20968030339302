import React from 'react'

import * as Styles from './Logo.styled'

import { ILogo } from '../types'

export const Logo: React.FC<ILogo> = ({ src, alt, targetScroll }) => {
  return <Styles.Logo
    to={ targetScroll }
    spy
    smooth
  >
    <Styles.Image src={ src } alt={ alt } />
  </Styles.Logo>
}

export default Logo
