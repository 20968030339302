import React from 'react'

import * as Styles from './Inventory.styled'

import Section from '../../common/Section'
import Slogan from '../SloganSection'
import SectionParallax, { ParallaxSide } from '../SectionParallax'

import Indicators from '../../Indicators'
import { IInvertorySection } from '../../../types/sections'
import SocialStairs from "../../common/SocialStairs";
import { useDesktop } from '../../../styled/useMedia'

const Inventory: React.FC<IInvertorySection> = ({ slogan, communitySlogan, segmentSlogan, socialSlogan, indicatorsList, segmentsList, socialList }) => {
  const segments = segmentsList.map(item => {
    const Icon = item.content

    return {
      ...item,
      content: <Styles.Icon key={item.id}> <Icon /> </Styles.Icon>
    }
  })

  const isDesktop = useDesktop()

  const bgImageDesktopStyleObject = {
    left: '35%',
    width:'65%',
    top: '-15%',
    height: '100%',
  }

  return <Styles.Wrapper>
    <SectionParallax side={ ParallaxSide.Left } bgImageStyle={ isDesktop ? bgImageDesktopStyleObject : {} }>
      <Section>
        <Styles.Header>
          <Slogan>{ slogan }</Slogan>
        </Styles.Header>

        <Styles.Content>

          <Styles.LeftSide>
            <Styles.Indicators>
              <Styles.SubSlogan>{ communitySlogan }</Styles.SubSlogan>
              <Indicators indicators={ indicatorsList } />
            </Styles.Indicators>
            <Styles.Social>
              <Styles.SubSlogan>{ socialSlogan }</Styles.SubSlogan>
              <SocialStairs socials={ socialList } />
            </Styles.Social>
          </Styles.LeftSide>

          <Styles.RightSide>
            <Styles.Segment>
              <Styles.SubSlogan>{ segmentSlogan }</Styles.SubSlogan>
              <Styles.SkewCard>
                <Styles.ListWithEllipse items={ segments } />
              </Styles.SkewCard>
            </Styles.Segment>
          </Styles.RightSide>
        </Styles.Content>
      </Section>
    </SectionParallax>
  </Styles.Wrapper>
}

export default Inventory
