import React from 'react'

import Modal from '../../common/Modal'
import EnterForm from '../../_VP/Forms/Enter'
import RegisterForm from '../../_VP/Forms/Register'
import RegisterConfirmation from '../../_VP/Forms/RegisterConfirmation'
import RecoveryPasswordForm from '../../_VP/Forms/RecoveryPassword'
import RecoveryPasswordConfirmationForm from '../../_VP/Forms/RecoveryPasswordConfirmation'

import * as Styles from './Enter.styled'

import { EAuthModalSteps, EModalNames } from '../Modals.type'

import storeApp from '../../../store/AppStore'
import { observer } from 'mobx-react-lite'

import { useSprings, animated } from 'react-spring'

const modalForms = [
  {
    id: 1,
    name: EAuthModalSteps.Register,
    component: <RegisterForm />,
  },
  {
    id: 2,
    name: EAuthModalSteps.Enter,
    component: <EnterForm />,
  },
  {
    id: 3,
    name: EAuthModalSteps.RegisterConfirmation,
    component: <RegisterConfirmation /> ,
  },
  {
    id: 4,
    name: EAuthModalSteps.RecoveryPassword,
    component: <RecoveryPasswordForm />,
  },
  {
    id: 5,
    name: EAuthModalSteps.RecoveryPasswordConfirmation,
    component: <RecoveryPasswordConfirmationForm />,
  }
]

const EnterModal = () => {
  const onClickCloseModal = () => {
    storeApp.modalClose(EModalNames.Enter)
    storeApp.removeLoginModalData()
  }

  const animationStyles = useSprings(
    modalForms.length,
    modalForms.map(form => ({
      opacity: storeApp.authModalData.stepName === form.name ? 1 : 0,
    }))
  )

  return <Modal
      slogan={ getModalSlogan(storeApp.authModalData.stepName) }
      isOpen={ storeApp.modalOpenNames.includes(EModalNames.Enter) }
      setIsClose={ onClickCloseModal }
  >
    <Styles.Container>
      {
        animationStyles.map((style, index) =>
          <animated.div style={ style } key={index}>
            {
              storeApp.authModalData.stepName === modalForms[index].name ? modalForms[index].component : null
            }
          </animated.div>
        )
      }
    </Styles.Container>
  </Modal>
}

const getModalSlogan = (stepName: EAuthModalSteps | '') => {
  if (stepName === EAuthModalSteps.Enter) return "Вход в личный кабинет";
  if (stepName === EAuthModalSteps.Register || stepName === EAuthModalSteps.RegisterConfirmation) return 'Регистрация'
  if (stepName === EAuthModalSteps.RecoveryPassword || stepName === EAuthModalSteps.RecoveryPasswordConfirmation) return 'Восстановление пароля'

  return ''
}

export default observer(EnterModal)
