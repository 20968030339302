import styled, {css} from 'styled-components'

import { columnMixin, resetList } from '../../styled/mixins'

export const List = styled.ul`
  ${resetList};
  display: flex;
  flex-wrap: wrap;
`

export const Item = styled.li`
  ${columnMixin(3, 15)};
  display: flex;
  flex-direction: column;
  transition: transform 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  
  &:hover {
    transform: scale(1.1);
  }
`

const contentCSS = css`
  position: relative;
  z-index: 5;
  flex-grow: 1;
  padding: 25px 8px;
  border-radius: 15px;
  background: linear-gradient(148.62deg, rgba(193, 101, 221, 0.15) 6.02%, rgba(92, 39, 254, 0.15) 94.37%), rgba(14, 11, 29, 0.8);
  backdrop-filter: blur(30px);
  color: ${props => props.theme.colors.text.contrast};
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    border-radius: 20px;
    padding: 25px 15px;
  }
`

export const Content = styled.div<{ backgroundColor: string }>`
  ${contentCSS};
   
   &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      padding: 1px;
      border-radius: 15px;  
      background: ${props => props.backgroundColor}; 
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out; 
      mask-composite: exclude; 
      
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      border-radius: 20px;
    }
   }
`

export const SafariContent = styled.div`
  ${contentCSS};
  display: flex;
  flex-direction: column;
  padding: 0;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding: 0;
  } 
`

export const SafariContentSubstrate = styled.div<{ backgroundColor: string }>`
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding: 25px 8px;
  border-radius: 15px;
  background-size: 0 0;
  background-image: ${props => props.backgroundColor};
  box-sizing: border-box;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding: 25px 15px;
    border-radius: 20px;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    
    background-image: inherit;
    background-size: auto;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="5" y="5" width="100%" height="100%" style="height:calc(100% - 10px);width:calc(100% - 10px)" rx="15" ry="15" stroke-width="1" fill="transparent" stroke="white"/></svg>') 0 / 100% 100%;
    mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="5" y="5" width="100%" height="100%" style="height:calc(100% - 10px);width:calc(100% - 10px)" rx="15" ry="15" stroke-width="1" fill="transparent" stroke="white"/></svg>') 0 / 100% 100%;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
     -webkit-mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="5" y="5" width="100%" height="100%" style="height:calc(100% - 10px);width:calc(100% - 10px)" rx="20" ry="20" stroke-width="1" fill="transparent" stroke="white"/></svg>') 0 / 100% 100%;
     mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="5" y="5" width="100%" height="100%" style="height:calc(100% - 10px);width:calc(100% - 10px)" rx="20" ry="20" stroke-width="1" fill="transparent" stroke="white"/></svg>') 0 / 100% 100%;
    }
  }
`

export const Number = styled.div<{ backgroundColor: string }>`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 41px;
  line-height: 42px;
  font-weight: 700;
  text-align: center;
  background: ${props => props.backgroundColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
   
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 66px;
    line-height: 82px;
  }
`

export const Dimension = styled.div`
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 23px;
  line-height: 24px;
  text-align: center;
    
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 40px;
  }
`

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;
  }
`
