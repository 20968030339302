import styled from 'styled-components'

import CardLibrary from '../Card'

import { resetList, columnMixin } from '../../styled/mixins'

export const List = styled.ul`
  ${resetList};
  display: flex;
  flex-wrap: wrap;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  
  svg {
    width: 60px;
    height: 60px;
    transition: transform 0.3s ease-in-out;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 100px;
      height: 100px;
    }
  }
`

export const Item = styled.li`
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    ${columnMixin(2, 15)};
  }

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    ${columnMixin(3, 30)}; 
  }
  
  &:hover ${IconContainer} {
    svg {
      transform: scale(0.9);
    }
  }
`

export const Card = styled(CardLibrary)<{ backgroundColor: string }>`
  background: ${props => props.backgroundColor};
  
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

export const Title = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${props => props.theme.colors.text.contrast};
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.08);
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 18px;
    line-height: 23px;
  }
`
