import React from 'react'

import * as Styles from './ListColumn.styled'

interface ItemColumnProps {
  ContentOval: any
  isHTML?: boolean
}

interface ItemColumn {
  id: number
  content: any
  title: string
}

interface ListColumn {
  items: ItemColumn[]
  isHTML?: boolean
}

export const ItemColumn: React.FC<ItemColumnProps> = ({ children, ContentOval, isHTML = false }) => {
  return <Styles.Root>
    <Styles.Oval>
      <ContentOval />
    </Styles.Oval>
    {
      isHTML ?
        <Styles.Title dangerouslySetInnerHTML={{ __html: String(children) }} />
        :
        <Styles.Title>
          { children }
        </Styles.Title>
    }
  </Styles.Root>
}

export const ListColumn: React.FC<ListColumn> = ({ items, isHTML }) => {
  return <Styles.List>
    {
      items.map(item => (
        <Styles.Item key={ item.id }>
          <ItemColumn ContentOval={ item.content } isHTML={ isHTML }>
            { item.title }
          </ItemColumn>
        </Styles.Item>
      ))
    }
  </Styles.List>
}

export default ListColumn
