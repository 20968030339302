import React from 'react'

const ListItemOfOne = () => {
  return <g>
    <path
      d="M524.769 212.667H456.528C452.044 212.667 448.418 209.042 448.418 204.56V158.382C448.418 153.901 452.044 150.276 456.528 150.276H524.769C529.253 150.276 532.879 153.901 532.879 158.382V204.56C532.879 209.042 529.253 212.667 524.769 212.667Z"
      fill="white"
    />
    <path
      d="M483.042 196V191.571H479.688V186.927H483.042V184.218H479.688V179.316H483.042V167.62H493.921C496.645 167.62 498.838 168.365 500.5 169.856C502.22 171.347 503.08 173.368 503.08 175.919C503.08 178.442 502.22 180.463 500.5 181.982C498.809 183.473 496.616 184.218 493.921 184.218H488.847V186.927H496.683V191.571H488.847V196H483.042ZM488.847 179.316H493.534C494.652 179.316 495.555 179.015 496.243 178.413C496.931 177.811 497.275 176.994 497.275 175.962C497.275 174.901 496.931 174.07 496.243 173.468C495.555 172.837 494.652 172.522 493.534 172.522H488.847V179.316Z"
      fill="#D5E1ED"
    />
    <path
      d="M609.252 165.105H554.329C552.087 165.105 550.285 163.281 550.285 161.063C550.285 158.822 552.109 157.02 554.329 157.02H609.252C611.494 157.02 613.296 158.844 613.296 161.063C613.296 163.281 611.494 165.105 609.252 165.105Z"
      fill="white"
    />
    <path
      d="M609.252 185.536H554.329C552.087 185.536 550.285 183.712 550.285 181.493C550.285 179.253 552.109 177.451 554.329 177.451H609.252C611.494 177.451 613.296 179.275 613.296 181.493C613.296 183.712 611.494 185.536 609.252 185.536Z"
      fill="white"
    />
    <path
      d="M582.615 205.966H554.308C552.066 205.966 550.264 204.143 550.264 201.924C550.264 199.683 552.088 197.882 554.308 197.882H582.615C584.857 197.882 586.659 199.705 586.659 201.924C586.681 204.143 584.857 205.966 582.615 205.966Z"
      fill="white"
    />
  </g>
}

const ListItemOfTwo = () => {
  return <g>
    <path
      d="M524.769 290.413H456.528C452.044 290.413 448.418 286.789 448.418 282.307V236.129C448.418 231.648 452.044 228.023 456.528 228.023H524.769C529.253 228.023 532.879 231.648 532.879 236.129V282.307C532.879 286.789 529.253 290.413 524.769 290.413Z"
      fill="white"
    />
    <path
      d="M482.354 273.38V268.951H479V264.307H482.354V261.598H479V256.696H482.354V245H493.233C495.956 245 498.149 245.745 499.812 247.236C501.532 248.727 502.392 250.748 502.392 253.299C502.392 255.822 501.532 257.843 499.812 259.362C498.121 260.853 495.928 261.598 493.233 261.598H488.159V264.307H495.995V268.951H488.159V273.38H482.354ZM488.159 256.696H492.846C493.964 256.696 494.867 256.395 495.555 255.793C496.243 255.191 496.587 254.374 496.587 253.342C496.587 252.281 496.243 251.45 495.555 250.848C494.867 250.217 493.964 249.902 492.846 249.902H488.159V256.696Z"
      fill="#D5E1ED"
    />
    <path
      d="M609.252 242.852H554.329C552.087 242.852 550.285 241.028 550.285 238.809C550.285 236.569 552.109 234.767 554.329 234.767H609.252C611.494 234.767 613.296 236.59 613.296 238.809C613.296 241.028 611.494 242.852 609.252 242.852Z"
      fill="white"
    />
    <path
      d="M609.252 263.282H554.329C552.087 263.282 550.285 261.459 550.285 259.24C550.285 256.999 552.109 255.198 554.329 255.198H609.252C611.494 255.198 613.296 257.021 613.296 259.24C613.296 261.459 611.494 263.282 609.252 263.282Z"
      fill="white"
    />
    <path
      d="M582.615 283.713H554.308C552.066 283.713 550.264 281.89 550.264 279.671C550.264 277.43 552.088 275.629 554.308 275.629H582.615C584.857 275.629 586.659 277.452 586.659 279.671C586.681 281.89 584.857 283.713 582.615 283.713Z"
      fill="white"
    />
  </g>
}

const ListItemOfThree = () => {
  return <g>
    <path
      d="M524.769 368.16H456.528C452.044 368.16 448.418 364.535 448.418 360.054V313.876C448.418 309.394 452.044 305.77 456.528 305.77H524.769C529.253 305.77 532.879 309.394 532.879 313.876V360.054C532.879 364.535 529.253 368.16 524.769 368.16Z"
      fill="white"
    />
    <path
      d="M609.252 320.598H554.329C552.087 320.598 550.285 318.775 550.285 316.556C550.285 314.315 552.109 312.514 554.329 312.514H609.252C611.494 312.514 613.296 314.337 613.296 316.556C613.296 318.775 611.494 320.598 609.252 320.598Z"
      fill="white"
    />
    <path
      d="M609.252 341.029H554.329C552.087 341.029 550.285 339.206 550.285 336.987C550.285 334.746 552.109 332.945 554.329 332.945H609.252C611.494 332.945 613.296 334.768 613.296 336.987C613.296 339.206 611.494 341.029 609.252 341.029Z"
      fill="white"
    />
    <path
      d="M582.615 361.46H554.308C552.066 361.46 550.264 359.637 550.264 357.418C550.264 355.177 552.088 353.375 554.308 353.375H582.615C584.857 353.375 586.659 355.199 586.659 357.418C586.681 359.637 584.857 361.46 582.615 361.46Z"
      fill="white"
    />
  </g>
}

export const itemsList = [
  <ListItemOfOne />,
  <ListItemOfTwo />,
  <ListItemOfThree />,
]
