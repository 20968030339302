import React from 'react'

const User = () => {
  return <g>
    <g filter="url(#filter9_welcome_rk_d)">
      <path
        d="M509.163 104.424C525.611 104.424 538.945 91.09 538.945 74.6412C538.945 58.1925 525.611 44.8582 509.163 44.8582C492.715 44.8582 479.381 58.1925 479.381 74.6412C479.381 91.09 492.715 104.424 509.163 104.424Z"
        fill="url(#paint4_welcome_rk_linear)"
      />
      <rect
        x={486.002}
        y={52.0264}
        width={45.1981}
        height={53.6095}
        fill="url(#pattern2)"
      />
      <path
        d="M520.437 63.5104C520.437 69.7338 515.387 74.7844 509.164 74.7844C502.94 74.7844 497.89 69.7338 497.89 63.5104C497.89 57.287 502.94 52.2364 509.164 52.2364C515.387 52.2125 520.437 57.263 520.437 63.5104Z"
        fill="white"
      />
      <path
        d="M509.163 104.465C517.732 104.465 525.415 100.827 530.848 95.0106C527.689 86.0585 519.192 79.6436 509.163 79.6436C499.134 79.6436 490.637 86.0585 487.478 95.0106C492.911 100.827 500.594 104.465 509.163 104.465Z"
        fill="white"
      />
    </g>
  </g>
}

export default User
