import React from 'react'

import { ReactComponent as MailSVG } from './mail.svg'
import { ReactComponent as PhoneSVG } from './phone.svg'
import { ReactComponent as TgSVG } from './telegram.svg'
import { ReactComponent as VkSVG } from './vk.svg'

export type TContactList = {
  tg: any
  vk: any
  mail: any
  phone: any
}

const contactsList: TContactList = {
  vk: VkSVG,
  tg: TgSVG,
  mail: MailSVG,
  phone: PhoneSVG,
}

export const getContactIcon = (name: keyof TContactList): React.FunctionComponent => contactsList[name]
