import React, {useEffect, useState} from 'react'
import { observer } from "mobx-react-lite";

import Section from '../../common/Section'
import Parallax from '../../common/Parallax'
import Tooltip from '../../common/Tooltip'
import Down from '../../common/Down'

import * as Styles from './Welcome.styled'

import { useDesktop } from '../../../styled/useMedia'
import { ButtonView } from '../../common/Button'

import ParallaxLinesSVG from '../../../svg/common/lines-background-welcome.svg'
import { IWelcomeSection } from '../../../types/sections'

import storeApp from "../../../store/AppStore";

type IWelcomeProps = IWelcomeSection
 
const Welcome: React.FC<IWelcomeProps> = ({ slogan, title, buttonMain, buttonSecondary, Image, nameDownSection, isReverseActions = false }) => {
  const isDesktop = useDesktop()
  const [isImageHover, setIsImageHover] = useState(false)

  useEffect(() => {
    setIsImageHover(_ => true)
  }, [])

  const bgImageDesktopStyleObject = {
    left: '65%',
    top: '-20%',
    width: '85%',
    height: '100%',
  }

  return (
    <Styles.Wrapper>
      <Parallax
        blur={0}
        bgImage={ParallaxLinesSVG}
        bgImageAlt="lines decoration"
        strength={500}
        bgImageStyle={isDesktop ? bgImageDesktopStyleObject : {}}
      >
        <Section>
          <>
            <Styles.Root>
              <Styles.Info>
                <Styles.Slogan>{slogan}</Styles.Slogan>
                <Styles.Title dangerouslySetInnerHTML={{ __html: title }}></Styles.Title>
                <Styles.Actions isReverse={isReverseActions}>
                  {isDesktop ? (
                    <Tooltip
                      idPrefix="welcomeadmin"
                      hoverComponent={
                        <Styles.Button
                          asHTML="a"
                          href={storeApp.settings.urls.publicPageOwner}
                        >
                          {buttonMain.title}
                        </Styles.Button>
                      }
                      component={<>{buttonMain.tooltip}</>}
                    />
                  ) : (
                    <Styles.Button
                      asHTML="a"
                      href={storeApp.settings.urls.publicPageOwner}
                    >
                      {buttonMain.title}
                    </Styles.Button>
                  )}
                  {isDesktop ? (
                    <Tooltip
                      idPrefix="welcomeadvertiser"
                      hoverComponent={
                        <Styles.Button
                          asHTML="a"
                          href={storeApp.settings.urls.advertiser}
                          view={ButtonView.Secondary}
                        >
                          {buttonSecondary.title}
                        </Styles.Button>
                      }
                      component={<>{buttonSecondary.tooltip}</>}
                    />
                  ) : (
                    <Styles.Button
                      asHTML="a"
                      href={storeApp.settings.urls.advertiser}
                      view={ButtonView.Secondary}
                    >
                      {buttonSecondary.title}
                    </Styles.Button>
                  )}
                </Styles.Actions>
              </Styles.Info>
              <Styles.Background
                onMouseLeave={() => setIsImageHover((_) => true)}
                onMouseEnter={() => setIsImageHover((_) => false)}
              >
                <Image isExternalAnimation={isImageHover} />
              </Styles.Background>
            </Styles.Root>
            {!isDesktop && (
              <Styles.Down>
                <Down to={nameDownSection} />
              </Styles.Down>
            )}
          </>
        </Section>
      </Parallax>
    </Styles.Wrapper>
  );
}

export default observer(Welcome)
