import React from 'react'

import * as Styles from './HeaderDesktop.styled'

import Navigation from '../../Navigation'
import HeaderActions from '../HeaderActions'

import { IExternalNavigations, INavigations } from '../../types'

interface IHeaderDesktopProps {
  navigations: INavigations
  externalLinks?: IExternalNavigations
  onClickEnterButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickRegisterButton: (e: React.MouseEvent<HTMLButtonElement> ) => void
  onClickLinkMenu: () => void
}

const HeaderDesktop: React.FC<IHeaderDesktopProps> = ({ navigations, externalLinks, onClickEnterButton, onClickRegisterButton, onClickLinkMenu }) => {
  return <Styles.Root>
    <Styles.Nav>
      <Navigation
        navigations={ navigations }
        externalLinks={ externalLinks }
        onClickLink={ onClickLinkMenu }
      />
    </Styles.Nav>
    <Styles.Actions>
      <HeaderActions
        onClickEnterButton={ onClickEnterButton }
        onClickRegisterButton={ onClickRegisterButton }
      />
    </Styles.Actions>
  </Styles.Root>
}

export default HeaderDesktop
