import styled from 'styled-components'

import { Link } from 'react-scroll'

export const Logo = styled(Link)`
  display: block;
  text-decoration: none;
  cursor: pointer;
`

export const Image = styled.img``
