import { useEffect, useRef, useState } from 'react'

export const useSticky = () => {
  const [isSticky, setSticky] = useState(false)
  const headerRef = useRef<HTMLHeadingElement | null>(null)

  useEffect(() => {
    const header = headerRef?.current

    const handlerScroll = () => {
      if (header) {
        setSticky(window.scrollY >= header.offsetHeight)
      }
    }

    window.addEventListener('scroll', handlerScroll)

    return () => {
      window.removeEventListener('scroll', handlerScroll)
    }
  }, []);

  return {
    isSticky: isSticky,
    refSticky: headerRef,
  }
}
