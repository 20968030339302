import { css } from 'styled-components'

export const visuallyHidden = css`
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
`

export const resetList = css`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const container = css`
  padding-right: 15px;
  padding-left: 15px;
  
  @media ${props => props.theme.device['desktop']} {
    box-sizing: content-box;
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px;
  }
  
  @media ${props => props.theme.device['desktop-medium']} {
    box-sizing: content-box;
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px;
  }
`

export const columnMixin = (count: number, margin: number) => {
  if (count === 1) {
    return css`
      width: 100%;
      margin-bottom: ${margin}px;
      
      &:last-child {
        margin-bottom: 0;
      }
    `
  }

  if (count > 1) {
    const width = (100 / count).toFixed(5)
    const marginWidth = (margin * (count - 1)) / count

    return css`
      width: calc(${width}% - ${marginWidth}px);
      margin-top: ${margin}px;
      margin-right: ${margin}px;
      
      &:nth-child(${count}n) {
        margin-right: 0;
      }

      &:nth-child(-n + ${count}) {
        margin-top: 0;
      }
    `
  }
}

export const placeholderColor = (placeholderCSS: any) => {
  return css`
    &::-webkit-input-placeholder {
      ${placeholderCSS}
    }

    &:-moz-placeholder {
      ${placeholderCSS}
    }

    &::-moz-placeholder {
      ${placeholderCSS}
    }

    &:-ms-input-placeholder {
      ${placeholderCSS}
    }
  `
}

export const scrollbar = (color: string) => {
  return css`
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: ${color};
      border-radius: 10px;
    }
 `
}

export const fontFace = (file: string, family: string, weight = 400, style = 'normal') => {
  return `
    @font-face {
      font-weight: ${weight};
      font-family: "${family}";
      font-style: ${style};
      src: url(${require(`../fonts/${file}.woff2`).default}) format('woff2'), url(${require(`../fonts/${file}.woff`).default}) format('woff');
      font-display: swap;
    }
  `
}

