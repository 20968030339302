import React from 'react'

import Hyphenated from 'react-hyphen'
import ru from 'hyphenated-ru'

import * as Styles from './ContentCard.styled'

interface ContentItemCard {
  id: number
  slogan: string
  title?: string
  subTitle: string
  systemName: string
}

interface IContentCardProps {
  className?: string
  content: ContentItemCard
  Icon: any
  isHyphenated?: boolean
}

const ContentCard: React.FC<IContentCardProps> = ({ content, Icon, className, isHyphenated = false }) => {
  return (
    <Styles.ContentCard className={className}>
      <Styles.Card>
        <Styles.CardWrapper>
          <Styles.IconContainer>
            <Icon />
          </Styles.IconContainer>
          {isHyphenated ? (
            <Hyphenated language={ru}>
              <Styles.CardSlogan
                isOnlySlogan={!Boolean(content.title)}
                dangerouslySetInnerHTML={{ __html: content.slogan }}
              ></Styles.CardSlogan>
            </Hyphenated>
          ) : (
            <Styles.CardSlogan
              isOnlySlogan={!Boolean(content.title)}
              dangerouslySetInnerHTML={{ __html: content.slogan }}
            ></Styles.CardSlogan>
          )}
          {content.title && (
            <Styles.CardTitle>{content.title}</Styles.CardTitle>
          )}
        </Styles.CardWrapper>
      </Styles.Card>
      <Styles.Content>
        <Styles.Slogan dangerouslySetInnerHTML={{ __html: content.slogan }}>
        </Styles.Slogan>
        <Styles.Title
          isOnlySlogan={!Boolean(content.title)}
          dangerouslySetInnerHTML={{ __html: content.subTitle }}
        ></Styles.Title>
      </Styles.Content>
    </Styles.ContentCard>
  );
}

export default ContentCard
