import React from 'react'

import * as Styles from './SkewCard.styled'

interface ISkewCardProps {
  className?: string
  isSkew?: boolean
}

const SkewCard: React.FC<ISkewCardProps> = ({ children, className, isSkew = false }) => {
  return <Styles.Root className={ className } isSkew={ isSkew }>
    <Styles.Content>
      { children }
    </Styles.Content>
    <Styles.Substrate />
  </Styles.Root>
}

export default SkewCard
