import styled, { css } from 'styled-components'
import { ModalView } from './index'

type TModalRootProps = {
  view: ModalView
}

export const Root = styled.div<TModalRootProps>`
  position: relative;
  padding-top: 30px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  background-color: rgba(19, 48, 76, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;

  @supports not ((backdrop-filter: blur(60px)) or (-webkit-backdrop-filter: blur(60px))) {
    & {
      background-color: rgba(19, 48, 76, 1);
    }
  }

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    ${props => props.view === ModalView.Fixed && css`width: 1170px`};
    ${props => props.view !== ModalView.Fixed && css`
      max-width: 460px;
      margin-left: auto;
      margin-right: auto;
    `};

      padding-top: 30px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
`

export const Header = styled.div`
  margin-bottom: 30px;
`

export const Slogan = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.text.contrast};

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 26px;
    line-height: 33px;
  }
`

export const Title = styled.p`
  margin: 0;
  margin-top: 20px;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const Content = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
`
