import React from 'react'
import { isSafari } from 'react-device-detect'

import * as Styles from './Indicators.styled'

import { TIndicators } from '../types'

interface ITIndicatorsProps {
  indicators: TIndicators
}

const Indicators: React.FC<ITIndicatorsProps> = ({ indicators }) => {
  return <Styles.List>
    {
      indicators.map(indicator => (
        <Styles.Item>
          <IndicatorContent indicatorId={ indicator.id }>
            <Styles.Number backgroundColor={ getBackgroundColorNumber(indicator.id) }>{ indicator.number }</Styles.Number>
            <Styles.Dimension>{ indicator.dimension }</Styles.Dimension>
            <Styles.Title>{ indicator.title }</Styles.Title>
          </IndicatorContent>
        </Styles.Item>
      ))
    }
  </Styles.List>
}

const IndicatorContent: React.FC<{ indicatorId: number }> = ({ children, indicatorId }) => {
  return isSafari ? (
    <Styles.SafariContent>
      <Styles.SafariContentSubstrate backgroundColor={ getBackgroundColorNumber(indicatorId) }>
        { children }
      </Styles.SafariContentSubstrate>
    </Styles.SafariContent>
    ) : (
    <Styles.Content backgroundColor={ getBackgroundColorNumber(indicatorId) }>
      { children }
    </Styles.Content>
  )
}

const getBackgroundColorNumber = (index: number): string => {
  switch (index) {
    case 1: return 'linear-gradient(148.62deg, #FACD68 5.73%, #FC76B3 93.75%)'
    case 2: return 'linear-gradient(148.62deg, #C165DD 6.02%, #5C27FE 94.37%)'
    case 3: return 'linear-gradient(148.62deg, #1DE5E2 6.25%, #B588F7 94.27%)'
    default: return 'linear-gradient(148.62deg, #FACD68 5.73%, #FC76B3 93.75%)'
  }
}

export default Indicators
