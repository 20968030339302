import React from 'react'

const Gear = () => {
  return <g>
    <g filter="url(#filter12_welcome_vp_d)">
      <g>
        <path
          fill="#fff"
          fillOpacity="0.6"
          d="M27.825 98.492h-2.422c-2.421 0-4.403 2.128-4.403 4.55v13.21c0 2.495 1.982 4.256 4.403 4.256h2.495c3.963 0 6.385 5.798 3.523 8.66l-1.614 1.614c-1.762 1.688-1.762 4.55 0 6.238l9.246 9.32c1.688 1.688 4.477 1.688 6.238 0l2.716-2.715c2.788-2.789 7.485-.807 7.485 3.156v3.963c0 2.421 1.981 4.256 4.403 4.256h13.21c2.422 0 4.403-1.761 4.403-4.256v-3.963c0-3.89 4.77-5.871 7.486-3.083l2.715 2.716c1.688 1.688 4.477 1.688 6.238 0l9.247-9.247c1.761-1.761 1.687-4.55 0-6.238l-1.615-1.688c-2.862-2.789-.44-8.66 3.523-8.66h2.495c2.422 0 4.403-1.76 4.403-4.256v-13.21c0-2.421-1.981-4.55-4.403-4.55h-2.422c-3.963 0-6.458-5.944-3.669-8.733l1.76-1.688c1.689-1.688 1.689-4.476 0-6.164l-9.246-9.247c-1.761-1.761-4.55-1.688-6.238 0l-1.761 1.541c-2.789 2.862-8.513.514-8.513-3.522V68.55c0-2.422-1.981-4.55-4.403-4.55h-13.21c-2.422 0-4.403 2.128-4.403 4.55v2.202c0 4.036-5.724 6.384-8.513 3.522l-1.688-1.688c-1.688-1.761-4.55-1.761-6.238 0l-9.32 9.32c-1.688 1.688-1.688 4.477 0 6.238l1.761 1.615c2.79 2.789.294 8.733-3.669 8.733zm38.822-6.458a17.57 17.57 0 0117.613 17.613c0 9.76-7.853 17.613-17.613 17.613a17.571 17.571 0 01-17.613-17.613c0-9.76 7.926-17.613 17.613-17.613z"
        ></path>
        <path
          stroke="#fff"
          strokeOpacity="0.3"
          d="M30.152 131.143h0l.008-.007 1.614-1.615c1.616-1.615 1.702-4.014.907-5.947-.793-1.93-2.537-3.566-4.783-3.566h-2.495c-2.174 0-3.903-1.565-3.903-3.756v-13.21c0-2.167 1.779-4.05 3.903-4.05h2.422c2.25 0 4.009-1.678 4.828-3.627.818-1.949.782-4.373-.805-5.96l-.008-.007-.008-.008-1.749-1.603c-1.495-1.573-1.481-4.042.004-5.527l9.32-9.32h0l.007-.008c1.491-1.556 4.025-1.556 5.516 0h0l.008.008 1.685 1.686c1.578 1.616 3.94 1.723 5.849.936 1.91-.787 3.52-2.534 3.52-4.81V68.55c0-2.167 1.779-4.05 3.903-4.05h13.21c2.124 0 3.903 1.883 3.903 4.05v2.202c0 2.276 1.61 4.023 3.52 4.81 1.903.785 4.26.68 5.837-.924l1.747-1.529.012-.01.012-.012a3.907 3.907 0 015.53 0l9.247 9.246h0c1.492 1.492 1.493 3.961.003 5.454l-.003.004-1.753 1.68h0l-.008.008c-1.587 1.587-1.623 4.01-.805 5.96.819 1.948 2.578 3.626 4.828 3.626h2.422c2.124 0 3.903 1.883 3.903 4.05v13.21c0 2.192-1.729 3.756-3.903 3.756h-2.495c-2.249 0-3.991 1.658-4.784 3.595-.791 1.935-.711 4.336.906 5.917l1.608 1.682h0l.008.008a3.906 3.906 0 010 5.53l-9.24 9.24-.003.003c-1.572 1.503-4.047 1.492-5.535.004l-2.71-2.71-.002-.003c-3.036-3.114-8.342-.893-8.342 3.434v3.963c0 2.191-1.73 3.756-3.903 3.756h-13.21c-2.168 0-3.903-1.633-3.903-3.756v-3.963c0-4.398-5.228-6.621-8.34-3.51l-2.707 2.708a.015.015 0 01-.004.004c-1.572 1.503-4.047 1.491-5.534.004 0 0 0 0 0 0l-9.246-9.319h0l-.009-.009c-1.556-1.491-1.556-4.025 0-5.516zm54.608-21.496a18.07 18.07 0 00-18.113-18.113c-9.962 0-18.113 8.075-18.113 18.113a18.071 18.071 0 0018.113 18.113 18.071 18.071 0 0018.113-18.113z"
        ></path>
      </g>
    </g>
  </g>
}

export default Gear
