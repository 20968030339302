import React from 'react'

import * as Styles from './Card.style'

interface ICardProps {
  className?: string
}


const Card: React.FC<ICardProps> = ({ children, className }) => {
  return <Styles.Card className={ className }>
    { children }
  </Styles.Card>
}

export default Card
