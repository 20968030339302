import React, { useMemo, useState } from 'react';

import * as Styles from './Slider.styled';

import Section from '../../common/Section';
import Slogan from '../SloganSection';
import Slider, { TSliderParams } from '../../common/Slider';
import SectionParallax from '../SectionParallax';
import { useDesktop } from '../../../styled/useMedia';

import { getSliderIcon } from '../../../svg/slider';
import { ISliderSection } from '../../../types/sections';

const SliderSection: React.FC<ISliderSection> = ({
  slogan,
  title,
  sliderList,
  isHyphenated = false,
  getBackgroundCardColor,
}) => {
  const [activeIndexSlider, setActiveIndexSlider] = useState(0);
  const isDesktop = useDesktop();

  const bgImageDesktopStyleObject = {
    left: '35%',
    width: '75%',
    height: '100%',
  };

  const slides = sliderList.map((item, index) => {
    const ContentCardIcon = getSliderIcon(item.systemName);

    return (
      <Styles.ContentCard
        content={item}
        Icon={ContentCardIcon}
        isHyphenated={isHyphenated}
        isPrev={index < activeIndexSlider}
        differenceIndexOfCenter={Math.abs(activeIndexSlider - index)}
        backgroundColor={getBackgroundCardColor(
          item.id,
          index === activeIndexSlider
        )}
      />
    );
  });

  const sliderParams: TSliderParams = useMemo(
    () => ({
      slidesPerView: 'auto',
      initialSlide: Math.floor((slides.length - 1) / 2),
      spaceBetween: 0,
      navigation: true,
      centeredSlides: true,
      effect: 'coverflow',
      loop: true,
      // loopedSlides: 5,
      // watchSlidesProgress: true,
      pagination: {
        clickable: true,
      },
      onSwiper: (swiper) => {
        setActiveIndexSlider(swiper.realIndex);
      },
      onSlideChange: (swiper) => {
        setActiveIndexSlider(swiper.realIndex);
      },
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 150,
        modifier: 1,
        slideShadows: false,
      },
    }),
    []
  );

  return (
    <Styles.Wrapper>
      <Section isContainer={true}>
        <Styles.Header>
          <Styles.Slogan>
            <Slogan>{slogan}</Slogan>
          </Styles.Slogan>
          {title && <Styles.Title>{title}</Styles.Title>}
        </Styles.Header>

        <Styles.Slider>
          <Slider sliderParams={sliderParams} slides={slides} />
        </Styles.Slider>
      </Section>
    </Styles.Wrapper>
  );
};

export default SliderSection;

// {<SectionParallax bgImageStyle={ isDesktop ? bgImageDesktopStyleObject : {} }>
// <Section isContainer={true}>
//   <Styles.Header>
//     <Styles.Slogan>
//       <Slogan>{ slogan }</Slogan>
//     </Styles.Slogan>
//     {
//       title && <Styles.Title>{ title }</Styles.Title>
//     }
//   </Styles.Header>

//   <Styles.Slider>
//     <Slider
//       sliderParams={ sliderParams }
//       slides={ slides }
//     />
//   </Styles.Slider>
// </Section>
// </SectionParallax> *}
