import styled, {css} from 'styled-components'

import { resetList } from '../../../styled/mixins'

export const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background: rgba(20, 20, 20, 0.32);
  border-radius: 15px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border-radius: 20px;
  }
  
  svg {
    max-width: 24px;
    max-height: 24px;
    stroke-width: 1.5px;
    stroke: ${props => props.theme.colors.text.contrast};
    fill: none;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      max-width: 30px;
      max-height: 30px;
    }
  }
`

export const Content = styled.div``

export const Title = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.colors.text.contrast};
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;  
  }
`

export const Subtitle = styled.a`
  position: relative;
  display: inline-flex;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.thirty.default};
  text-decoration: none;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 16px;
    line-height: 20px;
  }
  
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: ${props => props.theme.colors.thirty.default};
    border-radius: 5px;
    transition: width 0.2s ease-in-out;
  }
  
  &:hover::after {
    width: 100%;
  }
`

export const Item = styled.li`
  margin-bottom: 25px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 40px;
  }
  
  &:first-child ${Title} {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      font-size: 22px;
      line-height: 28px;
    }
  }
  
  &:first-child ${Subtitle} {
    font-size: 12px;
    line-height: 15px;
    color: ${props => props.theme.colors.text.contrast};
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      font-size: 14px;
      line-height: 17px;
    }
  }
`

export const List = styled.ul<{ isRow: boolean }>`
  ${resetList};
  
  ${props => props.isRow && css`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      display: flex;
      
      ${Item} {
        margin-bottom: 0;
        margin-right: 14px;
        
        &:last-child {
          margin-right: 0;
        }
      }
      
      ${Item} {
        &:first-child ${Title} {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  `}
`

