import styled from 'styled-components'

import { resetList } from '../../../styled/mixins'

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${resetList};
`

export const Item = styled.li`
  margin-right: 10px;
   
    @media ${props => props.theme.device['desktop-medium']} {
    margin-right: 20px;
  }
  
  &:last-child {
    margin-right: 0;
  }
`

export const Button = styled.button<{ index: number, isDisabled?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => 65 - 5 * props.index}px;
  height: ${props => 65 - 5 * props.index}px;
  border-radius: 10px;
  text-decoration: none;
  border: ${props => props.isDisabled ? '1px solid #FFFFFF0D' : '1px solid rgba(255, 255, 255, 0.2)'};
  background-color: rgba(23, 17, 51, 0.6);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  
  &:hover {
    transform: ${props => props.isDisabled ? 'scale(1)' : 'scale(1.1)'};
  }
  
  &:focus {
    outline: none;
  }
  
  & svg {
    max-width: ${props => 50 - 5 * props.index}px;
    max-height: ${props => 45 - 5 * props.index}px;
    
    @media ${props => props.theme.device['desktop-medium']}{
      max-width: ${props => 50 - 5 * props.index}px;
      max-height: ${props => 45 - 5 * props.index}px;
    }

    @media ${props => props.theme.device['desktop-medium']} {
      max-width: ${props => 65 - 6 * props.index}px;
      max-height: ${props => 55 - 6 * props.index}px;
    }
  }
  
  @media ${props => props.theme.device.desktop} {
     width: ${props => 103 - 10 * props.index}px;
     height: ${props => 103 - 10 * props.index}px;
     border-radius: 20px;
  }
`
