import React, {useCallback, useEffect, useRef} from 'react'

import Form from '../../Form'

import { phoneValidation, emailValidation, requiredValidation } from '../../Form/utils/validation'

import { useForm, Controller, NestedValue } from 'react-hook-form'

import * as Styles from './Register.styled'
import storeApp, { TErrors } from '../../../../store/AppStore'
import Switch from '../../Switch'

export interface IRegisterSubmitData {
  phone: {
    country: {
      name: string
      dialCode: number
      countryCode: string
      format: string
    }
    number: number
  }
  password: string
  email: string
}

interface IRegisterFormProps {
  cbSubmitAction: (data: IRegisterSubmitData) => void
  cbCancelAction?: (e: React.MouseEvent<HTMLButtonElement>) => void
  cbAgreeAction?: (e: React.MouseEvent<HTMLButtonElement>) => void
  backendErrors?: TErrors
  onlyCountries?: string[]
  phoneMasks?: {[key:string]:string}
  recaptcha2?: string | null
  isShowRecaptcha: boolean
  cbRecaptchaToken: (token: string) => void
}

interface IRegisterFormInitialInputs {
  phone: NestedValue<{
    number: string
    country: string
  }>
  password: string
  email: string
}

enum RegisterFormInputNames {
  password = 'password',
  phone = 'phone',
  email = 'email'
}

const RegisterForm: React.FC<IRegisterFormProps> = ({ 
    cbCancelAction, 
    cbAgreeAction, 
    cbSubmitAction, 
    onlyCountries, 
    phoneMasks,
    backendErrors, 
    recaptcha2 = null, 
    isShowRecaptcha = false, 
  cbRecaptchaToken = ()=>{} }) => {
  const { register, control, handleSubmit, setError, getValues, reset, formState: { errors } } = useForm<IRegisterFormInitialInputs>({
    mode: 'onChange',
    defaultValues: {
      [RegisterFormInputNames.password]: '',
      [RegisterFormInputNames.phone]: {
        number: '',
        country: '',
      },
      [RegisterFormInputNames.email]: ''
    }
  })

  useEffect(() => {
    if (backendErrors) {
      const errors = Object.entries(backendErrors) as [[keyof typeof RegisterFormInputNames, string]]
      errors.forEach(([name, value]) => {
        if (value) {
          setError(name, {
            type: "backend",
            message: value,
          });
        }
      })
    }
  }, [backendErrors])

  const recaptcha = useRef(null);


  useEffect(() => {
    if (isShowRecaptcha && recaptcha2) {
      window.grecaptcha.render(recaptcha.current, {
        sitekey: recaptcha2,
        callback: (response: any) => {
          cbRecaptchaToken(response);
        },
      });
    }
  }, [isShowRecaptcha, recaptcha2]);

  const changeType = useCallback((newType: number) => {
    storeApp.changeTypePage(newType, 'signup');
  }, []);


  return (
    <Form onSubmit={handleSubmit(cbSubmitAction)}>
      <Switch
        ownerTitle={"Я администратор"}
        advTitle={"Я рекламодатель"}
        initialState={storeApp.typePage === 1 ? false : true}
        onChange={changeType}
      ></Switch>
      <Form.Field
        isError={Boolean(errors[RegisterFormInputNames.email]?.message)}
      >
        <Form.Input
          type="email"
          name={RegisterFormInputNames.email}
          register={register}
          placeholder="Ваш email"
          rules={emailValidation}
        />
        <Form.Label>Ваш email...</Form.Label>
        {errors && errors[RegisterFormInputNames.email] && (
          <Form.Error>
            {errors[RegisterFormInputNames.email]?.message}
          </Form.Error>
        )}
      </Form.Field>

      <Form.Field
        isError={Boolean(errors[RegisterFormInputNames.phone]?.message)}
      >
        <Controller
          name={RegisterFormInputNames.phone}
          control={control}
          rules={{
            validate: {
              country: phoneValidation,
            },
          }}
          render={({ field }) => (
            <Form.PhoneInput
              {...field}
              onlyCountries={onlyCountries}
              masks={phoneMasks}
              placeholder="Ваш телефон"
            />
          )}
        />
        <Form.Label>Ваш телефон</Form.Label>
        {errors && errors[RegisterFormInputNames.phone] && (
          <Form.Error>
            {errors[RegisterFormInputNames.phone]?.message}
          </Form.Error>
        )}
      </Form.Field>

      <Form.Field
        isError={Boolean(errors[RegisterFormInputNames.password]?.message)}
      >
        <Form.Input
          name={RegisterFormInputNames.password}
          register={register}
          placeholder="Ваш пароль"
          type="password"
          rules={requiredValidation}
        />
        <Form.Label>Пароль</Form.Label>
        {errors && errors[RegisterFormInputNames.password] && (
          <Form.Error>
            {errors[RegisterFormInputNames.password]?.message}
          </Form.Error>
        )}
      </Form.Field>

      <Form.Field>
        <Form.AgreeButton onClick={cbAgreeAction}>
          Соглашение об использовании персональных данных
        </Form.AgreeButton>
      </Form.Field>

      <Form.Field>
        <div ref={recaptcha}></div>
      </Form.Field>

      <Form.Field>
        <Styles.Buttons>
          <Form.SubmitButton>Регистрация</Form.SubmitButton>
          <Form.CancelButton onClick={cbCancelAction}>
            Вход на сайт
          </Form.CancelButton>
        </Styles.Buttons>
      </Form.Field>
    </Form>
  );
}

export default RegisterForm
