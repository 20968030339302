import { createGlobalStyle } from 'styled-components'
import { modalStylesCSS } from './modules/modal'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fonts.main};
    font-weight: 400;
    color: ${props => props.theme.colors.text.default};
    background-color: #171133;
  }
  
  h1 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      font-size: 32px;
      line-height: 40px;
    }
  }
  
  h2 {
    margin: 0;
    padding: 0;
    font-size: 19px;
    line-height: 24px;
    font-weight: 500;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      font-size: 26px;
      line-height: 33px;
    }
  }
  
  h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
    
     @media ${props => props.theme.device['desktop']},
     @media ${props => props.theme.device['desktop-medium']} {
      font-size: 22px;
      line-height: 28px;
    }
  }
  
  h4 {
   margin: 0;
   padding: 0;
   font-size: 17px;
   line-height: 21px;
   font-weight: 500;
   
   @media ${props => props.theme.device['desktop']},
   @media ${props => props.theme.device['desktop-medium']} {
     font-size: 20px;
     line-height: 25px;
   }
  }
  
  p {
    font-size: 12px;
    line-height: 15px;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      font-size: 16px;
      line-height: 20px;
    }
  }
  
  .swiper {
    &-container {
      overflow: hidden;
    }
    
    &-container-autoheight,
    &-container-autoheight &-slide {
      height: auto;
    }

    &-wrapper {
      display: flex;
    }
    
    &-container-autoheight &-wrapper {
      align-items: flex-start;
      transition-property: transform, height;
    }
    
    &-container-thumbs-center &-wrapper {
      justify-content: center;
    }

    &-slide {
      flex-shrink: 0;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .b24-widget-button-wrapper.b24-widget-button-position-bottom-left.b24-widget-button-visible {
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      left: 120px;
    }
  }
 
  ${modalStylesCSS};
`
