import React from 'react'
import { observer } from 'mobx-react-lite'

import Enter, { IEnterSubmitData } from '../../../common/Forms/Enter'

import storeApp from '../../../../store/AppStore'
import { EAuthModalSteps } from '../../../Modals/Modals.type'

const EnterForm = () => {
  const onSubmit = (data: IEnterSubmitData) => {
    storeApp.authLogin(data)
  }

  const onClickSwitchingRegistration = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    storeApp.setAuthModalData(EAuthModalSteps.Register)
  }

  const onClickRecoveryPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    storeApp.setAuthModalData(EAuthModalSteps.RecoveryPassword)
  }

  const backendErrors = React.useMemo(() => {
    return storeApp.errors;
  }, [storeApp.errorsUpdated]);

  return <Enter
    cbSubmitAction={ onSubmit }
    cbCancelAction={ onClickSwitchingRegistration }
    cbAgreeAction={ onClickRecoveryPassword }
    onlyCountries={ storeApp.onlyCountries }
    phoneMasks={ storeApp.phoneMasks }
    backendErrors={ backendErrors }
    key={new Date().getTime()}
  />
}

export default observer(EnterForm)
