import React from 'react'

import { observer } from 'mobx-react-lite'

import RecoveryPassword, { IRecoveryPasswordSubmitData } from '../../../common/Forms/RecoveryPassword'
import storeApp from '../../../../store/AppStore'
import { EAuthModalSteps } from '../../../Modals/Modals.type'

const RecoveryPasswordForm = () => {
  const onSubmit = (data: IRecoveryPasswordSubmitData) => {
    storeApp.authRecoveryPassword(data)
  }

  const onClickSwitchingEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    storeApp.setAuthModalData(EAuthModalSteps.Enter)
  }

  const backendErrors = React.useMemo(() => {
    return storeApp.errors;
  }, [storeApp.errorsUpdated]);

  return <RecoveryPassword
    cbSubmitAction={ onSubmit }
    cbCancelAction={ onClickSwitchingEnter }
    recaptcha={ storeApp.recaptcha }
    backendErrors={ backendErrors }
    recaptcha2={ storeApp.recaptcha.key2 }
    isShowRecaptcha={ storeApp.recaptcha.isShowRecaptchaPwdReset }
    cbRecaptchaToken={token => storeApp.setRecaptcha2Token(token, 'password_reset')}
    phoneMasks={ storeApp.phoneMasks }
    key={new Date().getTime()}
  />
}

export default observer(RecoveryPasswordForm)

