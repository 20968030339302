import React from 'react'

import * as Styles from './Navigation.styled'

import { IExternalNavigations, INavigations } from '../types'
import { useDesktop } from '../../../styled/useMedia'

interface INavigationProps {
  navigations: INavigations
  externalLinks?: IExternalNavigations
  onClickLink: () => void
}

const Navigation: React.FC<INavigationProps> = ({ navigations, externalLinks, onClickLink }) => {
  const isDesktop = useDesktop()

  return <Styles.Root>
    <Styles.List>
      {
        navigations.map(navigation => (
          <Styles.Item isMore={ Boolean(navigations.length > 5) } key={ navigation.id }>
            <Styles.Link
              to={ navigation.href }
              activeClass="selected"
              spy
              smooth
              offset={ !isDesktop ? -120 : 0 }
              onClick={ onClickLink }
            >
              <Styles.Name>
                { navigation.name }
              </Styles.Name>
            </Styles.Link>
          </Styles.Item>
        ))
      }
      {
        externalLinks && externalLinks.map(externalNavigation => (
          <Styles.Item isMore={ Boolean(navigations.length > 5) }  key={ externalNavigation.id }> 
            <Styles.ExternalLink href={ externalNavigation.href } target="_blank" rel="noopener noreferrer">
              <Styles.Name>
                { externalNavigation.name }
              </Styles.Name>
            </Styles.ExternalLink>
          </Styles.Item>
        ))
      }
    </Styles.List>
  </Styles.Root>
}

export default Navigation
