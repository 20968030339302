import React from 'react'

import { observer } from 'mobx-react-lite'
import storeApp from '../../../store/AppStore'
import { EAuthModalSteps, EModalNames } from '../../Modals/Modals.type'

import Header, { IHeaderProps } from '../../common/Header'

type THeaderVPProps = Omit<IHeaderProps, "isOpenMobileMenu" | "onClickMenuButton" | "onClickEnterButton" | "onClickRegisterButton" | "onClickLinkMenu">

const HeaderVP: React.FC<THeaderVPProps> = (props) => {
  const navigations = props.navigations.filter(item => item.id !== 1)
  const isOpenMobileMenu: boolean = storeApp.isMobileMenuOpen

  const onClickMenuButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.toggleMobileMenu()
  }

  const onClickLinkMenu = () => {
    storeApp.toggleMobileMenu()
  }

  const onClickEnterButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Enter)
    storeApp.setAuthModalData(EAuthModalSteps.Enter)
  }

  const onClickRegisterButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Enter)
    storeApp.setAuthModalData(EAuthModalSteps.Register)
  }

  return <Header
    { ...props }
    navigations={ navigations }
    isOpenMobileMenu={ isOpenMobileMenu }
    onClickMenuButton={ onClickMenuButton }
    onClickEnterButton={ onClickEnterButton }
    onClickRegisterButton={ onClickRegisterButton }
    onClickLinkMenu={ onClickLinkMenu }
  />
}

export default observer(HeaderVP)
