import { useState, useCallback } from 'react'

import YouTube, { YouTubeProps } from 'react-youtube'
import { YouTubePlayer } from 'youtube-player/dist/types'

import * as Styles from './VideoYoutube.styled'

type TOnReadyYoutubePlayer = (player: {target: YouTubePlayer}) => void

const VideoYoutube: React.FC<YouTubeProps> = ({ videoId, opts = {} }) => {
  const [youtube, setYoutube] = useState<YouTubePlayer>()
  const [isPlay, setIsPlay] = useState<boolean>(false)

  const onReady: TOnReadyYoutubePlayer = useCallback((player) => {
    setYoutube(player.target)
  }, [])

  const onPlayClick = useCallback(() => {
    if (youtube) {
      youtube.playVideo()
      setIsPlay(_ => true)
    }
  }, [youtube])

  return <Styles.Root>
    {!isPlay &&
      <Styles.PlayButton onClick={ onPlayClick } disabled={ !Boolean(youtube) } isLoading={ !Boolean(youtube) } >
        {/*{ Boolean(youtube) && `play` }*/}
      </Styles.PlayButton>
    }
    <YouTube
      opts={ opts }
      videoId={ videoId }
      onReady={ onReady }
    />
  </Styles.Root>
}

export default VideoYoutube
