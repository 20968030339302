import React from 'react'

import * as Styles from './Presentation.styled'

import { ButtonView } from '../../common/Button'
import Section from '../../common/Section'
import Slogan from '../SloganSection'
import VideoYoutube from '../../common/VideoYoutube'
import SectionParallax from '../SectionParallax'

import storeApp from '../../../store/AppStore'
import { EAuthModalSteps, EModalNames } from '../../Modals/Modals.type'
import { useDesktop } from '../../../styled/useMedia'

const Presentation = () => {
  const isDesktop = useDesktop()

  const bgImageDesktopStyleObject = {
    left: '40%',
    width: '75%',
    height: '100%',
  }

  const onClickRegisterButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Enter)
    storeApp.setAuthModalData(EAuthModalSteps.Register)
  }

  return <Styles.Wrapper>
    <SectionParallax
      bgImageStyle={ isDesktop ? bgImageDesktopStyleObject : {} }
    >
      <Section>
        <Styles.Header>
            <Slogan>Видео презентация</Slogan>
        </Styles.Header>

        <Styles.Content>
          <Styles.Video>
            <VideoYoutube videoId="y1o6HPJhLkk" />
          </Styles.Video>

          <Styles.Description>
            <Styles.SloganContent>
              Безопасная монетизация групп
            </Styles.SloganContent>
            <Styles.Title>
              Сотрудничество с Социальными сетями и партнерскими компаниями позволяет увеличить качество взаимодействия всех составляющих сервиса, собственная креативная команда берет на себя все важные и ответственные задачи, чтобы владельцы пабликов могли доверять сервису и быть уверенными в надежности его работы.
            </Styles.Title>
            <Styles.Action>
              <Styles.Button view={ ButtonView.Outline } onClick={ onClickRegisterButton }>Зарегистрироваться</Styles.Button>
            </Styles.Action>
          </Styles.Description>
        </Styles.Content>

      </Section>
    </SectionParallax>
  </Styles.Wrapper>
}

export default Presentation
