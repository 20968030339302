import styled from 'styled-components'
import { columnMixin, resetList } from '../../../styled/mixins'

import SkewCardLibrary from '../../SkewCard'
import ListWithEllipseLibrary from '../../ListWithEllipse'

import * as SkewCardLibraryStyles from '../../SkewCard/SkewCard.styled'
import * as ListWithEllipseLibraryStyles from '../../ListWithEllipse/ListWithEllipse.styled'

export const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.main.darken};
  
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 250px;
    background: linear-gradient(0deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 1;
  }
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 150px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`

export const Header = styled.div`
  position: relative;
  z-index: 5;
  margin-bottom: 15px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;  
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 5;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex;
  }
`

export const LeftSide = styled.div`
  margin-bottom: 20px;
    @media ${props => props.theme.device['desktop']} {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 0;
      flex-shrink: 0;
  }
    @media ${props => props.theme.device['desktop-medium']} {
      width: 500px;
      margin-right: 30px;
      margin-bottom: 0;
      flex-shrink: 0;
  }
`

export const RightSide = styled.div`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    flex-grow: 1;
  }
`

export const Indicators = styled.div`
  margin-bottom: 20px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 40px;
  }
`

export const Segment = styled.div``

export const Social = styled.div``

export const List = styled.div``

export const SubSlogan = styled.h3`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  color: ${props => props.theme.colors.text.contrast};
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 25px;
  }
`

export const SkewCard = styled(SkewCardLibrary)`
    @media ${props => props.theme.maxDevice['desktop']},
    @media ${props => props.theme.maxDevice['desktop-medium']} {
    ${SkewCardLibraryStyles.Content} {
      padding: 0;
      border: none;
      background: none;
      backdrop-filter: none;
    }
    
    ${SkewCardLibraryStyles.Substrate} {
      display: none;
    }
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    grid-template-columns: repeat(3, 1fr);
    
    ${SkewCardLibraryStyles.Substrate} {
      background: #204A74;
    }
  }
`

export const ListWithEllipse = styled(ListWithEllipseLibrary)`
    @media ${props => props.theme.maxDevice['desktop']},
    @media ${props => props.theme.maxDevice['desktop-medium']} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
    row-gap: 5px;
    
    ${ListWithEllipseLibraryStyles.Item} {
      margin-bottom: 0;
    }
    
    ${ListWithEllipseLibraryStyles.Title} {
      font-size: 10px;
      line-height: 12px;
    }
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 20px;
    height: 20px;
    fill: none;
    stroke: ${props => props.theme.colors.text.contrast};
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 26px;
      height: 26px;
    }
  }
`
