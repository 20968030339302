import React from 'react'

import * as Styles from './Button.styled'

export enum ButtonView {
  Outline = 'Outline',
  Default = 'Default',
  Secondary = 'Secondary',
}

export enum ButtonSize {
  Default = 'Default',
  Small = 'Small',
}

interface IButtonProps {
  className?: string
  type?: "button" | "reset" | "submit" | undefined
  view?: ButtonView
  size?: ButtonSize
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  asHTML?: React.ElementType
  href?: string
}

const Button: React.FC<IButtonProps> = ({ children, className, type = 'button', view = ButtonView.Default, size = ButtonSize.Default, onClick = () => {}, disabled = false, asHTML = 'button', href = '' }) => {
  return <Styles.Button
    className={ className }
    type={ type }
    view={ view }
    size={ size }
    onClick={ onClick }
    disabled={ disabled }
    as={ asHTML }
    href={ href }
  >
    { children }
  </Styles.Button>
}

export default Button
