import React, { useEffect, useRef } from "react";

import Form from '../../Form'

import { requiredValidation } from '../../Form/utils/validation'

import { useForm } from 'react-hook-form'

import * as Styles from './Feedback.styled'

import { TErrors } from '../../../../store/AppStore'

export interface IFeedbackSubmitData {
  name: string
  toe: string
  message: string
}

interface IFeedbackFormProps {
  cbSubmitAction: (data: IFeedbackSubmitData) => void
  backendErrors?: TErrors
  recaptcha2?: string | null
  isShowRecaptcha: boolean
  cbRecaptchaToken: (token: string) => void
}

export interface IFeedbackFormInitialInputs {
  name: string
  toe: string
  message: string
}

enum FeedbackFormInputNames {
  name = 'name',
  toe = 'toe',
  message = 'message'
}

const FeedbackForm: React.FC<IFeedbackFormProps> = ({ cbSubmitAction, backendErrors, recaptcha2 = null, isShowRecaptcha = false, cbRecaptchaToken = ()=>{} }) => {
  const { register, handleSubmit, setError, formState: { errors } } = useForm<IFeedbackFormInitialInputs>({
    mode: 'onChange',
    defaultValues: {
      [FeedbackFormInputNames.name]: '',
      [FeedbackFormInputNames.toe]: '',
      [FeedbackFormInputNames.message]: '',
    }
  })

  useEffect(() => {
    if (backendErrors) {
      const errors = Object.entries(backendErrors) as [[keyof typeof FeedbackFormInputNames, string]]
      errors.forEach(([name, value]) => {
        if (value) {
          setError(name, {
            type: "backend",
            message: value,
          });
        }
      })
    }
  }, [backendErrors])

  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (isShowRecaptcha && recaptcha2) {
      window.grecaptcha.render(recaptchaRef.current, {
        sitekey: recaptcha2,
        callback: (response: any) => {
          cbRecaptchaToken(response);
        },
      });
    }
  }, [isShowRecaptcha, recaptcha2]);

  return <Form onSubmit={ handleSubmit(cbSubmitAction) }>
    <Form.Field isError={ Boolean(errors[FeedbackFormInputNames.name]?.message) }>
      <Form.Input
        name={ FeedbackFormInputNames.name }
        register={ register }
        placeholder="Ваше имя..."
        rules={ requiredValidation }
      />
      <Form.Label>Имя</Form.Label>
      { errors && errors[FeedbackFormInputNames.name] && <Form.Error>{ errors[FeedbackFormInputNames.name]?.message }</Form.Error> }
    </Form.Field>

    <Form.Field isError={ Boolean(errors[FeedbackFormInputNames.toe]?.message) }>
      <Form.Input
        name={ FeedbackFormInputNames.toe }
        register={ register }
        placeholder="Телефон или email..."
        rules={ requiredValidation }
      />
      <Form.Label>Телефон/Email</Form.Label>
      { errors && errors[FeedbackFormInputNames.toe] && <Form.Error>{ errors[FeedbackFormInputNames.toe]?.message }</Form.Error> }
    </Form.Field>

    <Form.Field isError={ Boolean(errors[FeedbackFormInputNames.message]?.message) }>
      <Form.Textarea
        name={ FeedbackFormInputNames.message }
        register={ register }
        placeholder="Ваше сообщение..."
        rules={ requiredValidation }
      />
      <Form.Label>Сообщение</Form.Label>
      { errors && errors[FeedbackFormInputNames.message] && <Form.Error>{ errors[FeedbackFormInputNames.message]?.message }</Form.Error> }
    </Form.Field>


    <Form.Field>
      <div ref={recaptchaRef}></div>
    </Form.Field>


    <Form.Field>
      <Styles.Buttons>
        <Form.SubmitButton>Связаться с менеджером</Form.SubmitButton>
      </Styles.Buttons>
    </Form.Field>
  </Form>
}

export default FeedbackForm
