import React from 'react'

const MessageHeart = () => {
  return <g>
    <path
      d="M269.32 0H193.858C187.004 0 181.464 5.56242 181.464 12.395V63.0482C181.464 69.9026 187.026 75.4432 193.858 75.4432H221.472L231.589 85.5607L241.706 75.4432H269.32C276.174 75.4432 281.714 69.8808 281.714 63.0482V12.395C281.736 5.54052 276.174 0 269.32 0Z"
      fill="url(#paint0_welcome_rk_linear)"
    />
    <rect
      x={209.741}
      y={20.8335}
      width={43.0958}
      height={35.7397}
      fill="url(#pattern1_welcome_rk)"
    />
    <g filter="url(#filter0_welcome_rk_d)">
      <path
        d="M236.822 49.2297L231.589 52.6241L226.355 49.2516C213.282 40.7985 213.282 34.7543 213.282 32.4768C213.282 27.1552 217.596 22.8411 222.917 22.8411C226.727 22.8411 230.034 25.0529 231.589 28.2721C233.143 25.0529 236.45 22.8411 240.26 22.8411C245.582 22.8411 249.896 27.1552 249.896 32.4768C249.896 34.7324 249.896 40.7766 236.822 49.2297Z"
        fill="white"
      />
    </g>
  </g>
}

export default MessageHeart
