import React from 'react'

import EnterModal from './Enter'
import InfoModal from './Info'

const Modals = () => {
  return <>
    <EnterModal />
    <InfoModal />
  </>
}

export default Modals
