import styled from 'styled-components'
import SkewCardLibrary from '../../SkewCard'
import * as SkewCardLibraryStyles from '../../SkewCard/SkewCard.styled'
import ButtonLibrary from '../../common/Button'

export const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.main.darken};
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`

export const Header = styled.div`
  margin-bottom: 20px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 90px;
  }
`

export const SloganContent = styled.h3`
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.text.contrast};
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 25px;
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 5;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex; 
  }
`

export const YouAction = styled.div`
  margin-bottom: 20px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 360px;
    flex-shrink: 0;
    margin-right: 30px;
    margin-bottom: 0;
  }
`

export const SkewCard = styled(SkewCardLibrary)`
  ${SkewCardLibraryStyles.Substrate} {
    background: linear-gradient(148.62deg, #2AFEB7 5.21%, #08C7BC 93.75%);
  }
`

export const BidFoxAction = styled.div`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    flex-grow: 1;
  }
`

export const Action = styled.div``

export const Button = styled(ButtonLibrary)`
    @media ${props => props.theme.maxDevice['desktop']},
    @media ${props => props.theme.maxDevice['desktop-medium']} {
    width: 100%;
    justify-content: center;
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;
  }
`

export const Title = styled.p`
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 18px;
  }
`

export const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.colors.text.contrast};
  border-radius: 8px;
  border: 1.5px solid ${props => props.theme.colors.text.contrast};
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 25px;
    border-radius: 11px;
  }
`

export const Link = styled.button`
  position: relative;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.thirty.default};
  border: none;
  background-color: transparent;
  cursor: pointer;
  
  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.thirty.default};
    transition: width 0.2s ease-in-out;
  }
  
  &:hover::after {
    width: 0;
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;
  }
  
  &:hover {
    text-decoration: none;
  }
  
  &:focus {
    outline: none;
  }
`
