import React from 'react'

import ReactTooltip from 'react-tooltip'
import { Portal } from 'react-portal'

import * as Styles from './Tooltip.styled'
import { overridePosition } from './utils'

interface ITooltipProps {
  idPrefix: string
  component: any
  hoverComponent: any
}

const Tooltip: React.FC<ITooltipProps> = ({ idPrefix, component, hoverComponent }) => {
  return <Styles.Wrapper>
    <Styles.Content data-tip={ true } data-for={`${idPrefix}tooltip`}>
      { hoverComponent }
    </Styles.Content>
    <Portal>
      <Styles.Tooltip>
        <ReactTooltip
          html={!component}
          id={`${idPrefix}tooltip`}
          type="dark"
          overridePosition={ overridePosition }>
            { component }
        </ReactTooltip>
      </Styles.Tooltip>
    </Portal>
  </Styles.Wrapper>
}

export default Tooltip
