import Welcome from '../../../components/Sections/Welcome'
import Slider from '../../../components/Sections/Slider'
import HowWorkSystem from '../../../components/Sections/HowWorkSystem'
import Advantages from '../../../components/Sections/Advantages'
import ReferallProgram from '../../../components/Sections/ReferralProgram'
import Presentation from '../../../components/Sections/Presentation'
import Contact from '../../../components/Sections/Contact'

import { sliderContent, welcomeContent } from './data'
import { SectionNamesVP } from './names'
import { ISections } from '../../../types/sections'

export const sections: ISections = [
  {
    id: 1,
    name: SectionNamesVP.Welcome,
    component: Welcome,
    content: welcomeContent,
  },
  {
    id: 2,
    name: SectionNamesVP.Monetization,
    component: Slider,
    content: sliderContent,
  },
  {
    id: 3,
    name: SectionNamesVP.Work,
    component: HowWorkSystem,
    content: {
      name: 'Viza',
    },
  },
  {
    id: 4,
    name: SectionNamesVP.Advantages,
    component: Advantages,
    content: {
      name: 'Viza',
    },
  },
  {
    id: 5,
    name: SectionNamesVP.Referral,
    component: ReferallProgram,
    content: {
      name: 'Viza',
    },
  },
  {
    id: 6,
    name: SectionNamesVP.About,
    component: Presentation,
    content: {
      name: 'Viza',
    },
  },
  {
    id: 7,
    name: SectionNamesVP.Contacts,
    component: Contact,
    content: {
      name: 'Viza',
    },
  },
]
