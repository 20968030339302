import React from 'react'

import * as Styles from './Section.styled'

interface ISectionProps {
  isContainer?: boolean
}

const Section: React.FC<ISectionProps> = ({ children, isContainer = true }) => {

  return <Styles.Root>
    {
      isContainer ?
      <Styles.Container>
        { children }
      </Styles.Container>
        : children
    }
  </Styles.Root>
}

export default Section
