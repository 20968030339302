import styled, { css } from 'styled-components'
import { isMobileSafari } from 'react-device-detect'

export const Root = styled.div`
  padding: 15px;

  background: rgba(23, 17, 51, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  border-top-left-radius: 0;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding: 35px 30px;
    border-radius: 40px;
    border-top-left-radius: 0;
  }
  
  @supports not ((backdrop-filter: blur(60px)) or (-webkit-backdrop-filter: blur(60px))) {
    & {
      background: rgba(23, 17, 51, 1);
    }
  }
`

export const Content = styled.div``
