import React from 'react'

import * as Styles from './Opportunity.styled'

import { TOpportunitys } from '../types'

interface IOpportunityProps {
  opportunitys: TOpportunitys
}

const Opportunity: React.FC<IOpportunityProps> = ({ opportunitys }) => {
  return <Styles.List>
    {
      opportunitys.map(opportunity => {
        const Icon = opportunity.Icon

        return (
          <Styles.Item key={`opportunity-${opportunity.id}`}>
            <Styles.SkewCard
              backgroundColor={getBackgroundColor(opportunity.id)}
            >
              <Styles.Card>
                <Styles.ContainerIcon>
                  <Icon />
                </Styles.ContainerIcon>
                <Styles.Content>
                  <Styles.Slogan>{opportunity.slogan}</Styles.Slogan>
                  <Styles.Title>{opportunity.title}</Styles.Title>
                </Styles.Content>
              </Styles.Card>
            </Styles.SkewCard>
          </Styles.Item>
        );
      })
    }
  </Styles.List>
}

const getBackgroundColor = (id: number): string => {
  switch(id) {
    case 1: return 'linear-gradient(148.62deg, #C165DD 6.02%, #5C27FE 94.37%)'
    case 2: return 'linear-gradient(148.62deg, #FACD68 5.73%, #FC76B3 93.75%)'
    case 3: return 'linear-gradient(148.62deg, #1DE5E2 6.25%, #B588F7 94.27%)'
    case 4: return 'linear-gradient(148.62deg, #55AFF1 5.21%, #1153FC 95.31%)'
    case 5: return 'linear-gradient(148.62deg, #2AFEB7 5.21%, #08C7BC 93.75%)'
    case 6: return 'linear-gradient(148.62deg, #1DE5E2 6.25%, #B588F7 94.27%)'
    default: return ''
  }
}

export default Opportunity
