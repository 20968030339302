import React from 'react'

import * as Styles from './ButtonWithIcon.styled'

interface IButtonWithIconProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ButtonWithIcon: React.FC<IButtonWithIconProps> = ({ children, onClick }) => {
  return <Styles.Button onClick={ onClick }>
    { children }
  </Styles.Button>
}

export default ButtonWithIcon
