import React from 'react'

import './styled/scss/fonts.scss'

import { ThemeProvider } from 'styled-components'
import { ToastContainer } from "react-toastify"

import { getThemeApp, ThemeModes } from './styled/theme'
import { GlobalStyle } from './styled/globalStyle'

import VPPage from './pages/VPPage'
import RKPage from './pages/RKPage'

import 'react-toastify/dist/ReactToastify.min.css'

function App() {
  const themeApp = getThemeApp(ThemeModes.Default)

  return (
    <ThemeProvider theme={themeApp}>
      <GlobalStyle />
      <div className="wrapper">
        {process.env.REACT_APP_PERSONAL_AREA === "owner" ? <VPPage /> : <RKPage />}
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
