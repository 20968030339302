import React from 'react'
import { observer } from 'mobx-react-lite'

import * as Styles from './Contact.styled'

import Section from '../../common/Section'
import Slogan from '../SloganSection'
import FormFeedback, { IFeedbackFormInitialInputs } from '../../common/Forms/Feedback'

import { IContactsFormSection } from '../../../types/sections'
import Contacts from "../../common/Contacts";
import storeApp from '../../../store/AppStore'

const ContactForm: React.FC<IContactsFormSection> = ({ slogan, subSlogan, contactsList }) => {

  const backendErrors = React.useMemo(() => {
    return storeApp.errors;
  }, [storeApp.errorsUpdated]);

  return (
    <Styles.Wrapper>
      <Section>
        <Styles.Header>
          <Slogan>{slogan}</Slogan>
        </Styles.Header>

        <Styles.Content>
          <Styles.Description>
            <Styles.Slogan
              dangerouslySetInnerHTML={{ __html: subSlogan }}
            ></Styles.Slogan>
          </Styles.Description>
          <Styles.Form>
            <Styles.OpacityCard>
              <Styles.Social>
                <Contacts contacts={contactsList} isRow />
              </Styles.Social>
              <Styles.Feedback>
                <FormFeedback
                  cbSubmitAction={(data: IFeedbackFormInitialInputs) =>
                    storeApp.feedbackSend(data)
                  }
                  backendErrors={backendErrors}
                  recaptcha2={storeApp.recaptcha.key2}
                  isShowRecaptcha={storeApp.recaptcha.isShowRecaptchaFeedback}
                  cbRecaptchaToken={(token) =>
                    storeApp.setRecaptcha2Token(token, "feedback")
                  }
                />
              </Styles.Feedback>
            </Styles.OpacityCard>
          </Styles.Form>
        </Styles.Content>
      </Section>
    </Styles.Wrapper>
  );
}

export default observer(ContactForm)
