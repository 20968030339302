import React from 'react'

const SocialVk = () => {
  return <g>
    <g filter="url(#filter10_welcome_vp_d)">
      <ellipse
        cx="650.801"
        cy="189.8"
        fill="url(#paint8_welcome_vp_linear)"
        rx="23.801"
        ry="23.8"
      ></ellipse>
    </g>
    <g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M658.934 191.691c.73.713 1.5 1.383 2.155 2.167.289.349.563.709.772 1.113.297.575.028 1.208-.488 1.242l-3.205-.001c-.826.068-1.486-.264-2.04-.83-.444-.452-.855-.933-1.282-1.4a3.054 3.054 0 00-.576-.513c-.438-.284-.818-.197-1.068.26-.254.464-.312.978-.337 1.495-.034.755-.263.953-1.021.988-1.62.076-3.157-.169-4.586-.986-1.259-.721-2.236-1.738-3.086-2.89-1.655-2.242-2.922-4.706-4.061-7.239-.256-.57-.069-.877.561-.888a88.718 88.718 0 013.138-.001c.425.006.706.25.87.651.566 1.391 1.258 2.715 2.127 3.941.231.327.467.654.803.883.372.255.655.171.83-.243a2.77 2.77 0 00.184-.827c.083-.967.094-1.933-.051-2.897-.09-.602-.429-.991-1.029-1.105-.306-.058-.261-.172-.112-.347.257-.301.5-.489.983-.489h3.622c.57.112.697.369.775.942l.003 4.024c-.006.222.111.881.511 1.028.32.105.531-.151.723-.354.868-.921 1.487-2.009 2.04-3.135.245-.495.456-1.01.66-1.524.152-.381.39-.569.819-.56l3.486.003c.104 0 .208.001.308.018.588.101.749.353.567.927-.285.9-.842 1.65-1.386 2.403-.581.805-1.203 1.582-1.779 2.391-.53.739-.488 1.111.17 1.753z"
        clipRule="evenodd"
      ></path>
    </g>
  </g>
}

export default SocialVk
