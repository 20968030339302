import styled, { css } from 'styled-components';

import ArrowSVG from '../../../svg/common/arrow.svg';

const SwiperCSS = css`
  .swiper {
    &-container {
      position: relative;
      overflow: hidden;
      z-index: 1;
      // margin: 0 80px;
    }

    &-wrapper {
      display: flex;
      transition-property: transform;
    }

    &-container-android &-slide,
    &-wrapper {
      transform: translate3d(0px, 0, 0);
    }

    &-container-pointer-events {
      touch-action: pan-y;
    }

    &-container-pointer-events&-container-vertical {
      touch-action: pan-x;
    }

    &-container-thumbs-center &-wrapper {
      justify-content: center;
    }

    &-slide {
      position: relative;
      flex-shrink: 0;
      width: auto;
      transition-property: transform;
      transform: translateZ(0);
    }

    &-button {
      &-prev,
      &-next {
        width: 35px;
        height: 35px;
        background-color: rgba(20, 20, 20, 0.4);
        backdrop-filter: blur(10px);
        border-radius: 50%;

        @media ${(props) => props.theme.device['desktop']},
          @media ${(props) => props.theme.device['desktop-medium']} {
          width: 50px;
          height: 50px;
        }

        &::after {
          content: '';
          width: 12px;
          height: 12px;
          background-image: url(${ArrowSVG});
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 12px 12px;

          @media ${(props) => props.theme.device['desktop']},
            @media ${(props) => props.theme.device['desktop-medium']} {
            width: 14px;
            height: 14px;
            background-size: 14px 14px;
          }
        }
      }

      &-prev {
        @media ${(props) => props.theme.device['desktop']} {
          // left: 20%;
          left: 200px;
          top: 45%;
        }
        @media ${(props) => props.theme.device['desktop-medium']} {
          // left: 25%;
          left: 260px;
          top: 45%;
        }
      }

      &-next {
        @media ${(props) => props.theme.device['desktop']} {
          // right: 20%;
          right: 201px;
          top: 45%;
        }
        @media ${(props) => props.theme.device['desktop-medium']} {
          // right: 25%;
          right: 261px;
          top: 45%;
        }

        &::after {
          transform: rotate(180deg);
        }
      }

      &-disabled {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
`;

const SwiperPaginationCSS = css`
  .swiper-container {
    padding-bottom: 40px;
    @media ${(props) => props.theme.device['desktop']},
      @media ${(props) => props.theme.device['desktop-medium']} {
      padding-bottom: 50px;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-container-horizontal
    > .swiper-pagination-bullets
    > .swiper-pagination-bullet {
    margin: 0 5px;
  }

  .swiper-pagination-bullet {
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    transition: background-color 0.2s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      margin-left: -7px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    }

    &-active {
      background-color: ${(props) => props.theme.colors.text.contrast};
      box-shadow: 1px -1px 39px 4px rgba(255, 255, 255, 0.55);

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

type RootProps = {
  isPagination: boolean;
};

export const Root = styled.div<RootProps>`
  ${SwiperCSS};
  ${(props) => props.isPagination && SwiperPaginationCSS};
`;

export const ContainerEnd = styled.div``;
