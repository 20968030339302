import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const Nav = styled.div`
  margin-right: 40px;
`

export const Actions = styled.div`
  margin-left: auto;
`
