import { Parallax as ParallaxLibrary, ParallaxProps } from 'react-parallax'

const Parallax = ({ children, blur, bgImage, bgImageAlt, bgClassName, className, strength, bgImageStyle }: ParallaxProps) => {
  return <ParallaxLibrary
      blur={ blur }
      bgImage={ bgImage }
      bgImageAlt={ bgImageAlt }
      strength={ strength }
      bgClassName={ bgClassName }
      className={ className }
      bgImageStyle={ bgImageStyle }
      bgImageSizes="100%"
  >
    { children }
  </ParallaxLibrary>
}

export default Parallax
