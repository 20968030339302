import styled from 'styled-components'

export const Container = styled.div<{ isContent: boolean }>`  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
  }
`

export const Content = styled.div`
  margin-bottom: 15px;
  color: ${props => props.theme.colors.text.contrast};
  
  & span {
    font-family: ${props => props.theme.fonts.main};
    font-size: 12px !important;
    line-height: 16px !important;
    color: ${props => props.theme.colors.text.contrast} !important;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
  }
`

export const Action = styled.div`
  display: flex;
  justify-content: center;
  
  & button {
     @media ${props => props.theme.maxDevice['desktop']},
     @media ${props => props.theme.maxDevice['desktop-medium']} {
      width: 100%;
      justify-content: center;
    }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`
