import styled from 'styled-components'
import ButtonLibrary from '../../common/Button'

export const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.main.darken};
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`

export const Header = styled.div`
  margin-bottom: 25px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 60px;  
  }
`

export const SloganContent = styled.h3`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.text.contrast};
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 25px;
  }
`

export const Content = styled.div`
  margin-bottom: 40px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex;
    margin-bottom: 60px;
  }
`

export const YouAction = styled.div`
  margin-bottom: 20px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 310px;
    flex-shrink: 0;
    margin-right: 30px;
    margin-bottom: 0;
  }
`

export const List = styled.div`
  margin-bottom: 20px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
  }
`

export const BidFoxAction = styled.div` 
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    flex-grow: 1;
  }
`

export const Action = styled.div``

export const Button = styled(ButtonLibrary)`
   @media ${props => props.theme.maxDevice['desktop']},
   @media ${props => props.theme.maxDevice['desktop-medium']} {
    width: 100%;
    justify-content: center;
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;
  }
`

export const Title = styled.p`
  position: relative;
  z-index: 5;
  margin: 0;
  padding: 0;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px;
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 18px;
    height: 18px;
    fill: none;
    stroke: ${props => props.theme.colors.text.contrast};
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      width: 23px;
      height: 23px;
    }
  }
`
