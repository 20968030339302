import styled, { css } from 'styled-components'

export const Root = styled.div``

export const Line = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: rgba(23, 17, 51, 0.01);
  backdrop-filter: blur(21px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`

export const Menu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: 15%;
  margin-right: 15%;
  background-color: rgba(23, 17, 51, 0.6);
  backdrop-filter: blur(30px);
  border-radius: 0 0 30px 30px;
  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0s linear 0.2s, visibility 0s linear 0.2s;
  
  ${props => props.isOpen && css`
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
    transition: transform 0.2s ease-in-out;
  `}
`

export const MenuContent = styled.div`
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
`

export const ButtonMenu = styled.div`
  margin-right: 20px;
`

const buttonOpenCSS = css`
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    margin-top: -2px;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
`

type ButtonMobileMenuProps = {
  isOpen: boolean
}

export const ButtonMenuStyle = styled.button<ButtonMobileMenuProps>`
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin: 0;
  padding: 2px;
  font-size: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::after,
  &::before {
    content: "";
    display: block;
    width: 15px;
    height: 2px;
    background-color: ${props => props.theme.colors.text.contrast};
    transition: transform 0.15s linear;
    border-radius: 5px;
  }
  
  ${props => props.isOpen && buttonOpenCSS};
`

type ButtonLineMobileMenuProps = {
  isOpen: boolean
}

const ButtonLineHiddenCSS = css`
  visibility: hidden;
  opacity: 0;
`

export const ButtonLine = styled.div<ButtonLineMobileMenuProps>`
  width: 15px;
  height: 2px;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: ${props => props.theme.colors.text.contrast};
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s linear, visibility 0.15s linear;
  border-radius: 5px;

  ${props => props.isOpen && ButtonLineHiddenCSS};
`

export const ButtonUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  
  svg {
    width: 20px;
    height: 20px;
    fill: none;
    stroke: ${props => props.theme.colors.text.contrast};
  }
`

export const Logo = styled.div`
  margin-left: auto;
  margin-right: auto;
`

export const MenuLogo = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`

export const Nav = styled.div`
  margin-bottom: 30px;
`

export const Actions = styled.div`
  margin-bottom: 30px;
`

export const Social = styled.div`
  display: flex;
  justify-content: center;
`
