import React from 'react'

const Picture = () => {
  return <g>
    <g filter="url(#filter7_welcome_rk_d)">
      <path
        d="M583.2 476.553H385.831C381.364 476.553 377.75 472.939 377.75 468.472V344.018C377.75 339.551 381.364 335.937 385.831 335.937H583.2C587.668 335.937 591.281 339.551 591.281 344.018V468.494C591.281 472.939 587.668 476.553 583.2 476.553Z"
        fill="white"
      />
      <path
        d="M583.195 468.477V344.001L385.826 344.001V468.477L583.195 468.477Z"
        fill="#66B4FF"
      />
      <path
        d="M536.973 402.905L513.87 435.689L503.337 450.602L481.395 419.461L446.84 370.451L385.831 457.019V468.472H583.2L536.973 402.905Z"
        fill="#40A1FF"
      />
      <path
        d="M518.447 379.911C518.447 388.255 511.68 395 503.359 395C495.016 395 488.271 388.233 488.271 379.911C488.271 371.568 495.038 364.823 503.359 364.823C511.68 364.823 518.447 371.568 518.447 379.911Z"
        fill="white"
      />
    </g>
  </g>
}

export default Picture
