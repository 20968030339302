import React from 'react'

import * as Styles from './SocialStairs.styled'
import { ISocialsStairs } from '../types'
import { getSocialStairsIcon } from '../../../svg/socialStairs'
import Tooltip from '../Tooltip'

interface ISocialsStairsProps {
  socials: ISocialsStairs
  isLink?: boolean
}

const SocialStairs: React.FC<ISocialsStairsProps> = ({ socials, isLink = false }) => {
  return <Styles.List>
    {
      socials.map(social => {
        const Icon = getSocialStairsIcon(social.systemName)

        return (
          <Styles.Item key={social.id}>
            {social.tooltip ? (
              <Tooltip
                idPrefix={`sntooltip-adv-${social.systemName}`}
                hoverComponent={
                  <Styles.Button
                    index={social.id}
                    isDisabled={true}
                  >
                    <Icon />
                  </Styles.Button>
                }
                component={<>{social.tooltip}</>}
              />
            ) : (
              <Styles.Button index={social.id}>
                <Icon />
              </Styles.Button>
            )}
          </Styles.Item>
        );
      })
    }
  </Styles.List>
}

export default SocialStairs
