import React from 'react'

import * as Styles from './Opportunity.styled'

import { ITOpportunitySection } from '../../../types/sections'

import { useDesktop } from '../../../styled/useMedia'

import Section from '../../common/Section'
import Slogan from '../SloganSection'
import SectionParallax, { ParallaxSide } from '../SectionParallax'
import OpportunityList from '../../Opportunity'

const Opportunity: React.FC<ITOpportunitySection> = ({ slogan, opportunityList }) => {
  const isDesktop = useDesktop()

  const bgImageDesktopStyleObject = {
    left: '35%',
    width: '65%',
    top: '-15%',
    height: '100%',
  }

  return <Styles.Wrapper>
    <SectionParallax side={ ParallaxSide.Left } bgImageStyle={ isDesktop ? bgImageDesktopStyleObject : {} }>
      <Section>
        <Styles.Header>
          <Slogan>{ slogan }</Slogan>
        </Styles.Header>

        <Styles.Content>
          <Styles.List>
            <OpportunityList
              opportunitys={ opportunityList }
            />
          </Styles.List>
        </Styles.Content>
      </Section>
    </SectionParallax>
  </Styles.Wrapper>
}

export default Opportunity
