import styled, { css } from 'styled-components'
import { resetList } from '../../styled/mixins'

// List Styling
export const List = styled.ul<{ isFlowColumn?: boolean }>`
  ${resetList};
  
    @media ${props => props.theme.device['desktop']}{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 20px;
    
      ${props => props.isFlowColumn && css`
        grid-auto-flow: column;
      `}
    }

    @media ${props => props.theme.device['desktop-medium']} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 30px;
  
    ${props => props.isFlowColumn && css`
      grid-auto-flow: column;
    `}
  }
`

export const Item = styled.li`
  @media ${props => props.theme.maxDevice['desktop']},
  @media ${props => props.theme.maxDevice['desktop-medium']} {
    margin-bottom: 15px;
    
    &:last-child {
      margin-bottom: 0;
    } 
  }
`

// Item Styling
export const Root = styled.div`
  display: flex;
  align-items: center;
`

export const Ellipse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background: rgba(20, 20, 20, 0.32);
  border-radius: 15px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border-radius: 20px;
  }
`

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.theme.colors.text.contrast};
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 14px;
    line-height: 18px; 
  }
`
