import styled from 'styled-components'

import { container } from '../../../styled/mixins'

export const Root = styled.section`
  padding-top: 25px;
  padding-bottom: 25px;
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    padding-top: 85px;
    padding-left: 110px;
    padding-bottom: 85px;
  }
`

export const Container = styled.div`
  ${container};
`
