import React from 'react'

const MessageHeart = () => {
  return <g>
    <path
      fill="url(#paint4_welcome_vp_linear)"
      d="M225.705.035h-75.736c-6.879 0-12.44 5.58-12.44 12.434v50.813c0 6.877 5.583 12.434 12.44 12.434h27.714l10.154 10.15 10.153-10.15h27.715c6.879 0 12.439-5.58 12.439-12.434V12.47c.022-6.876-5.56-12.434-12.439-12.434z"
    ></path>
    <path
      fill="url(#pattern0_welcome_vp)"
      d="M165.91 20.934H209.162V56.787000000000006H165.91z"
    ></path>
    <g filter="url(#filter5_welcome_vp_d)">
      <path
        fill="#fff"
        d="M193.089 49.42l-5.253 3.405-5.252-3.383c-13.121-8.48-13.121-14.543-13.121-16.828 0-5.338 4.33-9.666 9.67-9.666a9.66 9.66 0 018.703 5.448 9.66 9.66 0 018.704-5.448c5.34 0 9.67 4.328 9.67 9.666 0 2.263 0 8.326-13.121 16.806z"
      ></path>
    </g>
  </g>
}

export default MessageHeart
