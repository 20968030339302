import React from 'react'

import * as Styles from './HowWorkSystem.styled'

import Section from '../../common/Section'
import Slogan from '../SloganSection'
import SkewCard from '../../SkewCard'
import ListWithEllipse from '../../ListWithEllipse'
import ListColumn from '../../ListColumn'
import { ButtonView } from '../../common/Button'
import SectionParallax, { ParallaxSide } from '../SectionParallax'
import { useDesktop } from '../../../styled/useMedia'

import { HowToWorkCompanyList, HowToWorkYouList } from '../../../data/vp'
import storeApp from '../../../store/AppStore'
import { EAuthModalSteps, EModalNames } from '../../Modals/Modals.type'

const HowWorkSystem = () => {
  const isDesktop = useDesktop()

  const bgImageDesktopStyleObject = {
    left: '60%',
    width: '75%',
    height: '100%',
  }

  const onClickRegisterButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Enter)
    storeApp.setAuthModalData(EAuthModalSteps.Register)
  }

  const howToWorkCompanyList = HowToWorkCompanyList.map(item => {
    const Icon = item.content

    return {
      ...item,
      content: <Styles.Icon> <Icon /> </Styles.Icon>
    }
  })

  return <Styles.Wrapper>
    <SectionParallax side={ ParallaxSide.Right }  bgImageStyle={ isDesktop ? bgImageDesktopStyleObject : {} }>
      <Section>
        <Styles.Header>
            <Slogan>Как работает сервис</Slogan>
        </Styles.Header>

        <Styles.Content>
          <Styles.YouAction>
            <Styles.SloganContent>
              Что необходимо сделать тебе:
            </Styles.SloganContent>
            <Styles.List>
              <ListColumn items={ HowToWorkYouList } isHTML />
            </Styles.List>
            <Styles.Action>
              <Styles.Button view={ ButtonView.Outline } onClick={ onClickRegisterButton }>Регистрация</Styles.Button>
            </Styles.Action>
          </Styles.YouAction>
          <Styles.BidFoxAction>
            <Styles.SloganContent>
              Что сделает BidFox:
            </Styles.SloganContent>
            <SkewCard>
              <ListWithEllipse items={ howToWorkCompanyList } isHTML />
            </SkewCard>
          </Styles.BidFoxAction>
        </Styles.Content>

        <Styles.Title>
          В системе предусмотрены автоматический и ручной режим работы. Установив автоматический режим, можно не беспокоиться о том, что и когда будет опубликовано: система самостоятельно определит время выхода поста, а все заявки от рекламодателей будут направлены в наш собственный отдел модерации. Также наша команда самостоятельно проверяет все офферы от партнерских программ и оставляет только те, которые показывают наибольшую эффективность и соответствую правилам социальных сетей.
        </Styles.Title>
      </Section>
    </SectionParallax>
  </Styles.Wrapper>
}

export default HowWorkSystem
