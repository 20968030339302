import styled from 'styled-components'

export const Slogan = styled.h2`
  width: fit-content;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  background: linear-gradient(#FACD68, #FC76B3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 32px;
    line-height: 40px;
  }
`
