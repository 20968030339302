import React from 'react'

import * as Styles from './StepMenu.styled'

import { INavigations } from '../types'

interface IStepMenuProps {
  navigations: INavigations
}

const StepMenu: React.FC<IStepMenuProps> = ({ navigations }) => {
  return <Styles.List>
    {
      navigations.map(navigation => (
        <Styles.Item key={ navigation.id }>
          <Styles.Link
            to={ navigation.href }
            activeClass="selected"
            spy
            smooth
            duration={ 500 }
          >
            { navigation.id > 9 ? navigation.id : `0${navigation.id}` }
          </Styles.Link>
        </Styles.Item>
      ))
    }
  </Styles.List>
}

export default StepMenu
