import styled from 'styled-components'

import ButtonLibrary from '../../common/Button'

export const Wrapper = styled.div`
  position: relative;
  background: radial-gradient(33.21% 39.01% at 62.22% 52.7%, rgba(169, 86, 230, 0.3) 0%, rgba(173, 89, 228, 0) 100%), ${props => props.theme.colors.main.darken};
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`

export const Header = styled.div`
  margin-bottom: 50px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 90px;
  }
`

export const SloganContent = styled.h3`
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.text.contrast};
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 25px;
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 5;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    display: flex;
    align-items: center;
  }
`

export const Video = styled.div`
  margin-bottom: 60px;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    width: 670px;
    flex-shrink: 0;
    margin-right: 30px;
    margin-bottom: 0;
  }
`

export const Description = styled.div`
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    flex-grow: 1; 
  }
`

export const Action = styled.div``

export const Button = styled(ButtonLibrary)`
   border-color: rgba(255, 255, 255, 0.4);
   background-color: rgba(255, 255, 255, 0.05);

   @media ${props => props.theme.maxDevice['desktop']},
   @media ${props => props.theme.maxDevice['desktop-medium']} {
    width: 100%;
    justify-content: center;
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-weight: 500;
  }
`

export const Title = styled.p`
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
  }
`
