import React from 'react'
import { ParallaxProps } from 'react-parallax'

import Parallax from '../../common/Parallax'

import ParallaxLinesLeftSVG from '../../../svg/common/lines-background-left.svg'
import ParallaxLinesRightSVG from '../../../svg/common/lines-background-right.svg'

export enum ParallaxSide {
  Left = 'Left',
  Right = 'Right',
}

type ISectionParallaxProps = ParallaxProps & {
  side?: ParallaxSide,
}

const SectionParallax: React.FC<ISectionParallaxProps> = ({ children, strength = 200, side = ParallaxSide.Left, bgImageStyle }) => {
  const bgImage = side === ParallaxSide.Left ? ParallaxLinesLeftSVG : ParallaxLinesRightSVG

  return <Parallax blur={0} bgImage={ bgImage } bgImageAlt="lines decoration" strength={strength} bgImageStyle={ bgImageStyle }>
    { children }
  </Parallax>
}

export default SectionParallax
