import React from 'react'

import * as Styles from './OpacityCard.styled'

interface IOpacityCardProps {
  className?: string
}

const OpacityCard: React.FC<IOpacityCardProps> = ({ children, className }) => {
  return <Styles.Root className={ className }>
    <Styles.Content>
      { children }
    </Styles.Content>
  </Styles.Root>
}

export default OpacityCard
