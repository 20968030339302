import React from 'react'

import { getContactIcon } from '../../../svg/contacts'
import * as Styles from './Contacts.styled'

import { IContacts } from '../types'

interface IContactsProps {
  contacts: IContacts
  isRow?: boolean
}

const Contacts: React.FC<IContactsProps> = ({ contacts, isRow = false }) => {
  return <Styles.List isRow={ isRow }>
    {
      contacts.map(contact => {
        const Icon = getContactIcon(contact.systemName)

        return <Styles.Item key={ contact.id }>
          <Styles.Link href={ contact.href } target="_blank">
            <Styles.Icon>
              <Icon />
            </Styles.Icon>
            <Styles.Content>
              <Styles.Title>{ contact.title }</Styles.Title>
              <Styles.Subtitle href={ contact.href } target="_blank">{ contact.subTitle }</Styles.Subtitle>
            </Styles.Content>
          </Styles.Link>
        </Styles.Item>
      })
    }
  </Styles.List>
}

export default Contacts
